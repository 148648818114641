import React, { useState } from "react";
import { JoinMrGen } from "../common/JoinMrGen";
import CompanyData from "./CompanyData";
import { EmailConfirmed } from "../common/EmailConfirmed";
import { CompanyCard } from "../../common/CompanyCard";
import { UserType } from "../../../data/enums";
import logo from "../../../assets/logo.png";

//1. Querés ser parte?
//2. Datos empresa
//3. Correo enviado

function CompanySignup() {
  const [activeStep, setActiveStep] = useState<number>(0);

  if (activeStep === 0) {
    return (
      <JoinMrGen
        title="Mr.GEN para empresas"
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        type="company"
      />
    );
  } else {
    return (
      <div className="company-signup">
        <div className="signup-left-content">
          <img src={logo} alt="logo" className="logo" />
          <div className="company-signup-content">
            {activeStep === 1 ? (
              <CompanyData
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            ) : null}
            {activeStep === 2 ? (
              <EmailConfirmed userType={UserType.Company} />
            ) : null}
          </div>
        </div>
        <div className="signup-right-content">
          <img src={logo} alt="logo" className="logo" />
          {/* {activeStep != 2 &&
            <CompanyCard />
          } */}
        </div>
      </div>
    );
  }
}

export default CompanySignup;
