import React, { useEffect, useState } from "react";
import logo from "../../../assets/logo.png";
import { CompanyCard } from "../../common/CompanyCard";
import { EndUserCard } from "../../common/EndUserCard";
import { RootState } from "../../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import { fetchInstruments } from "../../../store/instruments/actionCreators";
import { createAndPayAnalysis } from "../../../store/analyses/actionCreators";
import { ConnectedProps, connect, useSelector, useDispatch } from "react-redux";
import { FormTextField } from "../../common/forms/FormTextField";
import { Formik, Form, Field, setNestedObjectValues } from "formik";
import { Button } from "@material-ui/core";
import { LoadingAnimation } from "../../animations/LoadingAnimation";
import { CreateAnalysisRequest } from "../../../store/analyses/types";
import { RouteComponentProps } from "react-router-dom";
import { userAccount } from "../../../store/auth/selector";
import roles from "../../../utils/roles";
import { REDIRECT_URL } from "../../../services/config";


type PropsType = RouteComponentProps<any>;

interface Values {
  name: string;
  installments: number;
}

function Checkout({ history }: PropsType) {
  const actualUser = useSelector((state: RootState) => userAccount(state));
  const isCompany = actualUser?.role === roles?.business;
  const costs = useSelector((state: RootState) => state.costs);
  const dispatch = useDispatch();
  const instruments = useSelector((state: RootState) => state.instruments);
  const [price, setPrice] = useState<number>(0);
  const [finalPrice, setFinalPrice] = useState<number>(0);
  const [coupon, setCoupon] = useState<string>("");
  const [loading, setLoading] = useState(true);

  //console.log('Es empresa: ', isCompany);

  const redirectTo = (path: string): void => {
    //console.log('Redireccion a desde checkout: ', path);
    history.push(path);
  };

  useEffect(() => {
    if (
      !instruments.loading &&
      !instruments.instruments &&
      instruments.errorMessage === ""
    ) {
      //console.log("Cargando instrumentoos");
      //timeout 2 segundos para que haga el dispatch
      setTimeout(() => {
        dispatch(fetchInstruments());
      }, 2500);
    }
  }, [
    instruments.loading,
    instruments.instruments,
    instruments.errorMessage,
    fetchInstruments,
    dispatch
  ]);

  useEffect(() => {
    if (instruments.instruments) {
      //console.log("Instrumentos cargados - cambio loading a false");
      setLoading(false);
    } else if (instruments.errorMessage) {
      //console.log("Error al cargar instrumentos");
    }
  }, [instruments]);

  useEffect(() => {
    //console.log('finalPrice, price y cupon');
    //console.log(finalPrice, price, coupon);
    setPrice(Number(localStorage.getItem("price")));
    setFinalPrice(Number(localStorage.getItem("finalPrice")));
    setCoupon(localStorage.getItem("coupon") || "");
    //console.log("el cupon es: " + localStorage.getItem("coupon") || "");
    
    
    if(!isCompany) {
      //si final price es 0, hago dispatch de createAndPayAnalysis y redirijo a PaymentResults
      if(Number(localStorage.getItem("finalPrice")) === 0) {
        //console.log("me voy por final price 0 en checkout")
        //console.log("Instrumento en dispatch analisis desde checkout: ")
        //console.log(instruments)

        dispatch(createAndPayAnalysis({
          analysisAmount: 1,
          instrumentToken: instruments?.instruments?.[0]?.instrumentToken || "pagocupon",
          installments: 1,
          coupon: localStorage.getItem("coupon") || ""
        }));
        redirectTo("/payment");
      }
    }

    const el = document.querySelector(".whatsapp_float") as HTMLElement;
    if(el) {
      el.style.display = "none";
    }
    const el2 = document.querySelector(".user-signup") as HTMLElement;
    if(el2) {
      el2.style.backgroundColor = "white";
    }
    

  },[]);

  

  

  const submit = (values: Values) => {
    
    //console.log("me voy por submit en checkout")
    if(!loading) {
      const amount = localStorage.amount;
      localStorage.setItem('installments', values.installments.toString())
      //console.log(price, finalPrice, coupon);
      //console.log(instruments)
      dispatch(
        createAndPayAnalysis({
          analysisAmount: isCompany == true ? amount : 1,
          instrumentToken: instruments.instruments![0].instrumentToken,
          installments: values.installments,
          coupon: localStorage.getItem("coupon") || ""
        })
      );
      redirectTo("/payment");
    }
    
  };
  
  if(loading || !instruments.instruments) {
    return (<LoadingAnimation />)
  }
  
  return (
    <div className="user-signup">
      <div className="signup-left-content">
        <img src={logo} alt="logo" className="logo" />
        <div className="checkout">
          {instruments.instruments ? (
            <Formik
              initialValues={{
                name:
                  actualUser?.profile?.firstName +
                  " " +
                  actualUser?.profile?.lastName,
                installments: 1, 
              }}
              onSubmit={submit}
            >
              <Form>
                <div className="section">
                  <p className="subtitle">Seleccioná la cantidad de cuotas</p>
                  <Field 
                    as="select" 
                    name="installments" 
                    className="form-control" 
                    defaultValue={1}
                    style={{ padding: '10px 40px', fontSize: '1.1em' }}
                    >
                    {[1, 2, 3, 4, 5, 6].map((num) => (
                      <option key={num} value={num}>
                        {num} cuota{num > 1 ? 's' : ''}: USD {(finalPrice / num).toFixed(2)}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="section">
                  <p className="subtitle">Tus datos</p>
                  <Field
                    name="name"
                    component={FormTextField}
                    placeholder="Nombre completo"
                    disabled
                  />
                </div>
                
                <div className="section">
                  <p className="subtitle">Tu método de pago</p>
                  <div className="instrument-details">
                    <img
                      src={
                        instruments.instruments[0] &&
                        instruments.instruments[0].issuerImageUrl
                      }
                      alt="instrument"
                    />
                    <span>{`**** **** **** ${
                      instruments.instruments[0] &&
                      instruments.instruments[0].name.substring(12)
                    }`}</span>
                  </div>
                </div>
                <p className="checkout-speech">
                  Solo falta un paso más! Dando clic en pagar finalizarás la
                  compra del kit.
                </p>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disableElevation
                >
                  Pagar
                </Button>
              </Form>
            </Formik>
          ) : (
            <LoadingAnimation />
          )}
        </div>
      </div>
      
    </div>
  );
}

export default Checkout;
/* <div className="signup-right-content">
        <img src={logo} alt="logo" className="logo" />
        <CompanyCard isCompany={isCompany}/>
      </div> */