import React from "react";
import { Paper, Button } from "@material-ui/core";
import { SelectableDate } from "./DateSelector/DateSelector";
import moment from "moment";
import { Analysis } from "../../../store/analyses/types";
import tomaMuestraImage from "../../../assets/toma-muestra.png";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

interface Props {
  kitDeliveryInfo: Analysis;
  date: SelectableDate;
  name: string;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

function KitConfirmation({
  kitDeliveryInfo,
  name,
  activeStep,
  setActiveStep,
}: Props) {
  console.log(kitDeliveryInfo);
  const isDelivery = kitDeliveryInfo?.returnDistribution
    ? kitDeliveryInfo?.returnDistribution?.type == 0
    : kitDeliveryInfo?.pickupDistribution?.type == 0;
  const isReturn = !!kitDeliveryInfo?.returnDistribution;

  const history = useHistory();
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    const distribution = kitDeliveryInfo?.returnDistribution
        ? kitDeliveryInfo?.returnDistribution
        : kitDeliveryInfo?.pickupDistribution;
        console.log("Distribution");
        console.log(distribution);
    const kitDistribution = kitDeliveryInfo?.returnDistribution
        ? kitDeliveryInfo?.returnKitDistribution
        : kitDeliveryInfo?.pickupKitDistribution;
    console.log("KitDistribution");
    console.log(kitDistribution);
    
    function generateMessage(distribution,kitDistribution) {
      if(!kitDistribution) {
        return 'Cargando información del kit...';
      }

      if (!isDelivery) {
        return `Puedes pasar a ${
          isReturn ? "dejar" : "retirar"
        } tu kit en las oficinas de Mr. Gen de Lunes a Viernes de 10 a 17 horas.`;
      }
      if (
        distribution &&
        kitDeliveryInfo?.created &&
        distribution.address.department == "Montevideo"
      ) {
        
        const date = kitDistribution?.date;
        return `Listo! El ${moment(date).format("dddd DD [de] MMMM")} entre las ${
          kitDistribution?.schedule === 0 ? "10am - 2pm" : "2pm - 10pm"
        } ${!isReturn ? "entregaremos tu kit de toma de muestra" : "retiraremos el sobre con la muestra"}. `;
      } else {
        // If it's not from montevideo
        return "A la brevedad el equipo de Mr.Gen se pondrá en contacto contigo.";
      }
      
    }
    

    setMessage(generateMessage(distribution,kitDistribution));

  },[kitDeliveryInfo]);

  function getMessage() {
    
  }

  return (
    <div className="kit-confirmation">
      <Paper elevation={2} classes={{ root: "user-dashboard-content" }}>
        <Paper elevation={0} classes={{ root: "content-heading" }}>
          <p className="title">Hola {name}</p>
          <p>
            {kitDeliveryInfo?.status == 2
              ? "¡Ahora solo queda esperar!"
              : isDelivery
              ? `Listo! ${
                  !isReturn ? "Entregaremos" : "Retiraremos"
                } el kit en tu dirección.
              ${
                !isReturn
                  ? `A partir de que lo recibas podrás tomar tu muestra de saliva.
                  Al finalizar avanza al siguiente paso para coordinar el retiro del sobre con la muestra.`
                  : "Ahora deberás esperar a que recibamos los resultados de tu análisis."
              }`
              : `Listo! puedes pasar a ${
                  !isReturn ? "retirar" : "entregar"
                } tu kit por las oficinas de Mr. Gen de Lunes a Viernes de 10 a 17 horas.`}
          </p>
        </Paper>
        <div className="information-container">
          <p className="title">
            {kitDeliveryInfo?.status == 2
              ? "Test en laboratorio"
              : message}
          </p>
          {kitDeliveryInfo?.status == 2 ? (
            <>
              En un máximo de 45 días hábiles tendrás tus resultados disponibles. <br /><br />
              <b>Mientras tanto podés pedir platos ricos, sanos y nutritivos elaborados por chefs con experiencia.</b><br /><br/>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={() => history.push('/platos-semanales')}
              >
                Platos semanales
              </Button>
            </>
          ) : !isReturn ? (
            <>

              <div className="subtitle">
             <p>Al recibir el kit comienza completando tus datos y firma en el reverso del sobre de muestra.
             <br/>Antes de realizar la prueba toma en cuenta las siguientes indicaciones:
             </p>
              <div className="instructions">
                <div className="instructions--text">
                  <ul>
                    <li>No lleves nada a la boca durante al menos 1 hora antes de la recolección. Por ejemplo, bebidas, cepillo de dientes, dulces, cigarrillos y otros similares.</li>
                    <li>Con un hisopo, debes raspar vigorosamente el interior de la mejilla durante 1 a 2 minutos. Repite el mismo proceso con el segundo hisopo en el otro lado de la mejilla.</li>
                    <li>Coloca los dos hisopos recogidos en el sobre de muestra. Deja secar unos minutos y sella el sobre.</li>
                    <li>No exponer nunca el sobre a temperaturas extremas.</li>
                  </ul>
                </div>
                <div className="instructions--img">
                  <img src={tomaMuestraImage} alt="Toma de muestra" width={'280px'} />
                </div>
                
              </div>
              <p>Al finalizar, coloque el sobre de muestra dentro del sobre de retorno y haga click en "siguiente" para continuar al paso de retiro.</p>
              </div>
              
              <p className="info">
              
              </p>
            </>
          ) : null}
          {!isReturn && kitDeliveryInfo.status != 2 ? (
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => setActiveStep(activeStep + 1)}
            >
              Siguiente
            </Button>
          ) : null}
        </div>
      </Paper>
    </div>
  );
}

export default KitConfirmation;

/* 
<p className="subtitle">
                Puedes seguir los pasos del video para la toma de muestra de
                saliva
              </p>
              <a
                href="https://youtu.be/6SZC47YwuJo"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Enlace aquí
              </a>
              <p className="info">
                También puedes{" "}
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/mrgen-dev.appspot.com/o/otros%2Finstructivo-toma-de-muestra.pdf?alt=media&token=266a19ea-6b69-42d5-bbd3-371360510856"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  descargar aquí
                </a>{" "}
                el PDF con información paso a paso de la toma de muestra de
                saliva.
                <br />
                <br />
                Cuando tengas tu muestra de saliva, ve al siguiente paso y
                retiraremos la muestra en tu dirección
              </p>
*/