import React, {lazy, Suspense} from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
/* import {LandingHeader} from '../LandingHeader';
import {LandingCover} from '../LandingCover';
import {LandingIntro} from '../LandingIntro';
import {LandingAboutUs} from '../LandingAboutUs';
import {LandingHowItWorks} from '../LandingHowItWorks';
import {LandingResults} from '../LandingResults';
import {LandingBuy} from '../LandingBuy';
import {LandingNutritionists} from '../LandingNutritionists';
import {LandingBusinesses} from '../LandingBusinesses';
import {LandingCAT} from '../LandingCAT';
import {LandingFooter} from '../LandingFooter'; */
import { LoadingAnimation } from '../../animations/LoadingAnimation';

const LandingHeader = React.lazy(() =>
  import('../LandingHeader').then((module) => ({ default: module.LandingHeader }))
)
const LandingCover = React.lazy(() =>
  import('../LandingCover').then((module) => ({ default: module.LandingCover }))
)
const LandingIntro = React.lazy(() =>
  import('../LandingIntro').then((module) => ({ default: module.LandingIntro }))
)
const LandingHowItWorks = React.lazy(() =>
  import('../LandingHowItWorks').then((module) => ({ default: module.LandingHowItWorks }))
)
const LandingResults = React.lazy(() =>
  import('../LandingResults').then((module) => ({ default: module.LandingResults }))
)
const LandingBuy = React.lazy(() =>
  import('../LandingBuy').then((module) => ({ default: module.LandingBuy }))
)
const LandingFAQ = React.lazy(() =>
  import('../LandingFAQ').then((module) => ({ default: module.LandingFAQ }))
)
const LandingNutritionists = React.lazy(() =>
  import('../LandingNutritionists').then((module) => ({ default: module.LandingNutritionists }))
)
const LandingBusinesses = React.lazy(() =>
  import('../LandingBusinesses').then((module) => ({ default: module.LandingBusinesses }))
)
const LandingCAT = React.lazy(() =>
  import('../LandingCAT').then((module) => ({ default: module.LandingCAT }))
)
const LandingFooter = React.lazy(() =>
  import('../LandingFooter').then((module) => ({ default: module.LandingFooter }))
)

interface FAQData {
  pregunta: string;
  respuesta: string;
}

const datosFAQ: FAQData[] = [
  {
    pregunta: '1. ¿Cómo funciona el proceso de prueba de ADN?',
    respuesta: 'El proceso de prueba de ADN es muy simple. Una vez que realizas la compra en la página, te enviamos un kit de toma de muestra de saliva a la dirección que proporciones en el paso "Coordinación de kit". El kit incluye 3 hisopos para la toma de muestra de saliva que debes recolectar. Una vez la tengas lista debes avanzar al paso "Coordinación de retiro" para devolver la muestra a las oficinas de Mr. GEN para ser analizadas por el laboratorio. Una vez recibida la muestra por el laboratorio se deberá esperar un máximo de 45 días hábiles para recibir los resultados directamente en la página web de Mr. GEN.'
  },
  {
    pregunta: '2. ¿Qué tipo de información se obtiene a partir de los resultados del análisis de ADN?',
    respuesta: 'A partir del análisis de ADN, podemos brindarte información sobre cómo asimilas determinados nutrientes, y los categorizamos en forma de semáforo (verde - bajo, amarillo - moderado, rojo - alto). Además, te brindaremos una lista de casi 300 alimentos también categorizados con alimentos que debes preferir, moderar o evitar.'
  },
  {
    pregunta: '3. ¿Qué debo tener en cuenta antes de la toma de muestra de saliva',
    respuesta: 'Al tratarse de una prueba genética, no es necesario que la realices en ayunas. Eso sí, no debes comer, beber, fumar, masticar chicle o lavarte los dientes en las 2 horas previas a la toma de la muestra.'
  },
  {
    pregunta: '4. ¿Se necesita una consulta con un nutricionista después de recibir los resultados?',
    respuesta: 'No es obligatorio, pero se recomienda que el cliente hable con un nutricionista después de recibir los resultados. Nuestros resultados proporcionan una base sólida para tomar decisiones informadas sobre la dieta, pero un nutricionista puede proporcionar recomendaciones personalizadas y asesoramiento para optimizar la salud y el bienestar del cliente.'
  },
  {
    pregunta: '5. ¿Ofrecen algún otro servicio además de los resultados del análisis de ADN?',
    respuesta: 'Sí, además de los resultados del análisis de ADN, ofrecemos servicios adicionales, como consultas con nutricionistas y servicios de viandas a domicilio de comida saludable y rica. Estos servicios se pueden contratar por separado.'
  },
  {
    pregunta: '6. ¿Hay alguna restricción de edad para realizar la prueba de ADN?',
    respuesta: 'Sí, en Uruguay, se requiere que las personas tengan al menos 18 años de edad para realizar una prueba de ADN sin la supervisión de un adulto responsable.'
  },
  {
    pregunta: '7. ¿Los resultados son confidenciales?',
    respuesta: 'Sí, los resultados son confidenciales. Nos tomamos muy en serio la privacidad y la protección de datos personales de nuestros clientes. Por lo tanto, los resultados solo se comparten con el cliente y no se divulgan a terceros sin el consentimiento del mismo.'
  },
  {
    pregunta: '8. ¿Se puede realizar la prueba de ADN si estoy embarazada o lactando?',
    respuesta: 'No recomendamos que las mujeres embarazadas o lactantes realicen nuestra prueba de ADN sin antes consultar a su médico. Aunque nuestra prueba de ADN no tiene ningún riesgo conocido para la salud, es importante tener en cuenta que la información genética que proporcionamos puede no ser relevante durante el embarazo y la lactancia.'
  },
  {
    pregunta: '9. ¿Qué sucede si mis resultados son incorrectos o no son claros?',
    respuesta: 'Si por algún motivo sus resultados no son claros, por favor contáctenos a través de nuestro sitio web o correo electrónico info@mistergen.com para validar los mismos.'
  },
  {
    pregunta: '10. ¿Por qué algunas recomendaciones en el área de resultados se contradicen?',
    respuesta: 'Los resultados y sus recomendaciones son tomados de forma independiente y por eso se pueden contradecir. En cambio para obtener la "última palabra" respecto a un alimento puede acceder a las recomendaciones de alimentos, allí todos los resultados son tomados en cuenta, relacionados y por cada alimento se llega a un único resultado de si puede ser o no conveniente que lo consuma.'
  },
  {
    pregunta: '11. ¿Qué medidas toman para garantizar la calidad y precisión de sus resultados?',
    respuesta: 'Nos tomamos muy en serio la calidad y precisión de nuestros resultados. Trabajamos con laboratorios altamente calificados y con experiencia en la realización de pruebas de ADN y seguimos rigurosos procedimientos de control de calidad para garantizar que los resultados sean precisos y confiables. Además, nuestros resultados se basan en la última investigación científica y están respaldados por expertos en nutrición y genética.'
  },
  {
    pregunta: '12. ¿Puedo compartir mis resultados con mi médico o nutricionista?',
    respuesta: 'Sí, puede compartir sus resultados con su médico o nutricionista si lo desea. De hecho, recomendamos que comparta sus resultados con un profesional de la salud o un nutricionista para obtener asesoramiento personalizado y garantizar que esté siguiendo una dieta saludable y equilibrada.'
  },
  {
    pregunta: '13. ¿Cómo se realizan los pagos y cuáles son las opciones de pago disponibles?',
    respuesta: 'Puedes realizar la compra del kit utilizando tarjetas de débito o crédito VISA, OCA y MASTERCARD en pagos de 1, 3 o 6 cuotas.'
  },
  {
    pregunta: '14. ¿Puedo cancelar mi pedido o solicitar un reembolso?',
    respuesta: 'Sí, los clientes pueden cancelar su pedido o solicitar un reembolso antes de que se realice la prueba de ADN. Una vez que se ha procesado la muestra de saliva ya no se puede realizar un reembolso. Nos puede contactar a info@mistergen.com le responderemos en el horario de 10 a 17 horas de Lunes a Viernes.'
  },
  {
    pregunta: '15. ¿Cuánto tiempo tarda en llegar mi kit de prueba de ADN?',
    respuesta: 'El kit de ADN se enviará en el día y rango horario pactado al momento de la coordinación de entrega y retiro. Para esto hay dos franjas horarias (10 a 14 horas o 14 a 22 horas). En caso de requerir otro horario debe seleccionar retiro en oficinas de Mr. GEN en el paso "Coordinación de entrega" y nos comunicaremos para coordinar la entrega por otros medios.'
  },
  {
    pregunta: '16. ¿Qué sucede si mi muestra de saliva no es suficiente para la prueba?',
    respuesta: 'En algunos casos, puede que no haya suficiente saliva en la muestra para realizar la prueba de ADN. Si esto ocurre, nos pondremos en contacto, para solicitar una nueva muestra de saliva. Si no podemos obtener una muestra adecuada después de varios intentos, te ofreceremos un reembolso completo.'
  },
  {
    pregunta: '17. ¿Cómo puedo acceder a mis resultados?',
    respuesta: `Una vez recibida la muestra de saliva en nuestras oficinas, la aplicación cambiará de estado automáticamente a "Esperando Resultados". 
    El informe con los resultados de la prueba estará disponibles desde la aplicación web de Mr. Gen en un plazo máximo de 45 días hábiles.`
  },
  {
    pregunta: '18. ¿Tengo predisposición a desarrollar enfermedades o transmitirlas a mis hijos?',
    respuesta: 'La prueba de Mr. Gen no detecta este tipo de predisposiciones, está enfocado en las variaciones de nuestro ADN que impactan sobre nuestra alimentación, nutrición y deporte.'
  },
  {
    pregunta: '19. ¿Cómo puedo contactar con el equipo Mr. Gen?',
    respuesta: `Puedes contactarnos por mail a través de info@mistergen.com, te responderemos en un plazo máximo de 48 hs.
    También puedes enviarnos un Whatsapp  al +598 99 487 437. Nuestro horario de atención es de lunes a viernes de 10 a 17 hs (Montevideo (UTC-3).
    `
  }
];

function Landing({history, location}: RouteComponentProps) {
  const redirectTo = (path: string): void => {
    history.push(path);
  };

  return (
    <div className='landing'>
      <Suspense fallback={LoadingAnimation}>
        <LandingHeader redirect={redirectTo} />
        <LandingCover />
        <LandingIntro />
        <LandingResults />
        <LandingHowItWorks />
        <LandingFAQ datos={datosFAQ} />
        <LandingNutritionists redirect={redirectTo} />
        <LandingBusinesses redirect={redirectTo} />
        <LandingCAT redirect={redirectTo} />
        <LandingFooter redirect={redirectTo} />
      </Suspense>
      
     
    </div>
  );
}

export default withRouter(Landing);
