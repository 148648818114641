import React, { useState, useEffect } from "react";
import { UserStepper } from "./UserStepper";
import UserData from "./UserData";
import NutritionistCode from "./NutritionistCode";
import Patologies from "./Pathologies";
import FamilyBackground from "./FamilyBackground";
import { EmailConfirmed } from "../common/EmailConfirmed";
import { EndUserCard } from "../../common/EndUserCard";
import { UserType } from "../../../data/enums";
import logo from "../../../assets/logo.png";
import { Account as Acc } from "../../../store/auth/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/config/types";
import { changeEmail, register, login } from "../../../store/auth/actionCreators";
import qs from "query-string";
import { Link, useHistory } from "react-router-dom";
import { CompanyCard } from "../../common/CompanyCard";
import {Popup} from "../../common/Popup";


//1. Datos usuario final
//2. Código nutricionista
//3. Pantalla patologías
//4. Pantalla antecedentes
//5. Correo enviado

const previousRoutesToAvoidPopup = ["/createUser"];

function UserSignup() {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [userData, setUserData] = useState({
    name: "",
    surname: "",
    gender: 0,
    height: "70",
    weight: "170",
    phone: "",
    ethnicity: 0,
    date: "1990-01-01T00:00:00",
    email: "",
    password: "",
    repeatPassword: "",
  });
  const [nutritionistCode, setNutritionistCode] = useState({
    code: "",
  });
  const [pathologies, setPathologies] = useState({
    pathologies: [],
  });
  const [familyBackground, setFamilyBackground] = useState({
    familyBackground: [],
  });
  const [toPay, setToPay] = useState<boolean>(false);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [fromCompany, setFromCompany] = useState<boolean>(false);

  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const isRegistering = useSelector(
    (state: RootState) => state.auth.registering
  );

  useEffect(() => {
    const search = window.location.search;
    const stepReceived = Number(qs.parse(search)["step"]);
    const companyUser = Number(qs.parse(search)["fromCompany"]);
    if (companyUser == 1) setFromCompany(true);
    if (stepReceived) setActiveStep(stepReceived);
  }, []);

  useEffect(() => {
    if (toPay) {
      sendRegister();
    }
  }, [toPay]);

  useEffect(() => {
    if (auth.registerSuccess) {
      setActiveStep(4);
      if (!fromCompany) {
        setPopupOpen(true);  
        //HAGO AUTO LOGIN
        setTimeout(() => {
          setPopupOpen(false);
          dispatch(login({ email: userData.email, password: userData.password }));
        }, 3300);
      }
    }
  }, [auth.registerSuccess]);

  const sendRegister = () => {
    let pathologiesIds = [];
    for (let i of pathologies.pathologies) {
      pathologiesIds.push({ id: i, name: "" });
    }

    let familyBackIds = [];
    for (let i of familyBackground.familyBackground) {
      familyBackIds.push({ id: i, name: "" });
    }
    const account = createRequestRegisterCompany(pathologiesIds, familyBackIds);
    dispatch(changeEmail(userData.email));
    dispatch(register(account, userData.password));
  };

  const createRequestRegisterCompany = (pathologiesIds, familyBackIds): Acc => {
    const order = {
      invoiceId: 0,
      analyses: "",
      transaction: "",
      quantity: 1,
    };
    const analysis = {
      id: "",
      referenceId: "",
      created: new Date(),
      lastModified: new Date(),
      order: order,
    };
    const profile = {
      firstName: userData.name,
      lastName: userData.surname,
      birthDate: userData.date,
      height: userData.height,
      weight: userData.weight,
      gender: userData.gender,
      analysis: analysis,
      mobile: userData.phone,
      address: "",
      pathologies: pathologiesIds,
      familyHistories: familyBackIds,
    };
    const referer = {
      publicCode: nutritionistCode.code,
    };
    return {
      role: "Customer",
      profile,
      email: userData.email,
      business: null,
      referer,
      nutritionist: null,
    };
  };

  

  function goToHome() {
    history.push('/');
  }

  return (
    <div className="user-signup">
      <div className="signup-left-content">
        <div className="header">
          <img src={logo} alt="logo" className="logo" onClick={goToHome} />
          <Link to="/" className="back">
            Volver
          </Link>
        </div>
        <UserStepper activeStep={activeStep} />
        <div className="user-signup-content">
          {activeStep === 0 ? (
            <UserData
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              data={userData}
              setData={setUserData}
              setToPay={setToPay}
            />
          ) : null}
          {activeStep === 1 ? (
            <NutritionistCode
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              nutritionistCode={nutritionistCode}
              setNutritionistCode={setNutritionistCode}
            />
          ) : null}
          {activeStep === 2 ? (
            <Patologies
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              pathologies={pathologies}
              setPathologies={setPathologies}
            />
          ) : null}
          {activeStep === 3 ? (
            <FamilyBackground
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              familyBackground={familyBackground}
              setFamilyBackground={(ss) => setFamilyBackground(ss)}
              setToPay={setToPay}
              isLoading={isRegistering}
            />
          ) : null}
          {activeStep === 4 ? (
            <EmailConfirmed userType={UserType.EndUser} />
          ) : null}
        </div>
      </div>
      <div className="signup-right-content">
        <img src={logo} alt="logo" className="logo" />
          {UserType.EndUser ? <CompanyCard /> : ""}
        
      </div>
      <Popup open={popupOpen} onClose={() => setPopupOpen(false)} />
    </div>
  );
}

export default UserSignup;
