import { BusinessesState } from "./types";
import {
  LOAD_BUSINESS,
  LOADING_BUSINESS,
  LOAD_BUSINESS_FAILED,
  FAILED_EMPLOYES_ACCOUNTS,
  GET_EMPLOYES_ACCOUNTS,
  LOADING_EMPLOYES_ACCOUNTS,
} from "./actionTypes";
import { BusinessActionTypes } from "./actionTypes";
import { LOGOUT } from "../auth/actionTypes";

const initialState: BusinessesState = {
  businessIsLoading: false,
  business: null,
  businessErrorMessage: "",
  employesAccounts: null,
  employesAccountsErrorMessage: "",
  employesAccountsIsLoading: false,
};

export function businessesReducer(
  state = initialState,
  action: BusinessActionTypes| {type: 'LOGOUT'}
): BusinessesState {
  switch (action.type) {
    case GET_EMPLOYES_ACCOUNTS:
      return {
        ...state,
        employesAccounts: action.employesAccounts,
        employesAccountsIsLoading: false,
      };

    case LOADING_EMPLOYES_ACCOUNTS:
      return {
        ...state,
        employesAccountsIsLoading: true,
      };

    case FAILED_EMPLOYES_ACCOUNTS:
      return {
        ...state,
        employesAccountsIsLoading: false,
        employesAccountsErrorMessage: action.error,
      };

    case LOAD_BUSINESS:
      return {
        ...state,
        businessIsLoading: false,
        business: action.business,
        businessErrorMessage: "",
      };
    case LOADING_BUSINESS:
      return {
        ...state,
        businessIsLoading: true,
      };
    case LOAD_BUSINESS_FAILED:
      return {
        ...state,
        businessIsLoading: false,
        businessErrorMessage: action.error,
      };
    case LOGOUT:
      return {
        ...state,
        business: null,
        employesAccounts: null,
      };
    default:
      return state;
  }
}
