import React from "react";
import * as styleVars from "../../../../styles/_variables.scss";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { Option } from "./Users";

interface UserFiltersProps {
  selectedOptions: Option[];
  toggleOption: (id: number) => void;
}

function UserFilters({ selectedOptions, toggleOption }: UserFiltersProps) {
  return (
    <div className="filters">
      <p>Filtrar por: </p>
      <div className="options">
        {selectedOptions.map((option) => {
          return (
            <div
              onClick={() => toggleOption(option.id)}
              className="option"
              key={option.id}
              style={{
                borderColor: option.color,
                color: option.color,
                backgroundColor: option.selected ? option.background : styleVars.white,
              }}
            >
              <span className="text">{option.text}</span>
              {option.selected ? <CloseIcon /> : <AddIcon />}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default UserFilters;
