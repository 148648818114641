import { Business } from "./types";
import {Account} from '../auth/types';

export const LOAD_BUSINESS = "LOAD_BUSINESS";
export const LOADING_BUSINESS = "LOADING_BUSINESS";
export const LOAD_BUSINESS_FAILED = "LOAD_BUSINESS_FAILED";

export interface LoadBusinessAction {
  type: typeof LOAD_BUSINESS;
  business: Business;
}

export interface LoadingBusinessAction {
  type: typeof LOADING_BUSINESS;
}

export interface LoadBuisnessFailedAction {
  type: typeof LOAD_BUSINESS_FAILED;
  error: string;
}

export const GET_EMPLOYES_ACCOUNTS = 'GET_EMPLOYES_ACCOUNTS';
export const LOADING_EMPLOYES_ACCOUNTS = 'LOADING_EMPLOYES_ACCOUNTS';
export const FAILED_EMPLOYES_ACCOUNTS = 'FAILED_EMPLOYES_ACCOUNTS';

export interface GetEmployesAccountsAction {
  type: typeof GET_EMPLOYES_ACCOUNTS;
  employesAccounts: Account[];
}

export interface LoadingEmployesAccountsAction {
  type: typeof LOADING_EMPLOYES_ACCOUNTS;
}

export interface FailedEmployesAccountsAction {
  type: typeof FAILED_EMPLOYES_ACCOUNTS;
  error: string;
}

export type BusinessActionTypes =
  | LoadBusinessAction
  | LoadingBusinessAction
  | LoadBuisnessFailedAction
  | GetEmployesAccountsAction
  | LoadingEmployesAccountsAction
  | FailedEmployesAccountsAction;
