import React, { useState } from "react";
import {
  TextField,
  Tooltip,
  InputAdornment,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ErrorIcon from "@material-ui/icons/Error";
import { FieldProps } from "formik";
import DefaultImage from "../../../../assets/default-image.png";
import UploadIcon from "../../../../assets/upload-icon.png";
import { useDispatch } from "react-redux";
import { changeAvatar } from "../../../../store/users/actionCreators";
import { error } from "console";
import { url } from "inspector";

const useFormTextStyles = makeStyles({
  root: {
    width: "100%",
  },
  inputRoot: {
    padding: "12px 18px",
  },
  input: {
    display: "none",
    top: 12,
    left: 18,
  },
  focused: {
    fontSize: 16,
  },
  label: {
    color: "#7C67E2",
    alignItems: "center",
    display: "inline-flex",
  },
  labelSecondary: {
    opacity: "0.3",
    marginTop: 3,
  },
});

type FormFileFieldProps = FieldProps & React.HTMLProps<HTMLInputElement>;

function FormFileField({ field, form, ...props }: FormFileFieldProps) {
  const classes = useFormTextStyles();
  const hasError =
    (form.touched[field.name] && form.errors[field.name]) !== undefined;
  const errorMesagge = form.errors[field.name];
  const [image, setImage] = useState<string>("");

  const dispatch = useDispatch();

  const handleChange = (event: any): void => {
    if (event.target.files && event.target.files[0]) {
      const urlImage = URL.createObjectURL(event.target.files[0]);
      setImage(urlImage);
      dispatch(changeAvatar(event.target.files[0]));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div style={{ width: 84, height: 84 }}>
        {image === "" ? (
          <img
            style={{ display: "block", maxWidth: "100%", maxHeight: "100%" }}
            src={DefaultImage}
          />
        ) : (
          <img
            style={{ display: "block", maxWidth: "100%", maxHeight: "100%" }}
            src={image}
          />
        )}
      </div>
      <div style={{ marginLeft: 20 }}>
        <label htmlFor="avatar" className={classes.label}>
          Subir imagen <img style={{ marginLeft: 6 }} src={UploadIcon} />
        </label>
        <input
          id="avatar"
          type="file"
          style={{ display: "none" }}
          {...props}
          {...{ ...field, value: "" }}
          onChange={(e: any) => {
            field.onChange(e);
            handleChange(e);
          }}
        />
        <br></br>
        <div className={classes.labelSecondary}>
          <div>Tamaño recomendado: 400x400px</div>
          <div style={{ marginTop: -10 }}>400 KB máximo</div>
        </div>
        {hasError ? <div className="error-message">{errorMesagge}</div> : null}
      </div>
    </div>
  );
}

export default FormFileField;
