import React, {useState, useEffect} from 'react';
import {Users} from '../common/Users';
import {NewUserButton} from '../common/NewUserButton';
import {UserDialog} from '../common/UserDialog';
import {RootState} from '../../../store/config/types';
import {useDispatch, useSelector} from 'react-redux';
import {getEmployes} from '../../../store/businesses/actionCreators';

function CompanyDashboard() {
  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const business = useSelector((state: RootState) => state.businesses);

  useEffect(() => {
    if (
      !business.employesAccountsIsLoading &&
      business.employesAccountsErrorMessage === ''
    ) {
      dispatch(getEmployes());
    }
  }, []);

  return (
    <div className='company-dashboard'>
      <div className='company-navigation'>
        <NewUserButton isNutritionist={false} setOpen={setOpen} />
      </div>
      <Users isNutritionist={false} />
      <UserDialog open={open} setOpen={setOpen} isNutritionist={false} />
    </div>
  );
}

export default CompanyDashboard;
