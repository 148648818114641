import React, { useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import logo from "../../../assets/logo.png";
import { CompanyCard } from "../../common/CompanyCard";
import { EndUserCard } from "../../common/EndUserCard";
import { Button } from "@material-ui/core";
import { RootState } from "../../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import { CreateAnalysisRequest } from "../../../store/analyses/types";
import { createAndPayAnalysis} from "../../../store/analyses/actionCreators";
import { logout } from "../../../store/auth/actionCreators";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { LoadingAnimation } from "../../animations/LoadingAnimation";
import { REDIRECT_URL } from "../../../services/config";
import { Widgets } from "@material-ui/icons";

interface GTMEvent {
  event: string;
  [key: string]: any;
}

const mapStateToProps = (state: RootState) => ({
  analyses: state.analyses,
  instruments: state.instruments,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, AppActions>
) => ({
  createAndPayAnalysis: ({
    analysisAmount,
    instrumentToken,
    installments,
    coupon
  }: CreateAnalysisRequest) =>
    dispatch(createAndPayAnalysis({ analysisAmount, instrumentToken, installments, coupon })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function PaymentResult({
  analyses,
  instruments,
  createAndPayAnalysis,
  history,
}: PropsType) {
  const dispatch = useDispatch();
  const isCompany = false;

  const redirectTo = (path: string): void => {
    history.push(path);
  };

  const retryPayment = () => {
    createAndPayAnalysis({
      analysisAmount: 1,
      instrumentToken: instruments.instruments![0].instrumentToken,
      installments: parseInt(localStorage.getItem("installments")),
      coupon: localStorage.getItem("coupon") || "",
    });
  };
  const finalPrice = Number(localStorage.getItem('finalPrice')) === 0;
  //console.log('finalPrice is 0? :', finalPrice);
  
  const paymentError = (
    <div className="payment-result">
      <h2 style={{height:'auto'}}>Error al realizar el pago</h2>
      <p>Reintente nuevamente de lo contrario comuníquese con nosotros.</p>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          retryPayment();
        }}
      >
        Reintentar pago
      </Button>
    </div>
  );

  const pushToDataLayer = (event: GTMEvent, top: boolean) => {
    if(top) {
      if(window.top.dataLayer) {
        window.top.dataLayer.push(event);
      } else {
        console.warn('GTM datalayer is not defined');
      }
    }else {
      if(window.dataLayer) {
        window.dataLayer.push(event);
      } else {
        console.warn('GTM datalayer is not defined');
      }
    }
  };

  const paymentSuccess = (
    <div className="payment-result">
      <h2 style={{height:'auto'}}>Pago realizado con éxito</h2>
      <p>Ahora puedes coordinar la entrega del kit y comenzar tu proceso</p>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          //redirectTo("/dashboard");
          const finalPrice = localStorage.getItem('finalPrice');
          console.log(window.location, window.top.location);
          if (window.location != window.top.location) {
            pushToDataLayer({
              event: 'purchase',
              price: finalPrice, 
            }, true);
          } else {
            pushToDataLayer({
              event: 'purchase',
              precio: finalPrice
            }, false);
          }
          window.top.location.href = REDIRECT_URL+"dashboard/?payment=success";
        }}
      >
        Comenzar
      </Button>
    </div>
  );

  useEffect(() => {
    const el2 = document.querySelector(".user-signup") as HTMLElement;
    if(el2) {
      el2.style.backgroundColor = "white";
    }
  },[]);

  function goBack() {
    dispatch(logout());
    history.push("/");
  }
  function goToMeals() {
    history.push("/platos-semanales");
  }


  
  
  if(finalPrice) {
    //console.log("imprimo if payment result")
   return (
      <div className="container">
        <div className="header-payment">
          <div className="gotomeals" onClick={goToMeals}>
            <p>Platos semanales</p>
          </div>  
          <div className="logout" onClick={goBack}>
              <p>Cerrar Sesion</p>
          </div>
        </div>
        <div className="user-signup" style={{height:'auto !important'}}>
          <div className="signup-left-content">
            <img src={logo} alt="logo" className="logo" />
              {analyses.creatingAnalysis ? (
                <div className="payment-result" style={{display:'flex',flexDirection:'column', justifyContent:'center'}}>
                  <LoadingAnimation />
                </div>
              ) : analyses.createAnalysisErrorMessage !== "" ? (
                paymentError
              ) : (
                paymentSuccess
              )}
        </div>
        <div className="signup-right-content payment-right-content" style={{backgroundSize:'100% !important', width:'100% !important'}}>
          <img src={logo} alt="logo" className="logo" />
          <CompanyCard isCompany={isCompany} />
        </div>
        </div>
      </div>
   )
  }else {
    //console.log("imprimo else payment result")
    return (
      
      <div className="user-signup">
        <div className="signup-left-content">
          <img src={logo} alt="logo" className="logo" />
          {analyses.creatingAnalysis ? (
            <div className="payment-result">
              <LoadingAnimation />
            </div>
          ) : analyses.createAnalysisErrorMessage !== "" ? (
            paymentError
          ) : (
            paymentSuccess
          )}
        </div>
      </div>
    );
  }
}

export default connector(withRouter(PaymentResult));
