import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Button, createStyles, Theme, makeStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FullWidthSnackbar } from "../../common/FullWidthSnackbar";
import { Formik, Form, Field } from "formik";
import { FormTextField } from "../../common/forms/FormTextField";
import * as styleVars from "../../../styles/_variables.scss";
import { AuthInfoPanel } from "../AuthInfoPanel";
import { login } from "../../../store/auth/actionCreators";
import { LoginRequest } from "../../../store/auth/types";
import { RootState } from "../../../store/config/types";
import { AppActions } from "../../../store/config/ActionTypes";
import { connect, ConnectedProps } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import schemas from "../../../data/schemas";
import { useSelector } from "react-redux";
import { loginErrorMessageSelector } from "../../../store/auth/selector";
import { LoadingAnimation } from "../../animations/LoadingAnimation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pswRecoveryDiv: {
      margin: "auto 0",
    },
    pswRecoveryTxt: {
      margin: "5px 0",
      color: styleVars.lightSecondaryText,
      fontSize: 12,
    },
    loginBtn: {
      margin: "auto 0",
    },
    pswRecoveryBtn: {
      color: styleVars.primary,
      fontWeight: "bold",
      textDecoration: "none",
    },
    buyBox: {
      backgroundColor: styleVars.lightBackground,
      padding: "20px",
      display: "flex",
      justifyContent: "space-between",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
        "& p": {
          marginRight: "5px",
        },
      },
    },
    buyBtn: {
      margin: "auto 0",
      padding: "8px 30px",
    },
  })
);

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, AppActions>
) => ({
  login: ({ email, password }: LoginRequest) =>
    dispatch(login({ email, password })),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

interface Values {
  email: string;
  password: string;
}

function Login({ login, history }: PropsType) {
  const [open, setOpen] = useState(false);
  const loginErrorMessage = useSelector((state: RootState) =>
    loginErrorMessageSelector(state)
  );

  const isLoading = useSelector((x: RootState) => x.auth.loggingIn);
  const redirectTo = (path: string): void => {
    history.push(path);
  };

  const submit = (values: Values) => {
    login({
      email: values.email,
      password: values.password,
    });
  };

  useEffect(() => {
    if (loginErrorMessage !== "") {
      setOpen(true);
    }
  }, [loginErrorMessage]);

  const classes = useStyles();

  return (
    <div className="auth-partition-container">
      <AuthInfoPanel />
      <div className="auth-partition">
        <div className="auth-partition-no-img">
          <div className="auth-header" onClick={() => redirectTo("/")}>
            <div className="auth-back">
              <ArrowBackIcon />
              <p>Volver</p>
            </div>
          </div>
          <h2>Ingresar</h2>
          <p className="sub-heading">Con correo electrónico y contraseña</p>

          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={submit}
            validationSchema={schemas.LoginSchema}
          >
            {isLoading ? (
              <LoadingAnimation />
            ) : (
              <Form>
                <Field
                  name="email"
                  component={FormTextField}
                  type="text"
                  placeholder="Email"
                />
                <Field
                  name="password"
                  component={FormTextField}
                  type="password"
                  placeholder="Contraseña"
                />
                <div className="form-footer">
                  <div className={classes.pswRecoveryDiv}>
                    <p className={classes.pswRecoveryTxt}>
                      ¿Olvidaste tu contraseña?
                    </p>
                    <a className={classes.pswRecoveryBtn} href="/resetPassword">
                      Recuperar contraseña
                    </a>
                  </div>
                  <Button
                    className={classes.loginBtn}
                    color="primary"
                    variant="contained"
                    type="submit"
                    disableElevation
                  >
                    Ingresar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="register-btn">
            <Button
              variant="outlined"
              className={classes.buyBtn}
              onClick={() => redirectTo("/signup/?user=1")}
              disableElevation
            >
              Registrarme
            </Button>
          </div>
       
        </div>
      </div>
      <FullWidthSnackbar
        open={open}
        setOpen={setOpen}
        text="Datos incorrectos, por favor verifique correo y contraseña."
        success={false}
      />
    </div>
  );
}

export default connector(withRouter(Login));
