// GET /business
// Obtener información sobre la empresa

import { httpGet, httpPost } from "./base.service";
import { endpoints } from "./endpoints";
import {Account} from '../store/auth/types';

export const businessService = {
    kitsavailables,
    getEmployes
};

async function kitsavailables(): Promise<number> {
    return await httpGet<number>(`${endpoints.kitsavailable}`)
      .then((response) => {
        return response.parsedBody;
      })
      .catch((error) => {
        console.log("error", error);
        throw new Error(error);
      });
    }

async function getEmployes(): Promise<Account[] | undefined> {
    return await httpGet<Account[]>(`${endpoints['employes']}`)
      .then((response) => {
        return response.parsedBody;
      })
      .catch((error) => {
        throw new Error(error);
      });
    }