import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Users } from "../common/Users";
import { UserDialog } from "../common/UserDialog";
import { NewUserButton } from "../common/NewUserButton";
import BalanceSheet from "./BalanceSheet";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/config/types';
import { getPatients } from '../../../store/nutritionists/actionCreators';
import NutritionistVerification from "../../signup/NutritionistSignup/NutritionistVerification";

const options = ["Mis pacientes", "Mi balance"];

function NutritionistDashboard({ history }: RouteComponentProps) {
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const nutritionist = useSelector((state: RootState) => state.nutritionists);

  useEffect(() => {
    if (!nutritionist.refererIsLoading && nutritionist.refererAccountsErrorMessage === "") {
      dispatch(getPatients());
    }
  }, [])

  if (nutritionist.refererAccounts?.length > 0)
    return (
      <div className="nutritionist-dashboard">
        <div className="nutritionist-navigation">
          <div className="options">
            {options.map((opt, index) => {
              return (
                <span
                  onClick={() => setSelectedOption(index)}
                  className={selectedOption === index ? "selected" : ""}
                  key={index}
                >
                  {opt}
                </span>
              );
            })}
          </div>
          <NewUserButton isNutritionist setOpen={setOpen} />
        </div>
        {selectedOption === 0 ? <Users isNutritionist /> : <BalanceSheet />}
        <UserDialog open={open} setOpen={setOpen} isNutritionist />
      </div>
    );
  else return <div className="nutritionist-signup"><NutritionistVerification /></div>
}

export default withRouter(NutritionistDashboard);
