import { User } from "./types";
import { UsersActionTypes, USERS_LOAD, USERS_LOADING, USERS_LOAD_FAILED, USERS_ADDING, USERS_ADD, ChangeAvatarAction, CHANGE_AVATAR } from './actionTypes';
import { Dispatch } from "redux";

export const changeAvatar = (avatar: any) => {
  return (dispatch: Dispatch<ChangeAvatarAction>) => {
    dispatch(changingAvatar(avatar));
  }
}


//#region Fetch users

export const fetchUsers = () => {
  return (dispatch: Dispatch<UsersActionTypes>) => {
    dispatch(updatingUsers());

    //TODO: Call backend
    setTimeout(() => {
      updateUsers([]);
    }, 3000);
  };
};

const changingAvatar = (avatar: any): ChangeAvatarAction => ({
  type: CHANGE_AVATAR,
  avatar
})

const updatingUsers = (): UsersActionTypes => ({
  type: USERS_LOADING,
});

const updateUsers = (users: User[]): UsersActionTypes => ({
  type: USERS_LOAD,
  users,
});

const updateUSersFailed = (error: string): UsersActionTypes => ({
  type: USERS_LOAD_FAILED,
  error,
});

//#endregion

//#region Create user

export const createUser = (user: User) => {
  return (dispatch: Dispatch<UsersActionTypes>) => {
    dispatch(addingUsers());

    //TODO: Call backend
    setTimeout(() => {
      addUser({
        firstName: "",
        lastName: "",
        birthDate: new Date(),
        height: 180,
        weight: 80,
        gender: 1,
      });
    }, 3000);
  };
};

const addingUsers = (): UsersActionTypes => ({
  type: USERS_ADDING,
});

const addUser = (user: User): UsersActionTypes => ({
  type: USERS_ADD,
  user,
});

//#endregion
