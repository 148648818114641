import React from "react";
import { Lottie } from "@crello/react-lottie";
import loadAnimation from "../../../assets/loading.json";

function LoadingAnimation() {
  return (
    <Lottie
      style={{ marginBottom: "8px",display:"flex",justifyContent:"center" }}
      height="200px"
      width="200px"
      config={{ animationData: loadAnimation, loop: true }}
    />
    
  );
}

export default LoadingAnimation;
