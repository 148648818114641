import { httpPost, httpGet } from "./base.service";
import { endpoints } from "./endpoints";
import { Instrument } from "../store/instruments/types";
import { REDIRECT_URL } from "./config"

export const instrumentsService = {
  addInstrument,
  fetchInstruments,
};

async function addInstrument(): Promise<string | undefined> {
  return await httpPost<string>(`${endpoints["instruments"]}`, `${REDIRECT_URL}checkout`)
    .then((response) => {
      console.log("Intrumento --------");
      console.log(response.stringBody);
      return response.stringBody;
    })
    .catch((error) => {
      console.log("Intrumento ERROR--------");
      console.log(error);
      throw new Error(error);
    });
}

async function fetchInstruments(): Promise<Instrument[] | undefined> {
  return await httpGet<Instrument[]>(`${endpoints["instruments"]}`)
    .then((response) => {
      console.log("Intrumento fetch --------");
      console.log(response.parsedBody);
      return response.parsedBody;
    })
    .catch((error) => {
      console.log("Intrumento fetch error --------");
      console.log(error);
      throw new Error(error);
    });
}
