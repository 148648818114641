import React, { useState, useEffect } from "react";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { FormTextField } from "../../common/forms/FormTextField";
import { Button } from "@material-ui/core";
import { RootState } from "../../../store/config/types";
import {
  userFromCode,
  errorUserFromCode,
} from "../../../store/nutritionists/selector";
import { getUserFromCode } from "../../../store/nutritionists/actionCreators";
import { FullWidthSnackbar } from "../../common/FullWidthSnackbar";

interface Values {
  code: string;
}

interface NutritionistCodeProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  nutritionistCode: Values;
  setNutritionistCode: React.Dispatch<React.SetStateAction<Values>>;
}

function NutritionistCode({
  activeStep,
  setActiveStep,
  nutritionistCode,
  setNutritionistCode,
}: NutritionistCodeProps) {
  const dispatch = useDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const account = useSelector((state: RootState) => userFromCode(state));
  const error = useSelector((state: RootState) => errorUserFromCode(state));

  const submit = (values: Values, actions: FormikHelpers<Values>) => {
    if (values.code !== "") {
      if (hasSubmitted) {
        setActiveStep(activeStep + 1);
        setNutritionistCode(values);
      } else {
        dispatch(getUserFromCode(values.code.toUpperCase()));
        setHasSubmitted(true);
      }
    } else {
      setActiveStep(activeStep + 1);
      setNutritionistCode(values);
    }
  };

  useEffect(() => {
    if (error !== "") {
      setOpen(true);
      setHasSubmitted(false);
    }
  }, [error]);

  return (
    <div className="nutritionist-code">
      <b>
        No es necesario contar con este código para avanzar en el proceso.
      </b>
      <p className="title">¿Tenés un código de nutricionista?</p>
      <Formik initialValues={nutritionistCode} onSubmit={submit}>
        <Form>
          <Field
            name="code"
            component={FormTextField}
            type="text"
            placeholder="Código de nutricionista"
            disabled={hasSubmitted}
          />
          <p className="code-clarification">
            (Si no lo tenés podrás agregarlo luego en tu perfil)
          </p>
          <p className="code-speech">
            Con esto tu nutricionista podrá ver los resultados para asistirte
            con recomendaciones.
          </p>
          <p>
            
          </p>
          {account && (
            <p>
              <b>Tu nutricionista es: </b>
              {account.profile.firstName} {account.profile.lastName}
            </p>
          )}
          <div className="buttons">
            <Button
              classes={{ root: "outlined-button" }}
              onClick={() => setActiveStep(activeStep - 1)}
              color="primary"
              disableElevation
            >
              Anterior
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
            >
              Continuar
            </Button>
          </div>
        </Form>
      </Formik>
      <FullWidthSnackbar
        open={open}
        setOpen={setOpen}
        text={"No hemos encontrado un nutricionista con ese codigo"}
        success={false}
      />
    </div>
  );
}

export default NutritionistCode;
