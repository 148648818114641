import React, { useEffect, useState } from "react";
import { Header } from "../../common/Header";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Paper } from "@material-ui/core";

export enum EndUserNavigation {
  Foods,
  Results,
  Agenda,
  Meal
}

function CommonContainer({ children }) {
  const loc = useLocation();
  const [currentScreen, setCurrentScreen] = useState<number>();

  useEffect(() => {
    const route = loc.pathname;
    if (route == "/foods") {
      setCurrentScreen(EndUserNavigation.Foods);
    }
    if (route == "/platos") {
      setCurrentScreen(EndUserNavigation.Meal);
    }
    if (route == "/results") {
      setCurrentScreen(EndUserNavigation.Results);
    }
  }, [loc]);

  return (
    <div className="dashboard">
      <Header>
        <Navigator active={currentScreen} />
      </Header>
      {children}
    </div>
  );
}

function Navigator({ active }: { active: EndUserNavigation }) {
  const history = useHistory();

  return (
    <Paper elevation={1} className="navigator-container">
      <ul className="end-user-navigator">
        <li
          className={`${active == EndUserNavigation.Results ? "active" : ""}`}
        >
          <Button
            color="primary"
            variant="text"
            disableElevation
            onClick={() => {
              history.push("/results");
            }}
          >
            Resultados
          </Button>
        </li>
        <li className={`${active == EndUserNavigation.Foods ? "active" : ""}`}>
          <Button
            color="primary"
            variant="text"
            disableElevation
            onClick={() => {
              history.push("/foods");
            }}
          >
            Recomendaciones
          </Button>
        </li>
        <li className={`${active == EndUserNavigation.Meal ? "active" : ""}`}>
          <Button
            color="primary"
            variant="text"
            disableElevation
            onClick={() => {
              history.push("/platos-semanales");
            }}
          >
            Platos semanales
          </Button>
        </li>
        <li className={`${active == EndUserNavigation.Meal ? "active" : ""}`}>
          <Button
            color="primary"
            variant="text"
            disableElevation
            onClick={() => {
              history.push("/consulta-nutricional");
            }}
          >
            Consulta nutricionista
          </Button>
        </li>
      </ul>
    </Paper>
  );
}

export default CommonContainer;
