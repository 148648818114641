import {httpPost, httpGet, httpDelete} from './base.service';
import {endpoints} from './endpoints';
import {
  AssociateNutritionistRequest,
  Nutritionist,
  RegisterFromNutritionistRequest,
} from '../store/nutritionists/types';
import {Account} from '../store/auth/types';
import {User} from '../store/users/types';

export const nutritionistsService = {
  associateNutritionist,
  getAssociatedNutritionist,
  removeAssociatedNutritionist,
  registerFromNutritionist,
  completeRegisterFromNutritionist,
  getAssociatedReferer,
  getPatients,
  getUserFromCodeService,
};

async function associateNutritionist(
  associateNutritionistRequest: AssociateNutritionistRequest
): Promise<Nutritionist | undefined> {
  return await httpPost<Nutritionist>(
    `${endpoints['authNutritionist']}`,
    associateNutritionistRequest
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function getAssociatedNutritionist(): Promise<Nutritionist | undefined> {
  return await httpGet<Nutritionist>(`${endpoints['authNutritionist']}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function getPatients(): Promise<Account[] | undefined> {
  return await httpGet<Account[]>(`${endpoints['patients']}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function removeAssociatedNutritionist(): Promise<boolean | undefined> {
  return await httpDelete<boolean>(`${endpoints['authNutritionist']}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function registerFromNutritionist(
  registerFromNutritionistRequest: RegisterFromNutritionistRequest
): Promise<Account | undefined> {
  return await httpPost<Account>(
    `${endpoints['authRegisterFromNutritionist']}`,
    registerFromNutritionistRequest
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function completeRegisterFromNutritionist(
  token: string
): Promise<boolean | undefined> {
  return await httpGet<boolean>(
    `${endpoints['authRegisterFromNutritionist']}/${token}`
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function getAssociatedReferer(): Promise<User | undefined> {
  return await httpGet<User>(`${endpoints['authReferer']}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function getUserFromCodeService(
  code: string
): Promise<Account | undefined> {
  return await httpGet<Account>(`${endpoints['getUserFromCode']}/${code}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
