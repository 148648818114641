import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import image1 from "../../../assets/nutritionist-dashboard-1.png";
import image2 from "../../../assets/nutritionist-dashboard-2.png";
import image3 from "../../../assets/nutritionist-dashboard-3.png";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/config/types';
import { NewUserButton } from "../../dashboard/common/NewUserButton";
import { UserDialog } from "../../dashboard/common/UserDialog";

function NutritionistVerification({ history }: RouteComponentProps) {

  const account = useSelector((state: RootState) => state.auth);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Paper elevation={2} classes={{ root: "nutritionist-signup-content nutritionist-verified" }}>
      <Paper elevation={0} classes={{ root: "content-heading" }}>
        <p className="speech">Registro Completado</p>
      </Paper>
      <p className="title">Felicitaciones, estás registrado en Mr. Gen</p>
      <span>Este código podrás darle a tus pacientes para que se registren y puedas ver sus resultados. Recuérdales que deben ingresarlo en el registro de usuario en el tercer paso.</span>
      <div className="nutritionist-code">
        <span>{account && account.account.nutritionist && account.account.nutritionist.publicCode}</span>
      </div>
      <span>En tu dashboard vas a poder:</span>
      <div className="dashboard-stuff">
        <div className="chunk">
          <img src={image1} alt="1" />
          <span>Cargar nuevos pacientes</span>
        </div>
        <div className="chunk">
          <img src={image2} alt="2" />
          <span>Ver status de tus pacientes</span>
        </div>
        <div className="chunk">
          <img src={image3} alt="3" />
          <span>Ver tu balance de comisiones</span>
        </div>
      </div>
        <NewUserButton isNutritionist setOpen={setOpen} />
      <UserDialog open={open} setOpen={setOpen} isNutritionist />
    </Paper>
  );
}

export default withRouter(NutritionistVerification);
