import React from 'react';
import {Field} from 'formik';
import {FormTextField} from '../forms/FormTextField';
import {FormCheckbox} from '../forms/FormCheckbox';

function PaymentMethodFields({collectionNetworkSelected}) {
  return (
    <div className='payment-method-form'>
      <div className='bank-account-details'>
        <p className='title'>Quiero recibir el dinero en mi banco</p>
        <div className='horizontal-line'>
          <Field
            className='field'
            name='bank'
            component={FormTextField}
            disabled={collectionNetworkSelected}
            type='text'
            placeholder='Banco'
          />
          <Field
            className='field'
            name='accountNumber'
            component={FormTextField}
            disabled={collectionNetworkSelected}
            type='text'
            placeholder='Número de cuenta'
          />
        </div>
        <div className='horizontal-line'>
          <Field
            className='field'
            name='branchOffice'
            component={FormTextField}
            disabled={collectionNetworkSelected}
            type='text'
            placeholder='Sucursal'
          />
          <Field
            className='field'
            name='fullName'
            component={FormTextField}
            disabled={collectionNetworkSelected}
            type='text'
            placeholder='Nombre y apellido'
          />
        </div>
      </div>
      <div className='collection-network-details'>
        <p className='title'>Quiero cobrar por redes de cobranza</p>
        <div className='checkbox-horizontal-line'>
          <Field
            name='collectionNetwork'
            value={'Redpagos'}
            component={FormCheckbox}
            label='Redpagos'
          />
        </div>
        {collectionNetworkSelected ? (
          <div className='horizontal-line'>
            <Field
              className='field'
              name='ci'
              component={FormTextField}
              type='text'
              placeholder='Cédula de identidad'
            />
            <Field
              className='field'
              name='fullName'
              component={FormTextField}
              type='text'
              placeholder='Nombre y apellido'
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default PaymentMethodFields;
