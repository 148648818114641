import { CommissionsState  } from "./types";
import {
  LOAD_COMMISSIONS,
  LOADING_COMMISSIONS,
  LOAD_COMMISSIONS_FAILED
} from "./actionTypes";
import { CommissionsActionTypes } from "./actionTypes";

const initialState: CommissionsState = {
    commissionsAreLoading: false,
    commissions: null,
    commisssionsErrorMessage: ""
};

export function commissionsReducer(
  state = initialState,
  action: CommissionsActionTypes
): CommissionsState {
  switch (action.type) {
    case LOAD_COMMISSIONS:
      return {
        ...state,
        commissionsAreLoading: false,
        commissions: action.commissions,
      };
    case LOADING_COMMISSIONS:
      return {
        ...state,
        commissionsAreLoading: true,
      };
    case LOAD_COMMISSIONS_FAILED:
      return {
        ...state,
        commissionsAreLoading: false,
        commisssionsErrorMessage: action.error,
      };
    default:
      return state;
  }
}
