import React from "react";
import WhatsApp from '@material-ui/icons/WhatsApp';

const WhatsAppIcon = () => {

    return (
        <>
            <a
                href="https://wa.me/59899436487"
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <WhatsApp className="fa fa-whatsapp whatsapp-icon" />
            </a>
        </>
    )

}

export default WhatsAppIcon;
;