import { BankAccountsState } from "./types";
import {
  BankAccountsActionTypes,
  BANKACCOUNTS_ADD,
  BANKACCOUNTS_ADDING,
  BANKACCOUNTS_ADD_FAILED,
  BANKACCOUNTS_LOAD,
  BANKACCOUNTS_UPDATING,
  BANKACCOUNTS_LOAD_FAILED,
} from "./actionTypes";

const initialState: BankAccountsState = {
  bankAccountsLoading: false,
  bankAccounts: null,
  bankAccountsListErrorMessage: "",
  bankAccountsAdding: false,
  bankAccountsAddSuccess: false,
  bankAccountsAddErrorMessage: "",
};

export function bankAccountsReducer(
  state = initialState,
  action: BankAccountsActionTypes
): BankAccountsState {
  switch (action.type) {
    case BANKACCOUNTS_LOAD:
      return {
        ...state,
        bankAccountsLoading: false,
        bankAccounts: action.bankAccounts,
        bankAccountsListErrorMessage: "",
      };
    case BANKACCOUNTS_ADD:
      const newBankAccountsList = state.bankAccounts ? state.bankAccounts : [];
      newBankAccountsList.push(action.bankAccount);
      return {
        ...state,
        bankAccountsAdding: false,
        bankAccounts: newBankAccountsList,
        bankAccountsAddErrorMessage: "",
        bankAccountsAddSuccess: true,
      };
    case BANKACCOUNTS_LOAD_FAILED:
      return {
        ...state,
        bankAccountsLoading: false,
        bankAccountsListErrorMessage: action.error,
      };
    case BANKACCOUNTS_ADD_FAILED:
      return {
        ...state,
        bankAccountsAdding: false,
        bankAccountsAddSuccess: false,
        bankAccountsAddErrorMessage: action.error,
      };
    case BANKACCOUNTS_UPDATING:
      return {
        ...state,
        bankAccountsLoading: true,
      };
    case BANKACCOUNTS_ADDING:
      return {
        ...state,
        bankAccountsAdding: true,
      };
    default:
      return state;
  }
}
