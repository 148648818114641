import React, { useState, useEffect } from "react";
import { JoinMrGen } from "../common/JoinMrGen";
import { ArrowSteps } from "../../common/ArrowSteps";
import { Step } from "../../common/ArrowSteps/types";
import NutritionistData from "./NutritionistData";
import PaymentMethod from "./PaymentMethod";
import NutritionistVerification from "./NutritionistVerification";
import { EmailConfirmed } from "../common/EmailConfirmed";
import { UserType } from "../../../data/enums";
import logo from "../../../assets/logo.png";
import { urlHelper } from "../../../helpers/urlHelper";
import { useDispatch, useSelector } from "react-redux";
import { register, changeEmail } from "../../../store/auth/actionCreators";
import { Account as Acc } from "../../../store/auth/types";
import { Link } from "react-router-dom";
import { RootState } from "../../../store/config/types";

//1. Querés ser parte?
//2. Datos nutricionista
//3. Forma de cobro
//4. Correo enviado

const steps: Step[] = [
  {
    presentTitle: "Primer paso",
    name: "Datos personales",
  },
  {
    presentTitle: "Segundo paso",
    name: "Forma de cobro",
  },
  {
    presentTitle: "Tercer paso",
    name: "Verificación email",
  },
  {
    presentTitle: "Último paso",
    name: "Mi dashboard",
  },
];

function NutritionistSignup() {
  const dispatch = useDispatch();
  const registerSuccess = useSelector((x: RootState) => x.auth.registerSuccess);
  const loggedIn = useSelector((x: RootState) => x.auth.loggedIn);

  const [nutrData, setNutrData] = useState({
    names: "",
    surnames: "",
    email: "",
    phone: "",
    password: "",
    repeatPassword: "",
  });

  const [paymentData, setPaymentData] = useState({
    bank: "",
    accountNumber: "",
    branchOffice: "",
    fullName: "",
    ci: "",
    collectionNetwork: [],
  });

  const [activeStep, setActiveStep] = useState<number>(99);

  useEffect(() => {
    if (activeStep === 99) {
      let search = window.location.search;
      let vars = urlHelper.parseQueryString(search.substring(1, search.length));
      const step = vars.step ? Number(vars.step) : 0;
      setActiveStep(loggedIn ? 4 : step);
    }
  }, [activeStep, setActiveStep]);

  useEffect(() => {
    if (registerSuccess) {
      setActiveStep(activeStep + 1);
    }
  }, [registerSuccess]);

  const submitNutritionist = () => {
    const account = createRequestRegister();
    dispatch(register(account, nutrData.password));
    dispatch(changeEmail(account.email));
  };

  const createRequestRegister = (): Acc => {
    const nutritionist = {
      bankAccount: {
        number: paymentData.accountNumber,
        name: paymentData.bank,
        bankBranch: paymentData.branchOffice,
      },
      publicCode: "",
      cardIdentification: paymentData.ci,
      paymentName: paymentData.fullName,
      paymentType: paymentData.collectionNetwork.length > 0 ? 3 : 1,
    };
    const order = {
      invoiceId: 0,
      analyses: "",
      transaction: "",
      quantity: 1,
    };
    const analysis = {
      id: "",
      referenceId: "",
      created: new Date(),
      lastModified: new Date(),
      order: order,
    };
    const profile = {
      firstName: nutrData.names,
      lastName: nutrData.surnames,
      birthDate: "1000-12-31",
      height: null,
      weight: null,
      gender: null,
      analysis: analysis,
      mobile: nutrData.phone,
      address: "",
    };
    return {
      role: "Nutritionist",
      profile,
      email: nutrData.email,
      nutritionist,
      referer: null,
      business: null,
    };
  };

  if (activeStep === 0) {
    return (
      <JoinMrGen
        title="¿Querés ser parte de Mr. Gen?"
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        type={"nutritionist"}
      />
    );
  } else {
    return (
      <div className="nutritionist-signup">
        <div className="signup-header">
          <img src={logo} alt="logo" className="logo" />
          <Link to="/" className="back">
            Volver
          </Link>
        </div>
        <ArrowSteps activeStep={activeStep - 1} steps={steps} />
        {activeStep === 1 ? (
          <NutritionistData
            data={nutrData}
            setData={setNutrData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        ) : null}
        {activeStep === 2 ? (
          <PaymentMethod
            data={paymentData}
            setData={setPaymentData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            submitNutritionist={submitNutritionist}
          />
        ) : null}
        {activeStep === 3 ? (
          <EmailConfirmed userType={UserType.Nutritionist} />
        ) : null}
        {activeStep === 4 ? <NutritionistVerification /> : null}
      </div>
    );
  }
}

export default NutritionistSignup;
