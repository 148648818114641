import { TransactionsState } from "./types";
import {
  TransactionsActionTypes,
  TRANSACTIONS_LOADING,
  ADD_TRANSACTIONS,
  TRANSACTIONS_FAILED,
  GETTING_TRANSACTION,
  ADD_TRANSACTION,
  GET_TRANSACTION_FAILED,
} from "./actionTypes";

const initialState: TransactionsState = {
  loading: false,
  transactions: null,
  errorMessage: "",
  gettingTransaction: false,
  transaction: null,
  getTransactionErrorMessage: "",
};

export function transactionsReducer(state = initialState, action: TransactionsActionTypes): TransactionsState {
  switch (action.type) {
    case TRANSACTIONS_LOADING:
      return {
        ...state,
        loading: true,
        transactions: null,
        errorMessage: "",
      };

    case ADD_TRANSACTIONS:
      return {
        ...state,
        loading: false,
        transactions: action.transactions,
        errorMessage: "",
      };

    case TRANSACTIONS_FAILED:
      return {
        ...state,
        loading: false,
        transactions: null,
        errorMessage: action.error,
      };

    case GETTING_TRANSACTION:
      return {
        ...state,
        gettingTransaction: true,
        transaction: null,
        getTransactionErrorMessage: "",
      };

    case ADD_TRANSACTION:
      return {
        ...state,
        gettingTransaction: false,
        transaction: action.transaction,
        getTransactionErrorMessage: "",
      };

    case GET_TRANSACTION_FAILED:
      return {
        ...state,
        gettingTransaction: false,
        transaction: null,
        getTransactionErrorMessage: action.error,
      };

    default:
      return state;
  }
}
