import React, { useState, useEffect } from "react";
import { Formik, FormikHelpers, Form, Field } from "formik";
import { FormTextField } from "../../common/forms/FormTextField";
import { Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/config/types";
import schemas from "../../../data/schemas";
import {
  register,
  verifyEmail,
  changeEmail,
} from "../../../store/auth/actionCreators";
import { Account as Acc } from "../../../store/auth/types";
import FormFileField from "../../common/forms/FormFileField/FormFileField";
import firebase from "firebase";
import { ScriptSnapshot } from "typescript";
import { authService } from "../../../services/auth.service";
import { LoadingAnimation } from "../../animations/LoadingAnimation";

interface Values {
  name: string;
  email: string;
  rut: string;
  businessName: string;
  address: string;
  phone: string;
  password: string;
  avatar: string;
}

interface CompanyDataProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

firebase.initializeApp({
  apiKey: "AIzaSyAsPQODrTAmo-EPRY8JKmJjUVvrbUGcfcU",
  authDomain: "mrgen-dev.firebaseapp.com",
  projectId: "mrgen-dev",
  storageBucket: "mrgen-dev.appspot.com",
  messagingSenderId: "869285210136",
  appId: "1:869285210136:web:02443dbd840307d9b1bb6d",
  measurementId: "G-7437HEDWY1",
});

function CompanyData({ activeStep, setActiveStep }: CompanyDataProps) {
  const costs = useSelector((state: RootState) => state.costs);
  const users = useSelector((state: RootState) => state.users);
  const auth = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    rut: "",
    businessName: "",
    address: "",
    phone: "",
    password: "",
    avatar: "",
  });
  const dispatch = useDispatch();

  const [percent, setPersent] = useState<number>(0);

  /*const uploadImage = (name: string) => {
    const file = users.companyAvatar;
    const fileName = `${name}-${Math.random().toString()}-${file.name}`;
    const storageRef = firebase.storage().ref(`/avatars/${fileName}`);
    const task = storageRef.put(file);

    task.on('state_changed',
      snapshot => {
        let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setPersent(Math.round(percentage));
      },
      error => {
        console.log(error.message);
        return null;
      },
      () => {
        task.snapshot.ref.getDownloadURL().then(
          url => {
            return url;
          }
        )
      });
  }*/

  useEffect(() => {
    if (auth.registerSuccess) {
      console.log('success')
      setActiveStep(activeStep + 1);
    }
  }, [auth.registerSuccess]);

  const submit = async (values: Values, actions: FormikHelpers<Values>) => {
    //dispatch(verifyEmail(values.email));
    setIsLoading(true);
    setFormData(values);
    await authService.verifyEmail(values.email).then(
      (response) => {
        //var avatarURL = uploadImage(values.name);
        if(users.companyAvatar !== null){
          const file = users.companyAvatar;
          const fileName = `${values.name}-${Math.random().toString()}-${
            file.name
          }`;
          const storageRef = firebase.storage().ref(`/avatars/${fileName}`);
          const task = storageRef.put(file);

          task.on(
            "state_changed",
            (snapshot) => {
              let percentage =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setPersent(Math.round(percentage));
            },
            (error) => {
              console.log(error.message);
              setIsLoading(false);
              return null;
            },
            () => {
              task.snapshot.ref.getDownloadURL().then((url) => {
                const account = createRequestRegisterCompany(values, url);
                dispatch(changeEmail(values.email));
                dispatch(register(account, values.password));
                setIsLoading(false);
              });
            }
          );
          setIsLoading(false);
        }
        const account = createRequestRegisterCompany(values, "");
        dispatch(changeEmail(values.email));
        dispatch(register(account, values.password));
        setIsLoading(false);
        //if (auth.registerSuccess)
      },
      (error) => {
        setIsLoading(false);
        actions.setFieldError(
          "email",
          "El correo electrónico no esta disponible"
        );
      }
    );
  };

  const createRequestRegisterCompany = (
    values: Values,
    avatarURL: string
  ): Acc => {
    const business = {
      name: values.businessName,
      rut: values.rut,
      avatar: avatarURL,
    };
    const order = {
      invoiceId: 0,
      analyses: "",
      transaction: "",
      quantity: costs.selected,
    };
    const analysis = {
      id: "",
      referenceId: "",
      created: new Date(),
      lastModified: new Date(),
      order: order,
    };
    const profile = {
      firstName: values.name,
      lastName: values.name,
      birthDate: "12/12/12",
      height: null,
      weight: null,
      gender: null,
      analysis: analysis,
      mobile: values.phone,
      address: values.address,
    };
    return {
      role: "Business",
      profile,
      email: values.email,
      business,
      referer: null,
      nutritionist: null,
    };
  };
  if (isLoading) {
    return (
      <div className="loading-full-screen">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <Formik
      initialValues={formData}
      onSubmit={submit}
      validationSchema={schemas.CreateCompanySchema}
    >
      <Form className="form">
        <>
          <div className="your-data">
            <p className="title">Tus datos</p>
            <div className="horizontal-line">
              <Field
                className="field"
                name="name"
                component={FormTextField}
                type="text"
                placeholder="Nombre"
              />
              <Field
                className="field"
                name="email"
                component={FormTextField}
                type="text"
                placeholder="Email"
              />
            </div>
            <div className="horizontal-line">
              <Field
                className="field"
                name="rut"
                component={FormTextField}
                type="text"
                placeholder="RUT"
              />
              <Field
                className="field"
                name="businessName"
                component={FormTextField}
                type="text"
                placeholder="Razón social"
              />
            </div>
            <div className="horizontal-line">
              <Field
                className="field"
                name="address"
                component={FormTextField}
                type="text"
                placeholder="Dirección"
              />
              <Field
                className="field"
                name="phone"
                component={FormTextField}
                type="text"
                placeholder="Celular"
              />
            </div>
          </div>
          <div className="your-data">
            <p className="title">Avatar</p>
            <div className="horizontal-line">
              <Field
                className="field"
                name="avatar"
                component={FormFileField}
                type="file"
                placeholder="Avatar"
              />
              {percent > 0 && <div>{percent}%</div>}
            </div>
          </div>
          <div className="your-passwords">
            <p className="title">Contraseña para ingresar a tu perfil</p>
            <div className="horizontal-line">
              <Field
                className="field"
                name="password"
                component={FormTextField}
                type="password"
                placeholder="Contraseña"
              />
              <Field
                className="field"
                name="repeatPassword"
                component={FormTextField}
                type="password"
                placeholder="Repetir contraseña"
              />
            </div>
            {isLoading ? (
              <div className="loading-full-screen">
                <LoadingAnimation />
              </div>
            ) : (
              <div className="buttons">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  Continuar
                </Button>
              </div>
            )}
          </div>
        </>
      </Form>
    </Formik>
  );
}

export default CompanyData;
