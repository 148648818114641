import {
  BankAccountsActionTypes,
  BANKACCOUNTS_LOAD,
  BANKACCOUNTS_LOAD_FAILED,
  BANKACCOUNTS_UPDATING,
  BANKACCOUNTS_ADD,
  BANKACCOUNTS_ADD_FAILED,
  BANKACCOUNTS_ADDING,
} from "./actionTypes";
import { Dispatch } from "redux";
import { BankAccount } from "./types";
import { bankAccountsService } from "../../services/bankAccounts.service";

//#region Fetch bank accounts

export const fetchBankAccounts = () => {
  return (dispatch: Dispatch<BankAccountsActionTypes>) => {
    dispatch(updatingBankAccounts());

    bankAccountsService.fetchBankAccounts().then(
      (response) => {
        loadBankAccountsSuccess(response!);
      },
      (error) => {
        bankAccountsLoadFailed(error);
      }
    );
  };
};

const updatingBankAccounts = (): BankAccountsActionTypes => ({
  type: BANKACCOUNTS_UPDATING,
});

const loadBankAccountsSuccess = (bankAccounts: BankAccount[]): BankAccountsActionTypes => ({
  type: BANKACCOUNTS_LOAD,
  bankAccounts,
});

const bankAccountsLoadFailed = (error: string): BankAccountsActionTypes => ({
  type: BANKACCOUNTS_LOAD_FAILED,
  error,
});

//#endregion

//#region Create back account

export const createBankAccount = ({ name, number }: BankAccount) => {
  const bankAccount: BankAccount = {
    name,
    number,
  };

  return (dispatch: Dispatch<BankAccountsActionTypes>) => {
    dispatch(addingBankAccount());

    bankAccountsService.createBankAccount(bankAccount).then(
      (response) => {
        addBankAccount(response!);
      },
      (error) => {
        bankAccountsAddFailed(error);
      }
    );
  };
};

const addBankAccount = (bankAccount: BankAccount): BankAccountsActionTypes => ({
  type: BANKACCOUNTS_ADD,
  bankAccount,
});

const addingBankAccount = (): BankAccountsActionTypes => ({
  type: BANKACCOUNTS_ADDING,
});

const bankAccountsAddFailed = (error: string): BankAccountsActionTypes => ({
  type: BANKACCOUNTS_ADD_FAILED,
  error,
});

//#endregion
