import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
import * as styleVars from "./styles/_variables.scss";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: styleVars.primary,
    },
    success: {
      main: styleVars.success,
    },
    warning: {
      main: styleVars.warning,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: `"Mukta", sans-serif`,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 50,
        textTransform: "none",
        padding: "8px 48px",
        fontSize: 16,
      },
      text: {
        padding: "8px 48px",
      },
      outlined: {
        padding: "8px 48px",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
        "&$selected": {
          backgroundColor: styleVars.primary,
          color: styleVars.white,
          "&:hover": {
            backgroundColor: styleVars.primary,
            color: styleVars.white,
          },
        },
      },
    },
    MuiPopover: {
      paper: {
        marginTop: 8,
        fontSize: 14,
      },
    },
  },
});
