import {AuthState} from './types';
import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
  CONFIRMING_REGISTER,
  CONFIRM_REGISTER_SUCCESS,
  CONFIRM_REGISTER_FAILED,
  CHANGING_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  RESETING_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  CONFIRMING_RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_FAILED,
  GETTING_ACCOUNT,
  GET_ACCOUNT,
  GET_ACCOUNT_FAILED,
  CHANGE_EMAIL_SENT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
} from './actionTypes';
import {AuthActionTypes, LOGOUT} from './actionTypes';

const initialState: AuthState = {
  registering: false,
  registerSuccess: false,
  registerErrorMessage: '',
  loggingIn: false,
  loggedIn: false,
  loginErrorMessage: '',
  verifyingEmail: false,
  verifyEmailSuccess: false,
  verifyEmailErrorMessage: '',
  confirmingRegister: false,
  confirmRegisterSuccess: false,
  confirmRegisterErrorMessage: '',
  changingPassword: false,
  changePasswordSuccess: false,
  changePasswordErrorMessage: '',
  resetingPassword: false,
  resetPasswordSuccess: false,
  resetPasswordErrorMessage: '',
  confirmingResetPassword: false,
  confirmResetPasswordSuccess: false,
  confirmResetPasswordErrorMessage: '',
  gettingAccount: false,
  account: null,
  getAccountSuccess: false,
  getAccountErrorMessage: '',
  emailSent: '',
  updateAccountSuccess: false,
};

export function authReducer(
  state = initialState,
  action: AuthActionTypes
): AuthState {
  switch (action.type) {
    case CHANGE_EMAIL_SENT:
      return {
        ...state,
        emailSent: action.email,
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        registering: true,
        registerSuccess: false,
        registerErrorMessage: '',
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        registering: false,
        registerSuccess: true,
        registerErrorMessage: '',
      };

    case REGISTER_FAILED:
      return {
        ...state,
        registering: false,
        registerSuccess: false,
        registerErrorMessage: action.error,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        loginErrorMessage: '',
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        loginErrorMessage: '',
        account: action.account,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        loginErrorMessage: 'Por Favor Verifique su usuario y contraseña',
      };

    case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        verifyingEmail: true,
        verifyEmailSuccess: false,
        verifyEmailErrorMessage: '',
      };

    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyingEmail: false,
        verifyEmailSuccess: true,
        verifyEmailErrorMessage: '',
      };

    case VERIFY_EMAIL_FAILED:
      return {
        ...state,
        verifyingEmail: false,
        verifyEmailSuccess: false,
        verifyEmailErrorMessage: action.error,
      };

    case CONFIRMING_REGISTER:
      return {
        ...state,
        confirmingRegister: true,
        confirmRegisterSuccess: false,
        confirmRegisterErrorMessage: '',
      };

    case CONFIRM_REGISTER_SUCCESS:
      return {
        ...state,
        confirmingRegister: false,
        confirmRegisterSuccess: true,
        confirmRegisterErrorMessage: '',
      };

    case CONFIRM_REGISTER_FAILED:
      return {
        ...state,
        confirmingRegister: false,
        confirmRegisterSuccess: false,
        confirmRegisterErrorMessage: action.error,
      };

    case CHANGING_PASSWORD:
      return {
        ...state,
        changingPassword: true,
        changePasswordSuccess: false,
        changePasswordErrorMessage: '',
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changingPassword: false,
        changePasswordSuccess: true,
        changePasswordErrorMessage: '',
      };

    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changingPassword: false,
        changePasswordSuccess: false,
        changePasswordErrorMessage: action.error,
      };

    case RESETING_PASSWORD:
      return {
        ...state,
        resetingPassword: true,
        resetPasswordSuccess: false,
        resetPasswordErrorMessage: '',
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetingPassword: false,
        resetPasswordSuccess: true,
        resetPasswordErrorMessage: '',
      };

    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetingPassword: false,
        resetPasswordSuccess: false,
        resetPasswordErrorMessage: action.error,
      };

    case CONFIRMING_RESET_PASSWORD:
      return {
        ...state,
        confirmingResetPassword: true,
        confirmResetPasswordSuccess: false,
        confirmResetPasswordErrorMessage: '',
      };

    case CONFIRM_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        confirmingResetPassword: false,
        confirmResetPasswordSuccess: true,
        confirmResetPasswordErrorMessage: '',
      };

    case CONFIRM_RESET_PASSWORD_FAILED:
      return {
        ...state,
        confirmingResetPassword: false,
        confirmResetPasswordSuccess: false,
        confirmResetPasswordErrorMessage: action.error,
      };

    case GETTING_ACCOUNT:
      return {
        ...state,
        gettingAccount: true,
        account: null,
        getAccountSuccess: false,
        getAccountErrorMessage: '',
      };

    case GET_ACCOUNT:
      return {
        ...state,
        gettingAccount: false,
        account: action.account,
        getAccountSuccess: true,
        getAccountErrorMessage: '',
        loggedIn: true,
      };

    case GET_ACCOUNT_FAILED:
      return {
        ...state,
        gettingAccount: false,
        account: null,
        getAccountSuccess: false,
        getAccountErrorMessage: action.error,
      };
    case UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        gettingAccount: true,
      };
    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        gettingAccount: false,
        updateAccountSuccess: true,
      };
    case UPDATE_ACCOUNT_FAILURE:
      return {
        ...state,
        gettingAccount: false,
        updateAccountSuccess: false,
      };
    case LOGOUT:
      return {
        ...state,
        loggedIn: false,
        account: null,
      };

    default:
      return state;
  }
}
