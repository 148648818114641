import { httpGet } from "./base.service";
import { Commission } from "../store/commissions/types";
import { endpoints } from "./endpoints";
import moment from "moment";
import qs from "query-string";
export const commissionsService = {
  fetchCommissions,
};

async function fetchCommissions(month): Promise<Commission[] | undefined> {
  const startDate = moment().month(month).startOf("month").toJSON();
  const endDate = moment().month(month).endOf("month").toJSON();
  const query = qs.stringify({ startDate, endDate });
  return await httpGet<Commission[]>(`${endpoints.commissions}?${query}`)
    .then((response) => {
      console.log(response);
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
