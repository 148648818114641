import React, { useState, ReactNode, ChangeEvent, useEffect } from "react";
import {
  Card,
  CardContent,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { RootState } from "../../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import {
  fetchCommissions,
  selectPlan,
} from "../../../store/costs/actionCreators";
import { ConnectedProps, connect } from "react-redux";
import compraSegura from "../../../assets/compra-segura.png";
import qs from "query-string";

const useStyles = makeStyles({
  item: {
    padding: 10,
  },
});

//const options = ["Kit ADN Empresarial x10 $200", "Kit ADN Empresarial x50 $800", "Kit ADN Empresarial x100 $1000"];

const mapStateToProps = (state: RootState) => ({
  costs: state.costs,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, AppActions>
) => ({
  fetchCommissions: () => dispatch(fetchCommissions()),
  selectPlan: (id: number) => dispatch(selectPlan(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & { isCompany?: boolean };

function CompanyCard({
  costs,
  fetchCommissions,
  selectPlan,
  isCompany,
}: PropsType) {
  const classes = useStyles();
  const [selectedPlan, setSelectedPlan] = useState<number>(0);
  const [monto, setMonto] = useState<number>(0);
  const [fromCompany, setFromCompany] = useState<boolean>(false);

  useEffect(() => {
    const search = window.location.search;
    const companyUser = Number(qs.parse(search)["fromCompany"]);
    if (companyUser == 1) setFromCompany(true);
  },[]);

  useEffect(() => {
    if (!costs.isLoading && !costs.costs && costs.errorMessage === "") {
      fetchCommissions();
    }
  }, [costs.isLoading, costs.costs, costs.errorMessage, fetchCommissions]);

  const onSelectChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: ReactNode
  ): void => {
    let id = Number(event.target.value);
    setSelectedPlan(Number(event.target.value));
    selectPlan(Number(event.target.value));
  };

  function getPrice() {
    let amount = Number(localStorage.getItem("amount")) || 1; 
    const price = costs?.costs?.find(
      (x) => amount >= x.minAmount && amount <= x.maxAmount
    );
    return price ? price.unitCost * amount : 0; 
  }

  useEffect(() => { 

    async function getFinalPrice() {
      const price = await getPrice();
      if (!isNaN(price)) {
        setMonto(price);
      }
    }
    
    getFinalPrice();
  },[costs.costs]);

  return (
    <Card className="purchase-card">
      <CardContent>
        <h3 className="title">¡Oferta de lanzamiento</h3>
        <h3 className="title">por tiempo limitado!</h3>
        <div className="purchase-data">
          {/* {isCompany ? (
            <Select
              classes={{ root: classes.item }}
              fullWidth
              value={selectedPlan}
              onChange={onSelectChange}
            >
              {costs.costs &&
                costs.costs.map((o, index) => {
                  return (
                    <MenuItem
                      classes={{ root: classes.item }}
                      value={o.minAmount}
                    >
                      <span>
                        Kit ADN $
                        {isCompany
                          ? `Empresarial x{o.minAmount}{" "}
                      ${o.unitCost}`
                          : null}
                      </span>
                    </MenuItem>
                  );
                })}
            </Select>
          ) : ( */}
          <div className="purchase-data-body">
            <div className="purchase-kit-image">
              <img className="kit-img" width="100%" src="https://mistergen.com/static/media/step1-test-packaging.8b4dcca6.png" alt="NutriTest" />
            </div>
            <p className="adn-kit-text">

            </p>
            <div className="purchase-amount">
              {!fromCompany ? (
                <>
                  <span className="small-title"><b>Precio Regular: USD 299 </b><br/></span>
                  <span className="primary-link" style={{cursor:'default'}}>Obtené hoy tu Kit NutriTest<br/>por solo USD {monto} imp. Incl. </span>
                </>
              ):(
                <span className="primary-link" style={{cursor:'default'}}>Obtené hoy tu Kit NutriTest<br/></span>
              )
              }
              
              
            </div>
          </div>
          {/* )} */}
            <p className="purchase-speech">
              {
                !fromCompany ? (
                  <>
                <p className="purchase-speech">
                  también podés pagar en cómodas cuotas con VISA, Mastercard y OCA:                  
                  <ul>
                    <li>3 cuotas de: <b>USD {(monto/3).toFixed(2)}</b></li>
                    <li>6 cuotas de: <b>USD {(monto/6).toFixed(2)}</b></li>
                  </ul>
                </p>
                <p style={{textAlign: 'center', fontSize:'13px', margin:'0', padding:'0'}}>
                  <img src={compraSegura} alt="Visa" width="110px" style={{margin:'10px 10px 0px 10px'}}/>  
                  <br/>
                  Servicio disponible solo en Uruguay
                </p>
                </>
              ) : (
                <p className="purchase-speech">
                  <b>Incluye kits válidos por Mr. GEN NutriTest.</b>
                  
                </p>
              )
              }
            </p>
        </div>
      </CardContent>
    </Card>
  );
}

export default connector(CompanyCard);
