import React from "react";
import { Paper, Button } from "@material-ui/core";
import { Formik, FormikHelpers, Form, Field } from "formik";
import { FormTextField } from "../../common/forms/FormTextField";
import schemas from "../../../data/schemas";
import { authService } from "../../../services/auth.service";

interface Values {
  names: string;
  surnames: string;
  email: string;
  phone: string;
  password: string;
  repeatPassword: string;
}

interface NutritionistDataProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  data: Values;
  setData: React.Dispatch<React.SetStateAction<Values>>;
}

function NutritionistData({
  activeStep,
  setActiveStep,
  data,
  setData,
}: NutritionistDataProps) {
  const submit = (values: Values, actions: FormikHelpers<Values>) => {
    authService.verifyEmail(values.email).then(
      (response) => {
        setData(values);
        setActiveStep(activeStep + 1);
      },
      (error) => {
        actions.setFieldError('email', 'El correo electrónico no esta disponible');
      }
    );
  };

  return (
    <Formik
      initialValues={data}
      onSubmit={submit}
      validationSchema={schemas.CreateNutricionistSchema}
    >
      <Form className="form">
        <>
          <Paper
            elevation={2}
            classes={{ root: "nutritionist-signup-content" }}
          >
            <Paper elevation={0} classes={{ root: "content-heading" }}>
              <p className="title">¡Hola! Bienvenido</p>
              <p>
                Para registrarte como nutricionista en Mr.Gen debes completar
                los siguientes pasos
              </p>
            </Paper>
            <div className="nutritionist-data-form">
              <div className="horizontal-line">
                <Field
                  className="field"
                  name="names"
                  component={FormTextField}
                  type="text"
                  placeholder="Nombre"
                />
                <Field
                  className="field"
                  name="surnames"
                  component={FormTextField}
                  type="text"
                  placeholder="Apellido"
                />
              </div>
              <div className="horizontal-line">
                <Field
                  className="field"
                  name="email"
                  component={FormTextField}
                  type="text"
                  placeholder="Email"
                />
                <Field
                  className="field"
                  name="phone"
                  component={FormTextField}
                  type="text"
                  placeholder="Celular"
                />
              </div>
              <div className="horizontal-line">
                <Field
                  className="field"
                  name="password"
                  component={FormTextField}
                  type="password"
                  placeholder="Contraseña"
                />
                <Field
                  className="field"
                  name="repeatPassword"
                  component={FormTextField}
                  type="password"
                  placeholder="Repetir contraseña"
                />
              </div>
            </div>
          </Paper>
          <div className="continue-button">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
            >
              Continuar
            </Button>
          </div>
        </>
      </Form>
    </Formik>
  );
}

export default NutritionistData;
