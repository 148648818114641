import { Transaction } from "./types";

export const TRANSACTIONS_LOADING = "TRANSACTIONS_LOADING";
export const ADD_TRANSACTIONS = "ADD_TRANSACTIONS";
export const TRANSACTIONS_FAILED = "TRANSACTIONS_FAILED";

export interface RequestTransactionsAction {
  type: typeof TRANSACTIONS_LOADING;
}

export interface AddTransactionsAction {
  type: typeof ADD_TRANSACTIONS;
  transactions: Transaction[];
}

export interface TransactionsFailedAction {
  type: typeof TRANSACTIONS_FAILED;
  error: string;
}

export const GETTING_TRANSACTION = "GETTING_TRANSACTION";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const GET_TRANSACTION_FAILED = "GET_TRANSACTION_FAILED";

export interface RequestTransactionAction {
  type: typeof GETTING_TRANSACTION;
}

export interface AddTransactionAction {
  type: typeof ADD_TRANSACTION;
  transaction: Transaction;
}

export interface GetTransactionFailedAction {
  type: typeof GET_TRANSACTION_FAILED;
  error: string;
}

export type TransactionsActionTypes =
  | RequestTransactionsAction
  | AddTransactionsAction
  | TransactionsFailedAction
  | RequestTransactionAction
  | AddTransactionAction
  | GetTransactionFailedAction;
