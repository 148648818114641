import React, {useState} from 'react';
import {Paper, Button} from '@material-ui/core';
import {DateSelector} from './DateSelector';
import {AddressSelector} from './AddressSelector';
import {Address} from './AddressSelector/types';
import WarningIcon from '@material-ui/icons/Warning';
import {SelectableDate} from './DateSelector/DateSelector';

interface Props {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  date: SelectableDate;
  setDate: React.Dispatch<React.SetStateAction<SelectableDate>>;
  address?: Address;
  setAddress: React.Dispatch<React.SetStateAction<Address>>;
  submitKitDistribution: () => void;
  name: string;
  type: CoordinationType;
}

export enum CoordinationType {
  DELIVERY,
  PICKUP,
}

function KitCoordination({
  activeStep,
  setActiveStep,
  date,
  setDate,
  address,
  setAddress,
  name,
  submitKitDistribution,
  type,
}: Props) {
  const [coordinated, setCoordinated] = useState<boolean>(false);

  return (
    <div className='kit-coordination'>
      <Paper elevation={2} classes={{root: 'user-dashboard-content'}}>
        <Paper elevation={0} classes={{root: 'content-heading'}}>
          <p className='title'>Hola {name}</p>
          <p>
            {type === CoordinationType.DELIVERY
              ? `Debes coordinar un día y horario para que nuestro equipo entregue el kit de toma de muestra en tu domicilio`
              : 'Debes coordinar un día y horario para que nuestro equipo retire el kit de toma de muestra en tu domicilio'}
          </p>
        </Paper>
        <div className='date-and-address'>
          <AddressSelector
            address={address}
            setAddress={setAddress}
            coordinated={coordinated}
          />

          {address?.state === 0 ? (
            <DateSelector
              selected={date}
              setSelected={setDate}
              shouldContact={address?.state !== 0}
              coordinated={coordinated}
            />
          ) : null}
          {address && address.state !== 0 ? (
            <div className='warning-container'>
              <WarningIcon />
              <span>
                Para su dirección no hay{' '}
                {type === CoordinationType.DELIVERY ? 'envíos' : 'retiros'}{' '}
                coordinados automáticamente. Posteriormente a la confirmación de este paso recibirá una
                llamada del equipo de Mr. GEN para coordinar el {type === CoordinationType.DELIVERY ? 'envío' : 'retiro'} del kit.
              </span>
            </div>
          ) : null}
        </div>
      </Paper>
      <div className='buttons'>
        <Button
          classes={{root: 'outlined-button'}}
          onClick={() => {
            if (coordinated) {
              setCoordinated(false);
            } else {
              setActiveStep(activeStep - 1);
            }
          }}
          color='primary'
          disableElevation
        >
          Anterior
        </Button>
        {!coordinated ? (
          <Button
            color='primary'
            variant='contained'
            disableElevation
            disabled={!address || (address?.state === 0 && !date)}
            onClick={() => {
              setCoordinated(true);
            }}
          >
            Siguiente
          </Button>
        ) : (
          <Button
            color='primary'
            variant='contained'
            disableElevation
            disabled={!address || (address?.state === 0 && !date)}
            onClick={() => {
              submitKitDistribution();
            }}
          >
            Confirmar
          </Button>
        )}
      </div>
    </div>
  );
}

export default KitCoordination;
