import React, { useEffect, useState } from "react";
import { Button, Paper } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { commissionsService } from "../../../services/commissions.service";
import moment from "moment";
import { LoadingAnimation } from "../../animations/LoadingAnimation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/config/types";
import { fetchBankAccounts } from "../../../store/bankAccounts/actionCreators";
interface CommissionModel {
  monthTotal: number;
  patients: string[];
}
function BalanceSheet() {
  const [monthData, setMonthData] = useState<CommissionModel>();
  const [selectedMonth, setSelectedMonth] = useState<number>(moment().month());
  const [noPatients, setNoPatients] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector((x: RootState) => x);

  async function fetchMonthComissions() {
    setIsLoading(true);
    const commissions = await commissionsService.fetchCommissions(
      selectedMonth
    );
    if (commissions) {
      setNoPatients(false);
      parseComissions(commissions);
    } else {
      setNoPatients(true);
    }
    setIsLoading(false);
  }
  function parseComissions(commissions) {
    let monthTotal = 0;
    let patients = [];
    commissions.forEach((x) => {
      monthTotal += x.amount;
      patients.push(
        `${x.transaction.buyer.firstName} ${x.transaction.buyer.lastName}`
      );
    });

    setMonthData({ monthTotal, patients });
  }

  useEffect(() => {
    dispatch(fetchBankAccounts());
    fetchMonthComissions();
  }, [selectedMonth]);

  return (
    <Paper elevation={1} classes={{ root: "dashboard-content" }}>
      <div className="balance-sheet">
        <div className="top">
          <div className="balance-navigation">
            <Button
              onClick={() => setSelectedMonth(selectedMonth - 1)}
              className="back"
              disableElevation
              variant="text"
            >
              <KeyboardBackspaceIcon color="primary" />
              <span>
                {moment()
                  .month(selectedMonth - 1)
                  .format("MMMM")}
              </span>
            </Button>
            <div className="actual-month">
              {moment().month(selectedMonth).format("MMMM")}
            </div>
            <Button
              onClick={() => setSelectedMonth(selectedMonth + 1)}
              className="back"
              disableElevation
              variant="text"
            >
              <span>
                {moment()
                  .month(selectedMonth + 1)
                  .format("MMMM")}
              </span>
              <ArrowRightAltIcon color="primary" />
            </Button>
          </div>
          {isLoading ? (
            <LoadingAnimation />
          ) : noPatients ? (
            <div> No tuviste pacientes este mes </div>
          ) : (
            <>
              <div className="actual-balance">
                Tu balance para este mes es de <b>${monthData?.monthTotal}</b>
              </div>
              <div className="patients-listing">
                <span className="patients-amount">
                  Tuviste {monthData?.patients.length} paciente
                  {monthData?.patients.length > 1 ? "s" : ""}
                </span>
                <ul className="list">
                  {monthData?.patients.map((x) => (
                    <li>{x}</li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
        {/* <div className="bottom">
          <div className="grey-container">Se depositan en tu cuenta santander </div>
        </div> */}
      </div>
    </Paper>
  );
}

export default BalanceSheet;
