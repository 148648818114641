import { Account } from "./types";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";

export interface RequestRegisterAction {
  type: typeof REGISTER_REQUEST;
}

export interface RegisterSuccessAction {
  type: typeof REGISTER_SUCCESS;
}

export interface RegisterFailedAction {
  type: typeof REGISTER_FAILED;
  error: string;
}

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export interface RequestLoginAction {
  type: typeof LOGIN_REQUEST;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  account: Account;
}

export interface LoginFailedAction {
  type: typeof LOGIN_FAILED;
  error: string;
}

export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILED = "VERIFY_EMAIL_FAILED";

export interface RequestVerifyEmailAction {
  type: typeof VERIFY_EMAIL_REQUEST;
}

export interface VerifyEmailSuccessAction {
  type: typeof VERIFY_EMAIL_SUCCESS;
}

export interface VerifyEmailFailedAction {
  type: typeof VERIFY_EMAIL_FAILED;
  error: string;
}

export const CONFIRMING_REGISTER = "CONFIRMING_REGISTER";
export const CONFIRM_REGISTER_SUCCESS = "CONFIRM_REGISTER_SUCCESS";
export const CONFIRM_REGISTER_FAILED = "CONFIRM_REGISTER_FAILED";

export interface RequestConfirmRegisterAction {
  type: typeof CONFIRMING_REGISTER;
}

export interface ConfirmRegisterSuccessAction {
  type: typeof CONFIRM_REGISTER_SUCCESS;
}

export interface ConfirmRegisterFailedAction {
  type: typeof CONFIRM_REGISTER_FAILED;
  error: string;
}

export const CHANGING_PASSWORD = "CHANGING_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export interface RequestChangePasswordAction {
  type: typeof CHANGING_PASSWORD;
}

export interface ChangePasswordSuccessAction {
  type: typeof CHANGE_PASSWORD_SUCCESS;
}

export interface ChangePasswordFailedAction {
  type: typeof CHANGE_PASSWORD_FAILED;
  error: string;
}

export const RESETING_PASSWORD = "RESETING_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export interface RequestResetPasswordAction {
  type: typeof RESETING_PASSWORD;
}

export interface ResetPasswordSuccessAction {
  type: typeof RESET_PASSWORD_SUCCESS;
}

export interface ResetPasswordFailedAction {
  type: typeof RESET_PASSWORD_FAILED;
  error: string;
}

export const CONFIRMING_RESET_PASSWORD = "CONFIRMING_RESET_PASSWORD";
export const CONFIRM_RESET_PASSWORD_SUCCESS = "CONFIRM_RESET_PASSWORD_SUCCESS";
export const CONFIRM_RESET_PASSWORD_FAILED = "CONFIRM_RESET_PASSWORD_FAILED";

export interface RequestConfirmResetPasswordAction {
  type: typeof CONFIRMING_RESET_PASSWORD;
}

export interface ConfirmResetPasswordSuccessAction {
  type: typeof CONFIRM_RESET_PASSWORD_SUCCESS;
}

export interface ConfirmResetPasswordFailedAction {
  type: typeof CONFIRM_RESET_PASSWORD_FAILED;
  error: string;
}

export const GETTING_ACCOUNT = "GETTING_ACCOUNT";
export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ACCOUNT_FAILED = "GET_ACCOUNT_FAILED";

export interface RequestGetAccountAction {
  type: typeof GETTING_ACCOUNT;
}

export interface GetAccountAction {
  type: typeof GET_ACCOUNT;
  account: Account;
}

export interface GetAccountFailedAction {
  type: typeof GET_ACCOUNT_FAILED;
  error: string;
}

export const CHANGE_EMAIL_SENT = "CHANGE_EMAIL_SENT";

export interface ChangeEmailSentAction {
  type: typeof CHANGE_EMAIL_SENT;
  email: string;
}

export const LOGOUT = "LOGOUT";
export interface LogoutAction {
  type: typeof LOGOUT;
}

export const UPDATE_ACCOUNT_REQUEST = "UPDATE_ACCOUNT_REQUEST";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_FAILURE";

export interface RequestUpdateAccountAction {
  type: typeof UPDATE_ACCOUNT_REQUEST;
}

export interface RegisteUpdateAccountAction {
  type: typeof UPDATE_ACCOUNT_SUCCESS;
}

export interface RegisterUpdateAccountAction {
  type: typeof UPDATE_ACCOUNT_FAILURE;
  error: string;
}

export type AuthActionTypes =
  | RequestUpdateAccountAction
  | RegisteUpdateAccountAction
  | RegisterUpdateAccountAction
  | RequestRegisterAction
  | RegisterSuccessAction
  | RegisterFailedAction
  | RequestLoginAction
  | LoginSuccessAction
  | LoginFailedAction
  | RequestVerifyEmailAction
  | VerifyEmailSuccessAction
  | VerifyEmailFailedAction
  | RequestConfirmRegisterAction
  | ConfirmRegisterSuccessAction
  | ConfirmRegisterFailedAction
  | RequestChangePasswordAction
  | ChangePasswordSuccessAction
  | ChangePasswordFailedAction
  | RequestResetPasswordAction
  | ResetPasswordSuccessAction
  | ResetPasswordFailedAction
  | RequestConfirmResetPasswordAction
  | ConfirmResetPasswordSuccessAction
  | ConfirmResetPasswordFailedAction
  | RequestGetAccountAction
  | GetAccountAction
  | GetAccountFailedAction
  | ChangeEmailSentAction
  | LogoutAction;
