export interface HttpResponse<T> extends Response {
  parsedBody?: T;
  stringBody?: string;
}

const token = () => localStorage.token;

async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(request);

  await response.text().then((body) => {
    try {
      response.parsedBody = JSON.parse(body);
    } catch (ex) {
      response.stringBody = body;
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response;
}

export async function httpGet<T>(
  path: string,
  args: RequestInit = {
    method: "get",
    headers: new Headers({ "Content-Type": "application/json", Authorization: `Bearer ${token()}` }),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function httpPost<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: "post",
    headers: new Headers({ "Content-Type": "application/json", Authorization: `Bearer ${token()}` }),
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function httpPut<T>(
  path: string,
  body: any,
  args: RequestInit = { 
    method: "put", 
    headers: new Headers({ "Content-Type": "application/json", Authorization: `Bearer ${token()}` }), 
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function httpDelete<T>(path: string, args: RequestInit = { method: "delete" }): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}
