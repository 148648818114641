import {
  LoginRequest,
  User,
  RegisterRequest,
  ChangePasswordRequest,
  Account,
  RegisterFromBusiness,
  GetUserFromBusinessResponse,
  RegisterFromNutricionist,
} from '../store/auth/types';
import {httpPost, httpGet, httpPut} from './base.service';
import {endpoints} from './endpoints';

export const authService = {
  login,
  register,
  verifyEmail,
  confirmRegister,
  modifyPassword,
  resetPassword,
  confirmResetPassword,
  getAccount,
  getAccountById,
  createUserFromBusiness,
  getUserFromBusiness,
  updateAccount,
  createUserFromNutricionist,
  resendEmail,
};

async function login(loginRequest: LoginRequest): Promise<User | undefined> {
  return await httpPost<User>(`${endpoints['authLogin']}`, loginRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function register(
  registerRequest: RegisterRequest
): Promise<string | undefined> {
  return await httpPost<string>(`${endpoints['authAccount']}`, registerRequest)
    .then((response) => {
      return response.stringBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function verifyEmail(email: string): Promise<boolean | undefined> {
  return await httpGet<boolean>(
    `${endpoints['authVerifyEmail']}?email=${email}`
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function confirmRegister(
  token: string,
  accountId: string
): Promise<boolean | undefined> {
  return await httpGet<boolean>(
    `${endpoints['authConfirmation']}?token=${token}&id=${accountId}`
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function modifyPassword(
  changePasswordRequest: ChangePasswordRequest
): Promise<boolean | undefined> {
  return await httpPut<boolean>(
    `${endpoints['authChangePassword']}`,
    changePasswordRequest
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function resetPassword(email: string): Promise<boolean | undefined> {
  return await httpPost<boolean>(`${endpoints['authResetPassword']}`, {email})
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function resendEmail(email: string): Promise<boolean | undefined> {
  return await httpPost<boolean>(`${endpoints.authResendEmail}`, {email})
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function confirmResetPassword(
  token: string,
): Promise<boolean | undefined> {
  return await httpGet<boolean>(
    `${endpoints['authResetPassword']}/confirmation${token}`
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function getAccount(): Promise<Account | undefined> {
  return await httpGet<Account>(`${endpoints['authAccount']}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function getAccountById(accountId: string): Promise<Account | undefined> {
  return await httpGet<Account>(`${endpoints['authAccount']}/${accountId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function updateAccount(
  updateRequest: Account
): Promise<string | undefined> {
  return await httpPut<string>(`${endpoints['authAccount']}`, updateRequest)
    .then((response) => {
      return response.stringBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function createUserFromBusiness(
  registerRequest: RegisterFromBusiness
): Promise<string | undefined> {
  return await httpPost<string>(
    `${endpoints['authRegisterFromBusiness']}`,
    registerRequest
  )
    .then((response) => {
      return response.stringBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function getUserFromBusiness(
  userData: string
): Promise<GetUserFromBusinessResponse | undefined> {
  return await httpGet<GetUserFromBusinessResponse>(
    `${endpoints['authRegisterFromBusiness']}?userdata=${userData}`
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function createUserFromNutricionist(
  registerRequest: RegisterFromNutricionist
): Promise<string | undefined> {
  return await httpPost<string>(
    `${endpoints['authRegisterFromNutritionist']}`,
    registerRequest
  )
    .then((response) => {
      return response.stringBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
