import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Formik, Form, Field } from "formik";
import { FormTextField } from "../../common/forms/FormTextField";
import { AuthInfoPanel } from "../AuthInfoPanel";
import { resetPassword } from "../../../store/auth/actionCreators";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../store/config/types";
import { AppActions } from "../../../store/config/ActionTypes";
import { connect, ConnectedProps } from "react-redux";
import { FullWidthSnackbar } from "../../common/FullWidthSnackbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      marginTop: "3em",
      [theme.breakpoints.down("xs")]: {
        marginTop: 0,
        marginBottom: "25px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
    },
    formFooter: {
      display: "flex",
      marginTop: "6em",
      justifyContent: "flex-end",
      [theme.breakpoints.down("xs")]: {
        marginTop: "1em",
      },
    },
    loginBtn: {
      margin: "auto 0",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    rightPartitionHeading: {
      marginTop: "4em",
      [theme.breakpoints.down("xs")]: {
        marginTop: "1em",
      },
    },
  })
);

interface Values {
  email: string;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  resetPassword: (email: string) => dispatch(resetPassword(email)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function ResetPassword({ auth, resetPassword, history }: PropsType) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const redirectTo = (path: string): void => {
    history.push(path);
  };

  const submit = (values: Values) => {
    resetPassword(values.email);
    setOpen(true);
  };

  useEffect(() => {
    setOpen(false);
    if (!auth.resetingPassword && (auth.resetPasswordSuccess || auth.resetPasswordErrorMessage !== "")) {
      setOpen(true);
    }
  }, [auth.resetingPassword, auth.resetPasswordErrorMessage, auth.resetPasswordSuccess]);

  return (
    <div className="auth-partition-container">
      <AuthInfoPanel />
      <div className="auth-partition">
        <div className="auth-partition-no-img">
          <div className="auth-header" onClick={() => redirectTo("/login")}>
            <div className="auth-back">
              <ArrowBackIcon />
              <p>Volver</p>
            </div>
          </div>
          <h2 className={classes.rightPartitionHeading}>Recuperar contraseña</h2>
          <p className="sub-heading">
            Ingresa tu correo electrónico y te enviaremos los pasos para restablecer tu contraseña
          </p>
          <Formik
            initialValues={{ email: "" }}
            onSubmit={(values, actions) => {
              submit(values);
              actions.resetForm();
            }}
          >
            {(props) => (
              <Form className={classes.formContainer} onSubmit={props.handleSubmit}>
                <Field name="email" component={FormTextField} type="text" placeholder="Correo electrónico" />

                <div className={classes.formFooter}>
                  <Button
                    className={classes.loginBtn}
                    color="primary"
                    variant="contained"
                    type="submit"
                    disableElevation
                  >
                    Restablecer
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <FullWidthSnackbar
        open={open}
        setOpen={setOpen}
        text={
          auth.resetPasswordErrorMessage === ""
            ? "La contraseña se reseteó correctamente."
            : "Ocurrió un error al resetear la contraseña."
        }
        success={auth.resetPasswordErrorMessage === ""}
      />
    </div>
  );
}

export default connector(withRouter(ResetPassword));
