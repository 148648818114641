import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import logo from "../../../assets/logo.png";
import backArrow from "../../../assets/backArrow.png";
import roles from "../../../utils/roles";
import { userRole, userAccount } from "../../../store/auth/selector";
import { RootState } from "../../../store/config/types";
import { Menu, MenuItem } from "@material-ui/core";
import { logout } from "../../../store/auth/actionCreators";

function Header({
  history,
  children,
}: RouteComponentProps & { children?: any }) {
  const role = useSelector((state: RootState) => userRole(state));
  const actualUser = useSelector((state: RootState) => userAccount(state));
  const isFinalUser = role === roles.user;
  const isNutritionist = role === roles.nutricionist;
  const firstName = actualUser && actualUser.profile.firstName;

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showBackButton, setShowBackButton] = useState(false);

  const selectOption = (option: number) => {
    if (option === 0) redirectTo("/profile");
    if (option === 1) {
      dispatch(logout());
      redirectTo("/");
    }
    if(option === 2) {
      redirectTo("/platos-semanales");
    }
    if (option === 3) {
      redirectTo("/consulta-nutricional");
    }
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectTo = (path: string): void => {
    history.push(path);
  };

  useEffect(() => {
    if (
      history.location.pathname.includes("profile") ||
      history.location.pathname.includes("patientresult")
    ) {
      setShowBackButton(true);
    }
  }, []);

  let image =
    "https://res.cloudinary.com/dj29j7ziz/image/upload/v1555347817/iconmonstr-cat-3-120.png";
  let businessName;
  if (actualUser !== null && actualUser !== undefined) {
    const businessImage =
      actualUser.business !== undefined &&
      actualUser.business !== null &&
      actualUser!.business?.avatar;
    businessName =
      actualUser.business !== undefined &&
      actualUser.business !== null &&
      actualUser!.business?.name;
    image =
      businessImage !== undefined && businessImage !== null
        ? businessImage
        : "https://res.cloudinary.com/dj29j7ziz/image/upload/v1555347817/iconmonstr-cat-3-120.png";
  }

  return (
    <div>
      <div className="header">
        <img
          src={logo}
          alt="logo"
          className="logo"
          onClick={() =>
            window.localStorage.token === ""
              ? redirectTo("/")
              : redirectTo("/dashboard")
          }
        />
        {children}
        <div className="right">
          <div className="variable-data">
            {isNutritionist ? (
              <div className="nutritionist-id">
                {actualUser?.nutritionist?.publicCode}
              </div>
            ) : actualUser?.business?.avatar ? (
              <img src={image} alt="business" className="business-image" />
            ) : null}
          </div>
          <div className="profile-header" onClick={handleClick}>
            <div className="image">{firstName?.charAt(0)}</div>
            <span>{actualUser?.profile?.firstName}</span>
          </div>
        </div>
        <Menu
          keepMounted
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          transformOrigin={{
            horizontal: "left",
            vertical: "top",
          }}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => selectOption(0)}>Editar perfil</MenuItem>
          <MenuItem onClick={() => selectOption(2)}>Platos semanales</MenuItem>
          <MenuItem onClick={() => selectOption(3)}>Consulta nutricionista</MenuItem>
          <MenuItem onClick={() => selectOption(1)}>Cerrar sesión</MenuItem>
        </Menu>
      </div>
      {showBackButton && (
        <button
          style={{
            color: "#7c67e2",
            marginRight: 0,
            border: "none",
            backgroundColor: "transparent",
          }}
          onClick={() => history.push("/dashboard")}
        >
          <img
            style={{ width: 20, marginRight: 20 }}
            src={backArrow}
            alt="volver"
          />
          Volver
        </button>
      )}
    </div>
  );
}

export default withRouter(Header);
