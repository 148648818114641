import React from "react";

import { Paper } from "@material-ui/core";
import CommonContainer from "./common/CommonContainer";

function Agenda() {
  return (
    <CommonContainer>
      <div className="agendaPadding">
        <Paper className="result-card welcome">
          <div className="agenda">
            Estamos trabajando para que puedas próximamente agendar tus
            consultas con nutricionistas desde aquí, mientras tanto puedes
            agendar directamente en nuestro WhatsApp haciendo{" "}
            <a
              href="https://wa.me/59899436487?text=Hola.%20Me%20interesa%20agendar%20una%20consulta%20con%20nutricionista.%20Gracias%20"
              target="_blank"
            >
              click aquí
            </a>{" "}
            o comunicándote al 099 436 487.
          </div>
        </Paper>
      </div>
    </CommonContainer>
  );
}

export default Agenda;
