import React, { useEffect, useCallback, useState, useRef } from "react";
import logo from "../../../assets/logo.png";
import { LoadingAnimation } from "../../animations/LoadingAnimation";
import { CompanyCard } from "../../common/CompanyCard";
import { EndUserCard } from "../../common/EndUserCard";
import { RootState } from "../../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import { addInstrument } from "../../../store/instruments/actionCreators";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { userAccount } from "../../../store/auth/selector";
import roles from "../../../utils/roles";
import { Header } from "../../common/Header";
import { Menu } from "@material-ui/core";
import { logout } from "../../../store/auth/actionCreators";
import {BASE_URL_FOODS, BASE_URL, REDIRECT_URL} from '../../../services/config';
import request from 'request';
import { set } from "date-fns";
import { Formik, Form, Field } from "formik";
import { FormTextField } from "../../common/forms/FormTextField";
import { Button } from "@material-ui/core";
import FormFileField from "../../common/forms/FormFileField/FormFileField";

type PropsType = RouteComponentProps<any>;

const LoadingPaymentClone: React.FC<PropsType> = ({ history }) => {
  const instruments = useSelector((state: RootState) => state.instruments);
  const [loading, setLoading] = useState(true);
  
  const dispatch = useDispatch();

  const iframeRef = useRef(null);

  useEffect(() => {
    window.addEventListener("message", receiveMessage);
  
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);
  
  function receiveMessage(event: MessageEvent) {
    console.log("Received message from iframe: ");
    console.log(event.data);
    console.log(event);
    
    if (event.origin !== REDIRECT_URL ) {
        console.warn("Mensaje de origen desconocido:", event.origin);
        return;
    }
  
    if (event.data === "redirectOccurred") {
        console.log("redireccion ocurrida desde iframe");
        //redirectTo("/checkout");
    } else if (event.data === "instrumentAdded") {
        console.log("instrumento agregado desde iframe");
      // Redirige a /checkout cuando el instrumento se ha agregado correctamente
      //redirectTo("/checkout");
    } else if (event.data === "errorOccurred") {
        console.log("error ocurrido desde iframe");
      // Manejo de errores, redirigir o mostrar un mensaje de error
      //redirectTo("/error");
    }
  }

  const iframeContent = (
    <iframe
      ref={iframeRef}
      height={700}
      width={700}
      src={instruments.instrumentUrl!}
      title="plexo"
      onLoad={() => {
        window.parent.postMessage("instrumentAdded", REDIRECT_URL );
      }}
    />
  );

  const checkIframeUrl = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      try {
        const iframeUrl = iframe.contentWindow.location.pathname;
        if (iframeUrl === '/checkout') {
          window.top.location.href = iframe.contentWindow.location.href;
        }
      } catch (e) {
        console.log(e);
        // Puede fallar debido a políticas de CORS, ignora el error
      }
    }
  };

  const handleIframeLoad = () => {
    checkIframeUrl();
    const iframe = iframeRef.current;
    if (iframe) {
      const observer = new MutationObserver(() => {
        checkIframeUrl();
      });
      observer.observe(iframe.contentWindow.document, {
        childList: true,
        subtree: true
      });

      // Desconectar el observer cuando el iframe se descarga
      return () => observer.disconnect();
    }
  };

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.onload = handleIframeLoad;
    }

    return () => {
      if (iframe) {
        iframe.onload = null;
      }
    };
  }, [instruments.instrumentUrl]);


  const handleAddInstrument = () => {
    dispatch(addInstrument());
  };

  return (
    <div>
      <button onClick={handleAddInstrument}>Add Instrument</button>
        <br/>
        <br/>
        {instruments.instrumentUrl ? iframeContent : <div>cargando plexo</div>}
    </div>
  );
};

export default withRouter(LoadingPaymentClone);