import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../config/types';

const authStateSelector = (state: RootState) => state.auth;

export const authSelector = createSelector(
  authStateSelector,
  (authReducer) => authReducer
);

export const loginErrorMessageSelector = (state: RootState) => {
  return state!.auth.loginErrorMessage;
};

export const userAccount = (state: RootState) => {
  return state!.auth.account;
};

export const userRole = (state: RootState) => {
  return state!.auth.account?.role;
};

export const editSuccess = (state: RootState) => {
  return state!.auth.updateAccountSuccess;
};
