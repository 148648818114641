import React, { useState, useEffect } from "react";
import { Paper, Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { FormTextField } from "../../common/forms/FormTextField";
import { RootState } from "../../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import { modifyPassword } from "../../../store/auth/actionCreators";
import { connect, ConnectedProps } from "react-redux";
import schemas from "../../../data/schemas";
import { userAccount, editSuccess } from "../../../store/auth/selector";
import {
  updateProfile,
  upadteFailed,
  changePasswordFailed,
} from "../../../store/auth/actionCreators";
import { FullWidthSnackbar } from "../../common/FullWidthSnackbar";
import { LoadingAnimation } from "../../animations/LoadingAnimation";

interface userValues {
  name: string;
  lastname: string;
  dob: string;
  email: string;
  phone: string;
}

interface passwordValues {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, AppActions>
) => ({
  changePassword: (oldPassword: string, newPassword: string) =>
    dispatch(modifyPassword(oldPassword, newPassword)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function MyData({ auth, changePassword, history }: PropsType) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const userData = useSelector((state: RootState) => userAccount(state));
  const editOk = useSelector((state: RootState) => editSuccess(state));
  const isGettingAccount = useSelector(
    (state: RootState) => state.auth.gettingAccount
  );
  const isUpdatingPassword = useSelector(
    (state: RootState) => state.auth.changingPassword
  );

  const passwordEditOk = useSelector(
    (state: RootState) => state.auth.changePasswordSuccess
  );

  const submitUser = (values: userValues) => {
    userData.profile.firstName = values.name;
    userData.profile.lastName = values.lastname;
    userData.profile.birthDate = values.dob;
    userData.profile.mobile = values.phone;

    dispatch(updateProfile(userData));
  };

  const submitPassword = (values: passwordValues) => {
    changePassword(values.oldPassword, values.newPassword);
  };

  useEffect(() => {
    if (editOk) {
      setOpen(true);
      setTimeout(() => {
        dispatch(upadteFailed(""));
      }, 2000);
    }
  }, [editOk]);

  useEffect(() => {
    if (passwordEditOk) {
      setOpen(true);
      setTimeout(() => {
        dispatch(changePasswordFailed(""));
      }, 2000);
    }
  }, [passwordEditOk]);

  return (
    userData &&
    userData.profile && (
      <Paper
        elevation={1}
        classes={{ root: "dashboard-content special-border-radius" }}
      >
        <div className="profile-form-data">
          <Formik
            initialValues={{
              name: userData.profile.firstName,
              lastname: userData.profile.lastName,
              dob: userData.profile.birthDate.substring(0, 10),
              email: userData.email,
              phone: userData.profile.mobile,
            }}
            onSubmit={submitUser}
          >
            {isGettingAccount ? (
              <LoadingAnimation />
            ) : (
              <Form className="form">
                <div className="horizontal-line">
                  <Field
                    className="field"
                    name="name"
                    component={FormTextField}
                    type="text"
                    placeholder="Nombre"
                  />
                  <Field
                    className="field"
                    name="lastname"
                    component={FormTextField}
                    type="text"
                    placeholder="Apellido"
                  />
                </div>
                <div className="horizontal-line">
                  <Field
                    className="field"
                    name="dob"
                    component={FormTextField}
                    type="date"
                    placeholder="Fecha de nacimiento"
                  />
                  <Field
                    className="field"
                    name="email"
                    component={FormTextField}
                    type="text"
                    placeholder="Email"
                  />
                </div>
                <div className="horizontal-line">
                  <Field
                    className="field"
                    name="phone"
                    component={FormTextField}
                    type="text"
                    placeholder="Celular"
                  />
                </div>
                <div className="buttons">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disableElevation
                  >
                    Guardar cambios
                  </Button>
                </div>
              </Form>
            )}
          </Formik>

          <Formik
            initialValues={{
              oldPassword: "",
              newPassword: "",
              repeatPassword: "",
            }}
            onSubmit={(values, actions) => {
              submitPassword(values);
              actions.resetForm();
            }}
            validationSchema={schemas.ChangePasswordSchema}
          >
            {(props) =>
              isUpdatingPassword ? (
                <LoadingAnimation />
              ) : (
                <Form className="form" onSubmit={props.handleSubmit}>
                  <p className="title">Cambiar contraseña</p>
                  <div className="horizontal-line">
                    <Field
                      className="field"
                      name="oldPassword"
                      component={FormTextField}
                      type="password"
                      placeholder="Contraseña"
                    />
                    <Field
                      className="field"
                      name="newPassword"
                      component={FormTextField}
                      type="password"
                      placeholder="Nueva Contraseña"
                    />
                    <Field
                      className="field"
                      name="repeatPassword"
                      component={FormTextField}
                      type="password"
                      placeholder="Repetir nueva contraseña"
                    />
                  </div>
                  <div className="buttons">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disableElevation
                    >
                      Actualizar contraseña
                    </Button>
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>
        {(editOk || passwordEditOk) && (
          <FullWidthSnackbar
            open={open}
            setOpen={setOpen}
            success
            text={"Cuenta editada correctamente"}
          />
        )}
      </Paper>
    )
  );
}

export default connector(withRouter(MyData));
