import React from "react";
import { FormControlLabel, Checkbox, makeStyles, createStyles, Theme } from "@material-ui/core";
import { FieldProps } from "formik";

const useCheckboxStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      width: "30%",
      marginBottom: 5,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  })
);

interface CheckboxProps {
  label: string;
  value: string;
}

type FormCheckboxProps = FieldProps & CheckboxProps;

function FormCheckbox({ field, form, label, value }: FormCheckboxProps) {
  const classes = useCheckboxStyles();

  return (
    <FormControlLabel
      classes={{ root: classes.checkbox }}
      control={
        <Checkbox
          color="primary"
          checked={field.value.includes(value)}
          onChange={() => {
            if (field.value.includes(value)) {
              const nextValue = field.value.filter((newValue: string) => newValue !== value);
              form.setFieldValue(field.name, nextValue);
            } else {
              const nextValue = field.value.concat(value);
              form.setFieldValue(field.name, nextValue);
            }
          }}
        />
      }
      label={label}
    />
  );
}

export default FormCheckbox;
