export const urlHelper = { parseQueryString, isOnPublicUrl };

function parseQueryString(queryString?: string): any {
  if (!queryString) {
    queryString = window.location.search.substring(1);
  }

  const params: any = {};

  const queries = queryString.split("&");

  queries.forEach((indexQuery: string) => {
    const indexPair = indexQuery.split("=");

    const queryKey = decodeURIComponent(indexPair[0]);
    const queryValue = decodeURIComponent(indexPair.length > 1 ? indexPair[1] : "");

    params[queryKey] = queryValue;
  });

  return params;
}

/* const publicUrls = ["/login", "/resetPassword", "/signup", "/signup/", "/", "/verified", "/createUser", "/changePassword","/terminos-condiciones-uso-uy", "/platos-semanales", "/consulta-nutricional", "/signup/?user=1"//,"/meals-checkout"];

function isOnPublicUrl(url: string): boolean {
  return publicUrls.indexOf(url) > -1;
} */

const publicUrls = [
  "/login", 
  "/resetPassword", 
  "/signup", 
  "/signup/", 
  "/", 
  "/verified", 
  "/createUser", 
  "/changePassword",
  "/terminos-condiciones-uso-uy", 
  "/platos-semanales", 
  "/consulta-nutricional", 
  "/signup/?user=1"
  // "/meals-checkout"
];

function isOnPublicUrl(url: string): boolean {
  const path = url.split('?')[0];
  
  return publicUrls.includes(path);
}