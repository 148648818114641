import React, { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { Paper, Button } from "@material-ui/core";
import { PaymentMethodFields } from "../../common/PaymentMethodFields";

interface Values {
  bank: string;
  accountNumber: string;
  branchOffice: string;
  fullName: string;
  collectionNetwork: string[];
  ci: string;
}

interface PaymentMethodProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  data: Values;
  setData: React.Dispatch<React.SetStateAction<Values>>;
  submitNutritionist: () => void;
}

function PaymentMethod({
  activeStep,
  setActiveStep,
  data,
  setData,
  submitNutritionist,
}: PaymentMethodProps) {
  const [showSummary, setShowSummary] = useState(false);


  const submit = (values: Values, actions: FormikHelpers<Values>) => {
    setData(values);
    if (showSummary) {
      submitNutritionist();
    } else {
      setShowSummary(true);
    }
  };

  function onBack() {
    if (showSummary) {
      setShowSummary(false);
    } else {
      setActiveStep(activeStep - 1);
    }
  }

  return (
    <Formik initialValues={data} onSubmit={submit}>
      {({ values }) => {
        let collectionNetworkSelected = values.collectionNetwork.length > 0;
        if(collectionNetworkSelected) {
          values.bank = "";
          values.accountNumber = "";
          values.branchOffice = "";
        }
        return (
          <Form className="form">
            <>
              <Paper
                elevation={2}
                classes={{ root: "nutritionist-signup-content" }}
              >
                <Paper elevation={0} classes={{ root: "content-heading" }}>
                  <p className="speech">
                    Para poder cobrar debes indicarnos <br />
                    la forma que más te quede cómoda.
                  </p>
                </Paper>
                {showSummary ? (
                  collectionNetworkSelected ?
                  (
                    <div className="payment-method-details">
                    <p className="details-title">Tu forma de cobro elegida</p>
                    <p className="detail">Red de cobranza: {values.collectionNetwork[0]}</p>
                    <p className="detail">
                      Documento: {values.ci}
                    </p>
                    <p className="detail">
                      Nombre y Apellido: {values.fullName}
                    </p>
                    <div className="grey-container">
                      <p>
                        Todos los meses se va a depositar en tu cuenta si tienes
                        balance positivo a cobrar.
                      </p>
                    </div>
                  </div>
                  ) :
                  (
                  <div className="payment-method-details">
                    <p className="details-title">Tu forma de cobro elegida</p>
                    <p className="detail">Banco: {values.bank}</p>
                    <p className="detail">Sucursal: {values.branchOffice}</p>
                    <p className="detail">
                      Número de cuenta: {values.accountNumber}
                    </p>
                    <p className="detail">
                      Nombre y Apellido: {values.fullName}
                    </p>
                    <div className="grey-container">
                      <p>
                        Todos los meses se va a depositar en tu cuenta si tienes
                        balance positivo a cobrar.
                      </p>
                    </div>
                  </div>
                )) : (
                  <PaymentMethodFields
                    collectionNetworkSelected={collectionNetworkSelected}
                  />
                )}
              </Paper>
              <div className="continue-button">
                <Button
                  classes={{ root: "outlined-button" }}
                  onClick={onBack}
                  color="primary"
                  disableElevation
                >
                  Anterior
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Continuar
                </Button>
              </div>
            </>
          </Form>
        );
      }}
    </Formik>
  );
}

export default PaymentMethod;
