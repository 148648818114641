import * as Yup from "yup";

const passwordMatchString = "Las contraseñas no coinciden.";
const invalidMailFomrat = "Formato inválido de correo electrónico.";
const emptyInput = "El campo es obligatorio.";
const invalidFormat = "Formato incorrecto.";
const invalidPassword = "La contraseña debe tener al menos 6 caracteres y un número entre 0 y 9.";

//const passwordRegex = /^.{6,}$/;//6 caracteres
const passwordRegex = /^(?=.*\d).{6,}$/;//6 caracteres al menos 1 dígito
const nameRegex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
const emailRegex = /^[^\s@&]+@[^\s@&]+\.[^\s@&]+$/;

const dateValidation = Yup.date()
  .typeError("La fecha debe estar en formato mm/dd/yyyy")
  .max(new Date(), "La fecha no puede ser en el futuro")
  .required(emptyInput);

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(passwordRegex, invalidPassword)
    .oneOf([Yup.ref("password")], passwordMatchString),
  repeatPassword: Yup.string()
    .matches(passwordRegex, invalidPassword)
    .oneOf([Yup.ref("newPassword")], passwordMatchString),
});

const CreateNutricionistSchema = Yup.object().shape({
  names: Yup.string().matches(nameRegex, invalidFormat).required(emptyInput),
  surnames: Yup.string().matches(nameRegex, invalidFormat).required(emptyInput),
  email: Yup.string().email(invalidMailFomrat).matches(emailRegex, "El correo no puede contener caracteres especiales como &.").required(emptyInput),
  phone: Yup.string().matches(/^\d{1,10}$/, "El teléfono debe tener entre 1 y 10 dígitos y solo contener números.").required(emptyInput),
  password: Yup.string().matches(passwordRegex, invalidPassword).required(emptyInput),
  repeatPassword: Yup.string().matches(passwordRegex, invalidPassword).required(emptyInput).oneOf([Yup.ref("password")], passwordMatchString),
});

const CreateCompanySchema = Yup.object().shape({
  name: Yup.string().matches(nameRegex, invalidFormat).required(emptyInput),
  email: Yup.string().email(invalidMailFomrat).matches(emailRegex, "El correo no puede contener caracteres especiales como &.").required(emptyInput),
  rut: Yup.number().typeError(invalidFormat).positive(invalidFormat).required(emptyInput),
  businessName: Yup.string().required(emptyInput),
  address: Yup.string().required(emptyInput),
  phone: Yup.string().matches(/^\d{1,10}$/, "El teléfono debe tener entre 1 y 10 dígitos y solo contener números.").required(emptyInput),
  password: Yup.string().matches(passwordRegex, invalidPassword).required(emptyInput),
  repeatPassword: Yup.string().matches(passwordRegex, invalidPassword).required(emptyInput).oneOf([Yup.ref("password")], passwordMatchString),
  avatar: Yup.string(),
});

const CreateUserSchema = Yup.object().shape({
  name: Yup.string().matches(nameRegex, invalidFormat).required(emptyInput),
  surname: Yup.string().matches(nameRegex, invalidFormat).required(emptyInput),
  gender: Yup.boolean().required(emptyInput),
  height: Yup.number().positive("La estatura debe ser un valor positivo").required(emptyInput).typeError(invalidFormat).min(50, "La estatura debe ser al menos 50 cm").max(230, "La estatura debe ser menor a 230 cm"),
  weight: Yup.number().positive(invalidFormat).required(emptyInput).typeError(invalidFormat).min(20, "El peso debe ser al menos 20 kg").max(270, "El peso debe ser menor a 270 kg"),
  email: Yup.string().email(invalidFormat).matches(emailRegex, "El correo no puede contener caracteres especiales como &.").required(emptyInput),
  phone: Yup.string().matches(/^\d{1,10}$/, "El teléfono debe tener entre 1 y 10 dígitos y solo contener números.").required(emptyInput),
  date: Yup.date().typeError("La fecha debe estar en formato mm/dd/yyyy").max(new Date(), "La fecha debe estar en formato mm/dd/yyyy").required(emptyInput),
  password: Yup.string().matches(passwordRegex, invalidPassword).required('El campo es obligatorio.'),
  repeatPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden').required('El campo es obligatorio.'),
});

const NewUserEmailFromCompanySchema = Yup.object().shape({
  name: Yup.string().matches(nameRegex, invalidFormat).required(emptyInput),
  email: Yup.string().email(invalidFormat).matches(emailRegex, "El correo no puede contener caracteres especiales como &.").required(emptyInput),
});

const LoginSchema = Yup.object().shape({
  email: Yup.string().matches(emailRegex, "El correo no puede contener caracteres especiales como &.").required(emptyInput),
  password: Yup.string().required(emptyInput),
});

const AddressSchema = Yup.object().shape({
  street: Yup.string().required(emptyInput),
  doorNumber: Yup.number().required(emptyInput).positive("Número de puerta debe ser positivo"),
  state: Yup.string().required(emptyInput),
});

const schemas = {
  ChangePasswordSchema,
  CreateCompanySchema,
  CreateNutricionistSchema,
  CreateUserSchema,
  LoginSchema,
  NewUserEmailFromCompanySchema,
  AddressSchema,
};

export default schemas;
