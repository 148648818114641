import React from 'react';
import {useSelector} from 'react-redux';
import {withRouter, RouteComponentProps} from 'react-router-dom';

import {Header} from '../common/Header';
import {UserProfile} from './UserProfile';
import {NutritionistProfile} from './NutritionistProfile';
import {BusinessProfile} from './BusinessProfile';
import roles from '../../utils/roles';
import {userRole} from '../../store/auth/selector';
import {RootState} from '../../store/config/types';

function Profile({history}: RouteComponentProps) {
  const role = useSelector((state: RootState) => userRole(state));
  const isFinalUser = role === roles.user;
  const isNutritionist = role === roles.nutricionist;

  return (
    <div className='profile'>
      <Header />
      {isFinalUser ? (
        <UserProfile />
      ) : isNutritionist ? (
        <NutritionistProfile />
      ) : (
        <BusinessProfile history={history}/>
      )}
    </div>
  );
}

export default withRouter(Profile);
