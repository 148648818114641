import React from "react";
import { User, Option } from "./Users";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { chagePatientId } from '../../../../store/analyses/actionCreators';
import ResultsReport from "../../../endUser/ResultsReport";

interface UserRowProps {
  user: User;
  options: Option[];
  isNutritionist: boolean;
}

const getPdfLink = (id) => {
  return <ResultsReport paciente={id} userType="nutricionista"/>;
}

function UserRow({ user, options, isNutritionist }: UserRowProps) {
  const userState = options[user.state];

  const history = useHistory();
  const dispatch = useDispatch();

  const redirectResult = (userId: string) => {
    dispatch(chagePatientId(userId));
    history.push('/patientresult');
  }
  if (userState?.selected) {
    return (
      <div key={user.id} className="user-row">
        <div className="left-chunk">
          <span>
            <b>{user.name}</b>
          </span>
          <span>{`${user.age} años`}</span>
          <span>{user.phone} - {user.email}</span>
        </div>
        <div className="right-chunk">
          <span
            className="state"
            style={{
              borderColor: userState.color,
              color: userState.color,
              backgroundColor: userState.background,
            }}
          >
            <span className="state-text">{userState.text}</span>
          </span>
          {/* <span className="actions">
            {user.state === 0 ? "Reenviar solicitud" : (user.state === 3 && isNutritionist) ? "Ver resultados" : ""}
          </span> */}
          <span className="actions" onClick={() => redirectResult(user.id)}>
            {isNutritionist && user.state === 3 &&
              "Ver resultados"
            }
          </span>
          <span className="actions" onClick={() => window.alert("descargar PDF")}>
            {isNutritionist && user.state === 3 && getPdfLink(user.id)
            }
          </span>
        </div>
      </div>
    );
  }

  return null;
}

export default UserRow;
