import { InstrumentsState } from "./types";
import {
  InstrumentsActionTypes,
  INSTRUMENTS_LOADING,
  ADD_INSTRUMENTS,
  INSTRUMENTS_FAILED,
  ADDING_INSTRUMENT,
  ADD_INSTRUMENT_URL,
  ADD_INSTRUMENT_FAILED,
} from "./actionTypes";

const initialState: InstrumentsState = {
  addingInstrument: false,
  instrumentUrl: null,
  addInstrumentErrorMessage: "",
  loading: false,
  instruments: null,
  errorMessage: "",
};

export function instrumentsReducer(state = initialState, action: InstrumentsActionTypes): InstrumentsState {
  switch (action.type) {
    case ADDING_INSTRUMENT:
      return {
        ...state,
        addingInstrument: true,
        instrumentUrl: null,
        addInstrumentErrorMessage: "",
      };

    case ADD_INSTRUMENT_URL:
      return {
        ...state,
        addingInstrument: false,
        instrumentUrl: action.url,
        addInstrumentErrorMessage: "",
      };

    case ADD_INSTRUMENT_FAILED:
      return {
        ...state,
        addingInstrument: false,
        instrumentUrl: null,
        addInstrumentErrorMessage: action.error,
      };

    case INSTRUMENTS_LOADING:
      return {
        ...state,
        loading: true,
        instruments: null,
        errorMessage: "",
      };

    case ADD_INSTRUMENTS:
      return {
        ...state,
        loading: false,
        instruments: action.instruments,
        errorMessage: "",
      };

    case INSTRUMENTS_FAILED:
      return {
        ...state,
        loading: false,
        instruments: null,
        errorMessage: action.error,
      };

    default:
      return state;
  }
}
