import { Dispatch } from "redux";
import { Cost } from "./types";
import { costsService } from "../../services/costs.service";
import {
  LOADING_COSTS,
  ADD_COSTS,
  COSTS_FAILED,
  CostsActionTypes,
  SELECT_COST,
} from "./actionTypes";

//#region Fetch costs

export const fetchCommissions = () => {
  return (dispatch: Dispatch<CostsActionTypes>) => {
    dispatch(loadingCosts());
    
    costsService.fetchCosts().then(
      (response) => {
        dispatch(addCosts(response!));
      },
      (error) => {
        costsFailed(error);
      }
    );
  };
};

export const selectPlan = (id: number) => {
  return (dispatch: Dispatch<CostsActionTypes>) => {
    dispatch(selectCost(id));
  };
};

const selectCost = (id: number): CostsActionTypes => ({
  type: SELECT_COST,
  selected: id,
});

const loadingCosts = (): CostsActionTypes => ({
  type: LOADING_COSTS,
});

const addCosts = (costs: Cost[]): CostsActionTypes => ({
  type: ADD_COSTS,
  costs: costs,
});

const costsFailed = (error: string): CostsActionTypes => ({
  type: COSTS_FAILED,
  error,
});

//#endregion
