import React, { useEffect } from "react";
import { useSelector } from 'react-redux';

import { UserDashboard } from "./UserDashboard";
import { NutritionistDashboard } from "./NutritionistDashboard";
import { CompanyDashboard } from "./CompanyDashboard";
import { Header } from "../common/Header";
import roles from '../../utils/roles';
import { userRole, userAccount } from '../../store/auth/selector';
import { RootState } from "../../store/config/types";
import { createMemoryHistory } from "history";
import LoadingAnimation from '../animations/LoadingAnimation/LoadingAnimation';

function Dashboard() {
  const role = useSelector((state: RootState) => userRole(state));
  const isFinalUser = role === roles.user;
  const isNutritionist = role === roles.nutricionist;
  const history = createMemoryHistory();

  const actualUser = useSelector((state: RootState) => userAccount(state));

  return actualUser !== null ? (
    <div className="dashboard">
      <Header />
      {isFinalUser ? <UserDashboard /> : isNutritionist ? <NutritionistDashboard /> : <CompanyDashboard />}
    </div>
  ) : (<div className="loading-full-screen">
    <LoadingAnimation />
  </div>);
}

export default Dashboard;
