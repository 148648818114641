import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Button, Theme, makeStyles, createStyles } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { FormTextField } from "../../common/forms/FormTextField";
import { AuthInfoPanel } from "../AuthInfoPanel";
import { RootState } from "../../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  confirmResetPassword,
  modifyPassword,
  } from "../../../store/auth/actionCreators";
import schemas from "../../../data/schemas";
import { FullWidthSnackbar } from "../../common/FullWidthSnackbar";
import { LoadingAnimation } from "../../animations/LoadingAnimation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      marginTop: "3em",
      [theme.breakpoints.down("xs")]: {
        marginTop: 0,
        marginBottom: "25px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
    },
    formFooter: {
      display: "flex",
      margin: "4em 0",
      justifyContent: "flex-end",
      [theme.breakpoints.down("xs")]: {
        margin: "1em 0",
      },
    },
    loginBtn: {
      margin: "auto 0",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    rightPartitionHeading: {
      marginTop: "4em",
      [theme.breakpoints.down("xs")]: {
        marginTop: "1em",
      },
    },
  })
);

interface Values {
  password: string;
  newPassword: string;
  repeatPassword: string;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<RootState, any, AppActions>
  ) => ({
    changePassword: (token: string) => dispatch(confirmResetPassword(token)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function ChangePassword({ auth, changePassword, history }: PropsType) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [token, setToken] = useState("");

  const redirectTo = (path: string): any => {
    history.push(path);
  };

  useEffect(() => {
    console.log("entro a cambio password");
    console.log(history.location);
    if (history.location?.search?.startsWith("?data=")) {
      console.log("entro a cambio password 2");
      const search = history.location.search;
      changePassword(search);
    }
    if (
      !auth.changingPassword &&
      (auth.changePasswordSuccess || auth.changePasswordErrorMessage !== "")
    ) {
      setOpen(true);
    }
  }, [auth.changingPassword]);

  return (
    <div className="auth-partition-container">
      <AuthInfoPanel />
      <div className="auth-partition">
        <div className="auth-partition-no-img">
          <div className="auth-header">
            <div className="auth-back" onClick={() => redirectTo('/')}>
              <ArrowBackIcon />
              <p>Volver</p>
            </div>
          </div>
          {auth.confirmingResetPassword ? (
            <LoadingAnimation />
          ) : (
            <h2 className={classes.rightPartitionHeading}>
              Tu contraseña ha sido modificada con éxito.<br />
              Recibirás una contraseña temporal en tu correo electrónico (si no recibes el correo, revisa tu bandeja de spam).<br />
            </h2>
          )}
        </div>
      </div>
      <FullWidthSnackbar
        open={open}
        setOpen={setOpen}
        text={
          auth.changePasswordErrorMessage === ""
            ? "La contraseña se reestableció correctamente."
            : "No se pudo reestablecer la contraseña."
        }
        success={auth.changePasswordErrorMessage === ""}
      />
    </div>
  );
}

export default connector(withRouter(ChangePassword));
