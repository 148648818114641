import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { AppActions } from "./ActionTypes";
import { analysesReducer } from "../analyses/reducer";
import { authReducer } from "../auth/reducer";
import { bankAccountsReducer } from "../bankAccounts/reducer";
import { businessesReducer } from "../businesses/reducer";
import { commissionsReducer } from "../commissions/reducer";
import { costsReducer } from "../costs/reducer";
import { instrumentsReducer } from "../instruments/reducer";
import { transactionsReducer } from "../transactions/reducer";
import { usersReducer } from "../users/reducer";
import { NutritionistReducer } from "../nutritionists/reducer";

const composeEnhancers =
  typeof window === "object" && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>));

export const rootReducer = combineReducers({
  analyses: analysesReducer,
  auth: authReducer,
  bankAccounts: bankAccountsReducer,
  businesses: businessesReducer,
  commissions: commissionsReducer,
  costs: costsReducer,
  instruments: instrumentsReducer,
  nutritionists: NutritionistReducer,
  transactions: transactionsReducer,
  users: usersReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, enhancer);
