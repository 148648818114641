import React, { useState } from "react";
import {
  TextField,
  Tooltip,
  InputAdornment,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ErrorIcon from "@material-ui/icons/Error";
import { FieldProps } from "formik";

const useFormTextStyles = makeStyles({
  root: {
    width: "100%",
  },
  inputRoot: {
    padding: "12px 18px",
  },
  input: {
    top: 12,
    left: 18,
  },
  focused: {
    fontSize: 16,
  },
});

function isIos() {
  return [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform);
}

type FormTextFieldProps = FieldProps & React.HTMLProps<HTMLInputElement>;

function FormTextField({ field, form, ...props }: FormTextFieldProps) {
  const [passwordType, setPasswordType] = useState<string>("password");

  const classes = useFormTextStyles();
  const hasError =
    (form.touched[field.name] && form.errors[field.name]) !== undefined;
  const isPassword = props.type === "password";
  const errorMesagge = form.errors[field.name];
  const isIosDevice = isIos();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <TextField
      helperText={hasError ? errorMesagge : null}
      margin="normal"
      variant="standard"
      className={props.className}
      error={hasError}
      classes={{ root: classes.root }}
      InputLabelProps={{
        classes: {
          formControl: classes.input,
          focused: classes.focused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
        },
        endAdornment:
          hasError && !isPassword ? (
            <Tooltip title="Error">
              <InputAdornment position="end">
                <ErrorIcon />
              </InputAdornment>
            </Tooltip>
          ) : isPassword ? (
            <InputAdornment position="end">
              {passwordType === "password" ? (
                <IconButton onClick={() => setPasswordType("text")}>
                  <VisibilityOffIcon color="primary" />
                </IconButton>
              ) : (
                <IconButton onClick={() => setPasswordType("password")}>
                  <VisibilityIcon color="primary" />
                </IconButton>
              )}
            </InputAdornment>
          ) : null,
        inputProps: {
          pattern: props.pattern,
          maxLength: props.maxLength,
          autoComplete: "off",
          
        }
      }}
      {...field}
      type={
        isPassword
          ? passwordType
          : props.type === "date" && !isFocused
          ? "text"
          : isIos()
          ? "text"
          : props.type
      }
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      label={props.placeholder}
      disabled={props.disabled}
      placeholder={isIosDevice && props.placeholder === "Fecha de nacimiento" ? "mm/dd/aaaa  (Mes/Día/Año)" : props.placeholder}
    />
  );
}

export default FormTextField;
