import React, { useState } from "react";
import MyData from "../common/MyData";
import PaymentDetails from "./PaymentDetails";

const options = ["Mis datos", "Datos de cobro"];

function NutritionistProfile() {
  const [selectedOption, setSelectedOption] = useState<number>(0);

  return (
    <div className="profile-dashboard">
      <div className="profile-navigation">
        <div className="options">
          {options.map((opt, index) => {
            return (
              <span
                onClick={() => setSelectedOption(index)}
                className={selectedOption === index ? "selected" : ""}
                key={index}
              >
                {opt}
              </span>
            );
          })}
        </div>
      </div>
      {selectedOption === 0 ? <MyData /> : <PaymentDetails />}
    </div>
  );
}

export default NutritionistProfile;
