import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  DialogContent,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import calendar from "../../../../assets/calendar.png";
import * as styleVars from "../../../../styles/_variables.scss";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px 0 0 0",
    },
    image: {
      height: 48,
      width: "auto",
      [theme.breakpoints.down("xs")]: {
        height: 36,
      },
    },
    titleText: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize: 24,
      lineHeight: "22px",
      color: styleVars.notBlack,
      [theme.breakpoints.down("xs")]: {
        fontSize: 16,
      },
    },
    step: {
      color: styleVars.grey,
      fontSize: 16,
      marginBottom: 4,
    },
    closeIcon: {
      boxShadow: "0 2px 24px 0 rgba(0, 0, 0, 0.15)",
      backgroundColor: styleVars.white,
    },
    content: {
      padding: "16px 32px",
    },
    calendar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    timesheet: {
      width: 200,
      margin: "0 auto",
    },
    option: {
      cursor: "pointer",
      userSelect: "none",
      fontSize: 16,
      color: styleVars.grey,
      padding: "8px 0",
      margin: "12px 0",
      textAlign: "center",
      border: `1px solid ${styleVars.grey}`,
      borderRadius: 6,
      WebkitTransition: "all 0.3s ease-in-out",
      transition: "all 0.3s ease-in-out",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    selectedOption: {
      color: styleVars.white,
      backgroundColor: styleVars.primary,
    },
    checkIcon: {
      marginLeft: 8,
      fontSize: 12,
    },
    actions: {
      padding: "16px 0",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",

    },
    button: {
      fontSize: 18,
    },
  })
);

interface DateDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSelectDate: (day: Date, timeId: number) => void;
}

interface Values {
  street: string;
  doorNumber: string;
  apartmentNumber: string;
  corner: string;
}

const timeOptions = ["8am - 2pm", "2pm - 10pm"];

function DateDialog({ open, setOpen, onSelectDate }: DateDialogProps) {
  const classes = useStyles();

  const [step, setStep] = useState<number>(0);
  const [selectedTime, setSelectedTime] = useState<number>(99);
  const [selectedDay, setSelectedDay] = useState();

  function handleClick(day) {
    setSelectedDay(day);
  }

  function selectDatetime() {
    onSelectDate(selectedDay, selectedTime);
    setOpen(false);
  }

  function disableDays(day) {
    const momentDay = moment(day);
    return (
      momentDay.isBefore(moment().add(48, "hours")) ||
      momentDay.day() === 0 ||
      momentDay.day() === 6
    );
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>
        <div className={classes.title}>
          <img
            src={step === 0 ? calendar : calendar}
            alt="icon"
            className={classes.image}
          />
          <div className={classes.titleText}>
            <span className={classes.step}>{`Paso ${step + 1}`}</span>
            <span>{`Seleccionar ${step === 0 ? "día" : "horario"}`}</span>
          </div>
          <IconButton
            className={classes.closeIcon}
            onClick={() => setOpen(false)}
          >
            <CloseIcon color="primary" fontSize="default" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {step === 0 ? (
          <div className={classes.calendar}>
            <DayPicker
              showOutsideDays
              selectedDays={selectedDay}
              onDayClick={handleClick}
              disabledDays={disableDays}
            />
          </div>
        ) : (
          <div className={classes.timesheet}>
            {timeOptions.map((t, index) => {
              const isSelected = selectedTime === index;
              return (
                <div
                  className={`${classes.option} ${
                    isSelected ? classes.selectedOption : null
                  }`}
                  onClick={() => setSelectedTime(index)}
                >
                  {t}
                  {isSelected ? (
                    <CheckIcon className={classes.checkIcon} />
                  ) : null}
                </div>
              );
            })}
          </div>
        )}
        <div className={classes.actions}>
          {step === 0 ? null : (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setStep(0)}
            >
              Volver
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (step === 0) {
                setStep(1);
              } else {
                selectDatetime();
                setStep(0);
                setSelectedTime(99);
                setSelectedDay(undefined);
              }
            }}
          >
            {step === 0 ? "Siguiente" : "Finalizar"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DateDialog;
