import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAccount } from "../../store/auth/selector";
import { RootState } from "../../store/config/types";
import { Header } from "../common/Header";
import { Card, CardActionArea, CardContent } from "@material-ui/core";
import agenda from "../../assets/agenda.png";
import probes from "../../assets/probe-tubes.png";
import search from "../../assets/search-pizza.png";
import meal from "../../assets/step4.png";
import CommonContainer from "./common/CommonContainer";
import ResultsReport from "./ResultsReport";


function Landing({ history }) {
  const dispatch = useDispatch();
  const actualUser = useSelector((state: RootState) => userAccount(state));
  const name = actualUser && actualUser.profile.firstName + " " + actualUser.profile.lastName;

  return (
    <CommonContainer>
      {/* <ResultsReport paciente={name}/> */}
      <div className="landing-container">
        <MenuCard
          icon={probes}
          title="Resultados"
          onClick={() => history.push("/results")}
          description="Conocé la forma en que tu cuerpo asimila los distintos nutrientes."
        />
        <MenuCard
          icon={search}
          title="Recomendaciones"
          onClick={() => history.push("/foods")}
          description="Descubrí sobre una lista de casi 300 alimentos cuales debes preferir, moderar y evitar su consumo."
        />
        
        <MenuCard
          icon={meal}
          title="Platos semanales"
          onClick={() => history.push("/platos-semanales")}
          description="Contratá platos ricos, saludables y personalizados semanalmente directo a tu puerta."
        />
      </div>
    </CommonContainer>
  );
}

function MenuCard({ icon, title, description, onClick }) {
  return (
    <Card className="card" elevation={3}>
      <CardActionArea onClick={onClick}>
        <CardContent className="card-content">
          <div className="img-container">
            <img src={icon} alt="" />
          </div>
          <div className="text">
            <h3 className="title">{title}</h3>
            <p>{description}</p>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default Landing;
/* <MenuCard
          icon={agenda}
          title="Agenda"
          onClick={() => history.push("/agenda")}
          description="Agendá consultas con nutricionistas especialistas en interpretar los resultados nutrigenéticos."
        /> */