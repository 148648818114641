import React from "react";
import { Field } from "formik";
import { FormCheckbox } from "../forms/FormCheckbox";

function PathologiesFields() {
  return (
    <div className="checkboxes">
      <p className="title">Marca si padeces alguna de estas patologías</p>
      <Field name="pathologies" value="24ad9605-e114-4780-9494-04aaa04ced0b" component={FormCheckbox} label="Hipertensión" />
      <Field name="pathologies" value="66e8b8e3-369d-41ef-93e2-8adc9bd4932f" component={FormCheckbox} label="Diabetes" />
      <Field name="pathologies" value="f8f64b21-d063-479d-abeb-b329d846a878" component={FormCheckbox} label="Colesterol alto" />
      <Field name="pathologies" value="7956e830-5756-4958-8f96-053b96ecb2f0" component={FormCheckbox} label="Nivel triglicéridos" />
      <Field name="pathologies" value="c5a73e8e-93a9-4cb6-bae0-6475cddcfde8" component={FormCheckbox} label="Alergias" />
      <Field name="pathologies" value="186814c4-51bf-45ea-aebb-d87cc10d1f3b" component={FormCheckbox} label="Intolerante a la lactosa" />
      <Field name="pathologies" value="bda9eb72-8fb0-43d1-b15f-fe445aadf113" component={FormCheckbox} label="Intolerante al gluten " />
      <Field name="pathologies" value="b2c72519-198a-4bb3-8b2d-4af4ff253a76" component={FormCheckbox} label="Nivel Normal de glucosa" />
      <Field name="pathologies" value="fe4f63a6-6b93-4a51-8312-abcd590ad746" component={FormCheckbox} label="Fumador" />
      <Field name="pathologies" value="c57ec43e-efae-4588-87d1-c013460c857e" component={FormCheckbox} label="Sedentarismo" />
      <Field name="pathologies" value="329ae769-5af6-4256-8fd3-b9a4be83d863" component={FormCheckbox} label="Toma bebidas alcohólicas" />
    </div>
  );
}

/*
Id                                  |Name                          |
------------------------------------|------------------------------|
c57ec43e-efae-4588-87d1-c013460c857e|Ejercicio                     |
c5a73e8e-93a9-4cb6-bae0-6475cddcfde8|Alergias                      |*
186814c4-51bf-45ea-aebb-d87cc10d1f3b|Intolerante a la Lactosa      |*
bda9eb72-8fb0-43d1-b15f-fe445aadf113|Intolerante al Gluten         |*
24ad9605-e114-4780-9494-04aaa04ced0b|Hipertensión                  |*
f8f64b21-d063-479d-abeb-b329d846a878|Nivel Normal de Colesterol    |*
b2c72519-198a-4bb3-8b2d-4af4ff253a76|Nivel Normal de Glucosa       |*
66e8b8e3-369d-41ef-93e2-8adc9bd4932f|Diabetes                      |*
fe4f63a6-6b93-4a51-8312-abcd590ad746|Cigarrillos                   |*
7956e830-5756-4958-8f96-053b96ecb2f0|Nivel Normal de Triglicéridos |*
329ae769-5af6-4256-8fd3-b9a4be83d863|Bebidas alcohólicas por semana|
*/

export default PathologiesFields;
