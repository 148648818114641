import React, { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import * as styleVars from "../../../../styles/_variables.scss";
import { Search } from "../../../common/Search";
import UserRow from "./UserRow";
import UserFilters from "./UserFilters";
import { businessService } from "../../../../services/businesses.service";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/config/types";
/* import landing from "../../../../assets/landing-cat-mosaic-horizontal.png"; */

export interface Option {
  id: number;
  text: string;
  color: string;
  background: string;
  selected: boolean;
}

export interface User {
  id: string;
  name: string;
  age: number;
  state: number;
  phone?: string;
  email?: string;
}

// const options: Option[] = [
//   {
//     id: 0,
//     text: "Esperando por registro",
//     color: styleVars.filterRed,
//     background: styleVars.filterLightRed,
//     selected: true,
//   },
//   {
//     id: 1,
//     text: "Esperando por muestra",
//     color: styleVars.filterOrange,
//     background: styleVars.filterLightOrange,
//     selected: true,
//   },
//   {
//     id: 2,
//     text: "Realizando test",
//     color: styleVars.filterYellow,
//     background: styleVars.filterLightYellow,
//     selected: true,
//   },
//   {
//     id: 3,
//     text: "Resultados disponibles",
//     color: styleVars.filterGreen,
//     background: styleVars.filterLightGreen,
//     selected: true,
//   },
// ];

// {
//   WaitingKit = 0,         //Análisis creado sin entrega sin retiro
//   WaitingTestKit = 1,     //Está esperando la entrega en MVD
//   WaitingResult = 2,      //Este estado se tiene que poner cuando entregó el análisis, lo hace el administrador
//   WaitingSendingKit = 3,  //Esperando a que entregue el kit (No es de mvd)
//   WaitingCollect = 4,     //Esperando que se retire el test en domicilio MVD
//   WaitingSendingCollect = 5,  //Esperando que se retire en un domicilio que no es MVD
//   WaitingGetKitOffice = 6,    //Esperando que retire el kit de las oficinas de Mrgen
//   WaitingSetKitOffice = 7     //Esperando que entregue el kit en oficinas de mrGen
// }

const options: Option[] = [
  {
    id: 0,
    text: "Esperando kit",
    color: styleVars.filterRed,
    background: styleVars.filterLightRed,
    selected: true,
  },
  {
    id: 1,
    text: "Realizando test",
    color: styleVars.filterOrange,
    background: styleVars.filterLightYellow,
    selected: true,
  },
  {
    id: 2,
    text: "Esperando resultado",
    color: styleVars.filterYellow,
    background: styleVars.filterLightOrange,

    selected: true,
  },
  {
    id: 3,
    text: "Resultado enviado",
    color: styleVars.filterGreen,
    background: styleVars.filterLightGreen,
    selected: true,
  },
];

interface UsersProps {
  isNutritionist: boolean;
}

function Users({ isNutritionist }: UsersProps) {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(options);
  const [searchTerms, setSearchTerms] = useState<string>("");
  const [users, setUsers] = useState<User[]>([]);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const nutritionist = useSelector((state: RootState) => state.nutritionists);
  const business = useSelector((state: RootState) => state.businesses);

  const calculateAge = (birthday) => {
    // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const parseStatus = (status: number): number => {
    if (status == 7 || status == 4 || status == 5) {
      return 1;
    }
    if (status == 2) {
      return 2;
    }
    if (status == 8) {
      return 3;
    }
    return 0;
  };
  const [available, setkitsavailables] = useState<number>(0);

  useEffect(() => {
    if (isNutritionist) {
      let newUser = [];
      console.log(nutritionist?.refererAccounts);
      nutritionist?.refererAccounts?.map(
        (i) =>
          i.profile.analysis !== null &&
          newUser.push({
            id: i.id,
            name: i.profile.firstName + " " + i.profile.lastName,
            age: calculateAge(new Date(i.profile.birthDate)),
            state: parseStatus(i.profile.analysis?.status),
            phone: i.profile.mobile,
            email: i.email,
          })
      );
      setUsers(newUser);
    } else {
      getkits();
      let newUser = [];
      business?.employesAccounts?.map(
        (i) =>
          i.profile.analysis !== null &&
          newUser.push({
            id: i.id,
            name: i.profile.firstName,
            age: calculateAge(new Date(i.profile.birthDate)),
            state: parseStatus(i.profile.analysis?.status),
          })
      );
      if (newUser.length === 0) setIsEmpty(true);
      else setIsEmpty(false);
      setUsers(newUser);
    }
  }, [nutritionist, business, isNutritionist]);

  async function getkits() {
    console.log('getKitsCalled')
    setkitsavailables(await businessService.kitsavailables());
  }

  const toggleOption = (optionId: number) => {
    let newOptions: Option[] = [];

    selectedOptions.map((opt) => {
      if (opt.id === optionId) {
        let selected = opt.selected;
        opt.selected = !selected;
      }

      newOptions.push(opt);

      return opt;
    });

    setSelectedOptions(newOptions);
  };

  const search = (value: string) => {
    console.log(value);
  };

  return (
    <>
      <Paper
        elevation={1}
        classes={{
          root: `dashboard-content ${
            isNutritionist ? "special-border-radius" : ""
          }`,
        }}
      >
        {isEmpty ? (
          <div className="no-users" style={{ padding: '0px !important' }}>
            {isNutritionist ? null : (
              <div
                className="company-heading no-pad"
                style={{ width: "inherit", padding: '0px !important'}}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: 32,
                    }}
                  >
                    <span>Empleados</span>
                    <div className="right">
                      <span className="amount-left-speech">
                        Cupos restantes:{" "}
                      </span>
                      <span>{available != null ? available : 0}</span>
                    </div>
                  </div>

                  <div
                    className="speech"
                    style={{ fontWeight: 300, width: 300, margin: 32 }}
                  >{`Todavía no tenés ningún ${
                    isNutritionist ? "paciente" : "empleado"
                  }, podes cargar uno nuevo aquí.`}</div>
                </div>
                <img
                  src="https://res.cloudinary.com/hqs-cdn/image/upload/v1634906803/mistergen/landing-cat-mosaic-horizontal_m9r9av.png"
                  style={{ height: 315, width: "50%", borderRadius: 10 }}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            {isNutritionist ? null : (
              <div
                className="company-heading"
                style={{ width: "inherit", padding: 0 }}
              >
                <span>Empleados</span>
                <div className="right">
                  <span className="amount-left-speech">Cupos restantes: </span>
                  <span>{available != null ? available : 0}</span>
                </div>
              </div>
            )}
            <UserFilters
              selectedOptions={selectedOptions}
              toggleOption={toggleOption}
            />
          </>
        )}
      </Paper>
      <Paper elevation={1} classes={{ root: "users-list" }}>
        {/* <div className="search-bar">
          <Search value={searchTerms} setValue={setSearchTerms} searchFunction={search} />
        </div> */}
        {!isEmpty && (
          <div className="list">
            {users.map((user) => {
              return (
                <UserRow
                  key={user.id}
                  user={user}
                  options={selectedOptions}
                  isNutritionist={isNutritionist}
                />
              );
            })}
          </div>
        )}
      </Paper>
    </>
  );
}

export default Users;
