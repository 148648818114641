import React, {useState} from "react";
import { Formik, FormikHelpers, Form, Field } from "formik";
import { FormTextField } from "../../common/forms/FormTextField";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { UserDataFields } from "../../common/UserDataFields";
import schemas from "../../../data/schemas";
import { authService } from '../../../services/auth.service';
import { Link } from 'react-router-dom';


interface Values {
  name: string;
  surname: string;
  gender: number;
  height: string;
  weight: string;
  phone: string;
  ethnicity: number;
  date: string;
  email: string;
  password: string;
  repeatPassword: string;
}

interface UserDataProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  data: Values;
  setData: React.Dispatch<React.SetStateAction<Values>>;
  setToPay: React.Dispatch<React.SetStateAction<boolean>>;
}

function UserData({ activeStep, setActiveStep, data, setData, setToPay }: UserDataProps) {
  const [checked, setChecked] = useState<boolean>(false);
  
  const submit = (values: Values, actions: FormikHelpers<Values>) => {
    authService.verifyEmail(values.email).then(
      (response) => {
        setData(values);
        setToPay(true);
        //setActiveStep(activeStep + 1);
      },
      (error) => {
        actions.setFieldError('email', 'El correo electrónico no esta disponible');
      }
    );
  };

  return (
    
    <Formik
      initialValues={data}
      onSubmit={submit}
      validationSchema={schemas.CreateUserSchema}
    >
      <Form className="form">
        <>
          
          <UserDataFields speech="Completá tus datos para adquirir tu Kit Mr. GEN NutriTest" />
          <div className="your-passwords">
            <p className="title">Contraseña para ingresar a tu perfil</p>
            <div className="horizontal-line">
              <Field
                className="field"
                name="password"
                component={FormTextField}
                type="password"
                placeholder="Contraseña"
              />
              <Field
                className="field"
                name="repeatPassword"
                component={FormTextField}
                type="password"
                placeholder="Repetir contraseña"
              />
            </div>
          </div>
          <div className="terms-and-conditions">
            <div className="separator"></div>
            <div className="share-info" style={{paddingTop:'50px'}}>
              <FormControlLabel
              label={
                <span>
                  Estoy de acuerdo con los siguientes{' '}
                  <Link to="/terminos-condiciones-uso-uy" target="_blank" rel="noopener noreferrer">
                    términos y condiciones
                  </Link>
                  .
                </span>
              }
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />
              }
            />
            </div>
          </div>
          <div className="buttons" style={{marginTop:'30px'}}>
            <Button
              disabled={!checked}
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
            >
              Continuar con la compra
            </Button>
          </div>
        </>
      </Form>
    </Formik>
  );
}

export default UserData;
