import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import user from "../../../../assets/user.png";
import CloseIcon from "@material-ui/icons/Close";
import * as styleVars from "../../../../styles/_variables.scss";
import { FormTextField } from "../../../common/forms/FormTextField";
import { Field, Form, Formik, FormikHelpers } from "formik";
import schemas from "../../../../data/schemas";

import { RootState } from "../../../../store/config/types";
import { useSelector } from "react-redux";
import { userAccount } from "../../../../store/auth/selector";
import { authService } from "../../../../services/auth.service";
import { FullWidthSnackbar } from "../../../common/FullWidthSnackbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px 0 0 0",
    },
    image: {
      height: 48,
      width: "auto",
      [theme.breakpoints.down("xs")]: {
        height: 36,
      },
    },
    titleText: {
      fontSize: 24,
      [theme.breakpoints.down("xs")]: {
        fontSize: 20,
      },
    },
    closeIcon: {
      boxShadow: "0 2px 24px 0 rgba(0, 0, 0, 0.15)",
      backgroundColor: styleVars.white,
    },
    content: {
      padding: "0 64px",
      [theme.breakpoints.down("xs")]: {
        padding: "0 32px",
      },
    },
    actions: {
      padding: "32px 0",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    button: {
      fontSize: 18,
    },
    container: {
      padding: 16,
      margin: "32px 0 16px 0",
      boxSizing: "border-box",
      textAlign: "center",
    },
  })
);

interface UserDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  isNutritionist: boolean;
}

interface Values {
  name: string;
  email: string;
}

function UserDialog({ open, setOpen, isNutritionist }: UserDialogProps) {
  const classes = useStyles();

  const actualUser = useSelector((state: RootState) => userAccount(state));
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleDialogClose = () => {
    setOpen(false);
  };

  const submit = (values: Values, actions: FormikHelpers<Values>) => {
    let success = true;
    authService.verifyEmail(values.email).then(
      (response) => {
        if (isNutritionist) {
          const request = {
            accountId: actualUser.id,
            newAccountInfo: {
              role: "Customer",
              profile: null,
              email: values.email,
              business: null,
              referer: actualUser.nutritionist,
              nutritionist: null,
            },
          };

          authService.createUserFromNutricionist(request).then(
            (response) => {},
            (error) => {
              success = false;
              alert("ROMPIÓ");
            }
          );
        } else {
          const request = {
            accountId: actualUser.id,
            newAccountInfo: {
              role: "Customer",
              profile: null,
              email: values.email,
              business: actualUser.business,
              referer: null,
              nutritionist: null,
            },
          };

          authService.createUserFromBusiness(request).then(
            (response) => {},
            (error) => {
              success = false;
              alert("ROMPIÓ");
            }
          );
        }

        if (success) {
          setSnackbarOpen(true);
          setTimeout(() => {
            setSnackbarOpen(false);
            handleDialogClose();
          }, 4000);
        }
      },
      (error) => {
        actions.setFieldError(
          "email",
          "El correo electrónico no está disponible"
        );
      }
    );
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="xs">
      <DialogTitle>
        <div className={classes.title}>
          <img src={user} alt="user" className={classes.image} />
          <span className={classes.titleText}>{`Cargar ${
            isNutritionist ? "paciente" : "empleado"
          }`}</span>
          <IconButton className={classes.closeIcon} onClick={handleDialogClose}>
            <CloseIcon color="primary" fontSize="default" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Formik
          initialValues={{ name: "", email: "" }}
          onSubmit={submit}
          validationSchema={schemas.NewUserEmailFromCompanySchema}
        >
          <Form>
            <Field
              name="name"
              component={FormTextField}
              type="text"
              placeholder="Nombre"
            />
            <Field
              name="email"
              component={FormTextField}
              type="text"
              placeholder="Email"
            />
            <div className={classes.actions}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disableElevation
                className={classes.button}
              >
                Enviar
              </Button>
              <div className={`grey-container ${classes.container}`}>
                Al presionar enviar le llegará al &nbsp;
                {isNutritionist ? "paciente" : "empleado"} un link para
                continuar con su registro.
              </div>
              <div className={`yellow-container ${classes.container}`}>
                Recuerde que si envía más invitaciones que cupos disponibles,
                solo podrán acceder los usuarios que lleguen en primer lugar a registrarse exitosamente.
              </div>
            </div>
          </Form>
        </Formik>
      </DialogContent>
      <FullWidthSnackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        text={
          "Se envió invitación correctamente, será desplegado en la lista cuando el invitado acepte la invitación y registre su cuenta"
        }
        success
      />
    </Dialog>
  );
}

export default UserDialog;
