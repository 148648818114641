import { CostsState } from "./types";
import { CostsActionTypes, ADD_COSTS, LOADING_COSTS, COSTS_FAILED, SELECT_COST } from "./actionTypes";

const initialState: CostsState = {
  isLoading: false,
  costs: null,
  errorMessage: "",
  selected: 0,
};

export function costsReducer(state = initialState, action: CostsActionTypes): CostsState {
  switch (action.type) {
    case ADD_COSTS:
      return {
        ...state,
        isLoading: false,
        costs: action.costs,
        errorMessage: "",
        selected: 0,
      };

    case LOADING_COSTS:
      return {
        ...state,
        isLoading: true,
        costs: null,
        errorMessage: "",
        selected: state.selected,
      };

    case COSTS_FAILED:
      return {
        ...state,
        isLoading: false,
        costs: null,
        errorMessage: action.error,
        selected: state.selected,
      };

    case SELECT_COST:
      return {
        ...state,
        isLoading: false,
        costs: state.costs,
        errorMessage: "",
        selected: action.selected,
      }

    default:
      return state;
  }
}
