// import { Tab, Tabs } from "@material-ui/core";
import { ButtonBase, Paper } from "@material-ui/core";
import {
  Assignment,
  BubbleChart,
  MeetingRoom,
  MenuBook,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import search from "../../assets/search-pizza.png";
import ResultCard from "./common/ResultCard";
import Diagnostics from "./Diagnostics";
import Metabolisms from "./Metabolisms";
import CommonContainer from "./common/CommonContainer";
import { createNode } from "typescript";
import ResultsReport from "./ResultsReport";
import { useDispatch, useSelector } from "react-redux";
import { number } from "yup";
import { fetchAnalysisFoods, fetchAnalysisResults } from "../../store/analyses/actionCreators";
import { FoodsResponse, FoodsResult } from "../../store/analyses/types";
import { RootState } from "../../store/config/types";
import { userAccount } from "../../store/auth/selector";

function Results() {
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  const actualUser = useSelector((state: RootState) => userAccount(state));
  const name = actualUser && actualUser.profile.firstName + " " + actualUser.profile.lastName;

    const getPdf = async (url) => {
      try {
        let response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type':'application/json',
            'Authorization':'Bearer ' + localStorage.getItem('token')
          }
        });
        let data = await response.json();
        return data;
      } catch(error){
        console.log(error)
      }
    } 

    const getPdfLink = () => {

      /* getPdf('https://services.mistergen.com/v1/Analyses/getpdf/')
        .then(res => {
            console.log(res);
            if(document.querySelector('.results-pdf') !== null) {
              return;
            }
            if(!res.link){ 
              return
            }
            //console.log(res.link);
            let a = document.createElement('div');
            a.classList.add('results-pdf');
            a.innerHTML = `
                          <a 
                          target="_blank"
                          rel="noopener noreferrer"
                          href="${res.link}">
                            Ver informe de resultados en PDF
                          </a>
                          `;  
            document.querySelector('.dashboard').children[0].appendChild(a);
            //document.querySelector('.results-container').prepend(a);
                   
        }).catch( error => {
            //console.log(error);
            return '';
        }); */
        return <ResultsReport paciente={name}/>
      }

    
  useEffect(() => {
    if (
      localStorage.previouslyEntered === "false" ||
      !localStorage.previouslyEntered
    ) {
      setActiveTab(1);
    }
    return () => {
      localStorage.previouslyEntered = true;
    };
  }, []);
  return (
    <CommonContainer>
      {getPdfLink()}
      <div className="landing-container results-container">
        <div className="tabs">
          <Tab
            setSelected={setActiveTab}
            label="Introducción al estudio"
            icon={<MeetingRoom fontSize="large" />}
            id="1"
            selected={activeTab == 1}
          />
          <Tab
            setSelected={setActiveTab}
            label="Resumen De Diagnósticos"
            icon={<Assignment fontSize="large" />}
            id="2"
            selected={activeTab == 2}
          />
          {/* <Tab
            setSelected={setActiveTab}
            label="Intolerancias, metabolismos, asimilaciones"
            icon={<BubbleChart fontSize="large" />}
            id="3"
            selected={activeTab == 3}
          /> */}
          <Tab
            setSelected={setActiveTab}
            label="Glosario"
            icon={<MenuBook fontSize="large" />}
            id="4"
            selected={activeTab == 4}
          />
        </div>
        <div className="tab-content" style={{paddingBottom:'20px'}}>
          {activeTab == 0 ? (
            <WelcomeCard
              title="Bienvenido a la herramienta de Consulta de comidas"
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          ) : null}
          {activeTab == 1 ? (
            <WelcomeCard
              title="Introducción al Estudio"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ) : null}
          {activeTab == 2 ? (
            <ResultCard
              title={"Resumen de Diagnósticos"}
              content={<Diagnostics patientId={""} />}
            />
          ) : null}
          {activeTab == 3 ? (
            <ResultCard
              title={"Resumen de diagnósticos"}
              content={<Metabolisms patientId={""} />}
            />
          ) : null}
          {activeTab == 4 ? (
            <ResultCard
              title={"Glosario de Términos"}
              content={
                <div className="top-border">
                  <p>
                    <b>ADN:</b> Siglas del ácido desoxirribonucleico responsable
                    de contener toda la información genética usadas en el
                    desarrollo y funcionamiento de todos los organismos vivos y
                    algunos virus, información que es única e irrepetible en
                    cada ser y es responsable de su transmisión hereditaria.
                    Alelo: Un alelo es cada una de las dos o más versiones de un
                    gen. Un individuo hereda dos alelos para cada gen, uno del
                    padre y el otro de la madre. Fenotipo: Conjunto de
                    caracteres visibles que un individuo presenta como resultado
                    de la interacción entre su genotipo y el medio. Gen:
                    Fragmente de ADN que funciona como unidad básica de
                    transmisión de las características hereditarias.
                  </p>

                  <p>
                    <b>Genotipo:</b> Conjunto de los genes que existen en el
                    núcleo celular de cada individuo.
                  </p>

                  <p>
                    <b>Intolerancia:</b> Incapacidad de un organismo para
                    resistir y aceptar el aporte de determinadas sustancias, en
                    especial alimentos o medicamentos. Metabolismo: Se puede
                    definir el metabolismo corporal o el metabolismo energético
                    como el conjunto de cambios físicos y químicos que se
                    producen dentro del cuerpo humano para la obtención de
                    energía a través de alguna fuente como son los alimentos.
                  </p>

                  <p>
                    <b>Polimorfismo:</b> Es una variación en la secuencia de un
                    lugar determinado del ADN entre los individuos de una
                    población dicha variación debe aparecer en al menos el 1% de
                    la población.
                  </p>

                  <p>
                    <b>Riesgo:</b> El riesgo genético, se define como la
                    presencia de determinados cambios o variaciones en la
                    secuencia de ADN y/o la combinación de una serie de ellas en
                    un individuo, que no son necesariamente anormales, pero que
                    asociados pueden incrementar la susceptibilidad de
                    desarrollar una determinada condición o enfermedad.
                  </p>

                  <p>
                    En otras palabras, una persona ha heredado de sus padres una
                    copia de un gen "de riesgo" que aunque no es un agente
                    causal por sí mismo, sí hace más susceptible a la persona a
                    desarrollar una condición particular. Si las condiciones
                    ambientales son adecuadas y se produce la interacción con el
                    gen "de riesgo" la condición se producirá.
                  </p>
                </div>
              }
            />
          ) : null}
        </div>
      </div>
    </CommonContainer>
  );
}

function WelcomeCard({ setActiveTab, title, activeTab }) {
  return (
    <Paper className="result-card welcome">
      <div className="info">
        <h2>{title}</h2>
        <p>
          El resultado de su test nutrigenético le aportará información detallada sobre
          aquellos alimentos que su organismo asimila/ tolera mejor o peor
          indicando si usted debe preferir, moderar o evitar su consumo.
        </p>
        <p>
          Es importante tener en cuenta que el componente genético puede influir
          hasta en un 40% sobre la pérdida de peso.
        </p>
      </div>
      <div className="images">
        {/* <button
          className="button-link"
          onClick={() => {
            setActiveTab(activeTab + 1);
            localStorage.previouslyEntered = false;
          }}
        >
          Omitir y ver luego
        </button> */}
        <div className="icon">
          <img src={search} />
        </div>
      </div>
    </Paper>
  );
}

function Tab({ label, icon, id, selected, setSelected }) {
  return (
    <Paper className="tab-button" elevation={selected ? 2 : 0}>
      <ButtonBase
        onClick={() => setSelected(id)}
        className={`${selected ? "selected" : ""}`}
      >
        <span className="label">{label}</span>
        {icon}
      </ButtonBase>
    </Paper>
  );
}

export default Results;
