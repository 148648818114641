const BASE_URL = "https://services.mistergen.com";
//const BASE_URL = "https://developers.services.mistergen.com";

//const BASE_URL_FOODS = "http://192.168.1.11:3000";
const BASE_URL_FOODS = "https://services.balance.com.uy";

// const BASE_URL = "http://35.169.8.183:9090";
// const BASE_URL = "https://localhost:44358";
// const BASE_URL = "http://localhost:9999";

const REDIRECT_URL = "https://mistergen.com/";
//const REDIRECT_URL = "http://localhost:3000/";
//const REDIRECT_URL = "http://localhost:3001/";

export { BASE_URL, REDIRECT_URL, BASE_URL_FOODS };