import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { UserSignup } from "./UserSignup";
import { NutritionistSignup } from "./NutritionistSignup";
import { CompanySignup } from "./CompanySignup";
import { UserType } from "../../data/enums";

function parseQueryString(queryString?: string): any {
  if (!queryString) {
    queryString = window.location.search.substring(1);
  }

  const params: any = {};

  const queries = queryString.split("&");

  queries.forEach((indexQuery: string) => {
    const indexPair = indexQuery.split("=");

    const queryKey = decodeURIComponent(indexPair[0]);
    const queryValue = decodeURIComponent(indexPair.length > 1 ? indexPair[1] : "");

    params[queryKey] = queryValue;
  });

  return params;
}

function Signup({ history }: RouteComponentProps) {
  const [userType, setUserType] = useState<number>(0);

  useEffect(() => {
    if (userType === 0) {
      let search = window.location.search;
      let vars = parseQueryString(search.substring(1, search.length));

      setUserType(Number(vars.user));
    }
  }, [userType, setUserType]);

  const isFinalUser = userType === UserType.EndUser;
  const isNutritionist = userType === UserType.Nutritionist;
  const isCompany = userType === UserType.Company;

  if (isFinalUser) {
    return <UserSignup />;
  } else if (isNutritionist) {
    return <NutritionistSignup />;
  } else if (isCompany) {
    return <CompanySignup />
  }
  else
    return <div></div>;
}

export default withRouter(Signup);
