import { Cost } from "./types";

export const ADD_COSTS = "ADD_COSTS";
export const LOADING_COSTS = "LOADING_COSTS";
export const COSTS_FAILED = "COSTS_FAILED";
export const SELECT_COST = "SELECT_COST";

export interface AddCostsAction {
  type: typeof ADD_COSTS;
  costs: Cost[];
}

export interface LoadingCostsAction {
  type: typeof LOADING_COSTS;
}

export interface CostsFailedAction {
  type: typeof COSTS_FAILED;
  error: string;
}

export interface SelectCostAction {
  type: typeof SELECT_COST;
  selected: number;
}

export type CostsActionTypes = AddCostsAction | LoadingCostsAction | CostsFailedAction | SelectCostAction;
