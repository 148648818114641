import React, { useState, useEffect } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { businessService } from '../../../../services/businesses.service';
import { FullWidthSnackbar } from '../../../common/FullWidthSnackbar';

interface NewUserButtonProps extends RouteComponentProps {
  isNutritionist: boolean;
  setOpen: (open: boolean) => void;
}

function NewUserButton({ isNutritionist, setOpen, history }: NewUserButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [available, setkitsavailables] = useState<number>(0);
  const [snackBar, setSnackBar] = useState<boolean>(false);

  async function getkits() {
    setkitsavailables(await businessService.kitsavailables());
  }

  const selectOption = (option: number) => {
    if (option === 0) {
      setOpen(true);
    } else {
      history.push('/createUser');
    }

    setAnchorEl(null);
  };

  useEffect(() => {
    if (!isNutritionist) {
      getkits();
    }
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isNutritionist) {
      setAnchorEl(event.currentTarget);
    } else {
      if (available !== null && available > 0) {
        setAnchorEl(event.currentTarget);
      } else {
        setSnackBar(true);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        disableElevation
        color='primary'
        variant='contained'
        classes={{ root: 'new-button' }}
      >
        {`Cargar nuevo ${isNutritionist ? 'paciente' : 'empleado'}`}
      </Button>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => selectOption(0)}>Enviar solo email</MenuItem>
        <MenuItem onClick={() => selectOption(1)}>
          Cargar todos los datos
        </MenuItem>
      </Menu>
      <FullWidthSnackbar
        open={snackBar}
        setOpen={setSnackBar}
        text={'Debes tener kits comprados'}
        success={false}
      />
    </>
  );
}

export default withRouter(NewUserButton);
