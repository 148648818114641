import React from "react";
import { Lottie } from "@crello/react-lottie";
import errorAnimation from "../../../assets/error.json";

interface ErrorAnimationProps {
  message: string;
}

function ErrorAnimation({ message }: ErrorAnimationProps) {
  return (
    <div className="error-animation">
      <Lottie
        style={{ marginBottom: "8px" }}
        height="100px"
        width="100px"
        config={{ animationData: errorAnimation, loop: true }}
      />
      <p>{message}</p>
    </div>
  );
}

export default ErrorAnimation;
