import React, { useState } from "react";
import {
  Paper,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

interface Props {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setShippingType: React.Dispatch<React.SetStateAction<number>>;
  submitKitDistribution: () => void;
  name: string;
  isDelivery: boolean;
}

function ShippingSelection({
  activeStep,
  setActiveStep,
  setShippingType,
  name,
  submitKitDistribution,
  isDelivery,
}: Props) {
  const [option, setOption] = useState(0);

  function onContinue() {
    setShippingType(option);
    if (option === 0) {
      setActiveStep(activeStep + 1);
    } else {
      submitKitDistribution();
      setActiveStep(isDelivery ? 2 : 5);
    }
  }

  return (
    <div className="kit-pickup">
      <Paper elevation={2} classes={{ root: "user-dashboard-content" }}>
        <Paper elevation={0} classes={{ root: "content-heading" }}>
          <p className="title">Hola {name}</p>
          <p>
            {isDelivery 
              ? "A partir de ahora puedes coordinar la entrega del kit de toma de muestra de saliva en tu dirección." 
              : "Ya tienes la muestra de saliva, a partir de ahora puedes coordinar el retiro del sobre en tu domicilio."}

          </p>
        </Paper>
        <div className="shipping-selection">
          <FormControl component="fieldset">
            <RadioGroup
              value={option}
              onChange={(event) => {
                let value = Number(event.target.value);
                setOption(value);
                setShippingType(value);
              }}
            >
              <FormControlLabel
                value={0}
                control={<Radio color="primary" />}
                label={`${
                  isDelivery ? "Entrega" : "Retiro"
                } a domicilio en todo el Uruguay`}
              />
              
              {/* DESCOMENTAR ESTO PARA TENER LA OPCION DE RETIRO DESPACHO EN OFICINA */}
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label={`${
                  isDelivery ? "Retiro" : "Despacho"
                } en la oficina de Mr.Gen (En Paraguay 1690. ${isDelivery ? "Retirar sobre de Mr. GEN en recepción" : "Entregar sobre a nombre de Mr. GEN en recepción"}. Disponible de 10 a 17 horas.)
                `}
              />
              <div className="grey-container">
                <p>
                  
                  Por consultas comunicate con nosotros vía WhatsApp al 099 436 487
                  <br />
                  Lunes a Viernes de 10hs a 17hs
                  <br /><br />
                  Las entregas y retiros fuera de Montevideo son coordinadas por nuestro equipo de forma manual <br />y son enviados por empresas de mensajería o empresas de transporte.
                </p>
              </div>
            </RadioGroup>
          </FormControl>
        </div>
      </Paper>
      <div className="buttons">
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={onContinue}
        >
          Siguiente
        </Button>
      </div>
    </div>
  );
}

export default ShippingSelection;
/* Luis Lamas 3407 - Of. 103, Montevideo, CP 11300 <br /> */