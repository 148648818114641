import {Nutritionist} from './types';
import {User} from '../users/types';
import {Account} from '../auth/types';

export const GET_USER_FROM_CODE_SUCCESS = 'GET_USER_FROM_CODE_SUCCESS';
export const GET_USER_FROM_CODE = 'GET_USER_FROM_CODE';
export const GET_USER_FROM_CODE_FAILURE = 'GET_USER_FROM_CODE_FAILURE';

export interface GetUserFromCodeSuccessAction {
  type: typeof GET_USER_FROM_CODE_SUCCESS;
  userFromCode: Account;
}
export interface GetUserFromCodeAction {
  type: typeof GET_USER_FROM_CODE;
}
export interface GetUserFromCodeFailureAction {
  type: typeof GET_USER_FROM_CODE_FAILURE;
  error: string;
}

export const NUTRITIONIST_LOAD = 'NUTRITIONIST_LOAD';
export const NUTRITIONIST_LOADING = 'NUTRITIONIST_LOADING';
export const NUTRITIONIST_LOAD_FAILED = 'NUTRITIONIST_LOAD_FAILED';

export interface NutritionistLoadAction {
  type: typeof NUTRITIONIST_LOAD;
  nutritionist: Nutritionist;
}
export interface NutritionistLoadingAction {
  type: typeof NUTRITIONIST_LOADING;
}
export interface NutritionistLoadFailedAction {
  type: typeof NUTRITIONIST_LOAD_FAILED;
  error: string;
}

export const NUTRITIONIST_ADD = 'NUTRITIONIST_ADD';
export const NUTRITIONIST_ADDING = 'NUTRITIONIST_ADDING';
export const NUTRITIONIST_ADD_FAILED = 'NUTRITIONIST_ADD_FAILED';

export interface NutritionistAddAction {
  type: typeof NUTRITIONIST_ADD;
  nutritionist: Nutritionist;
}
export interface NutritionistAddingAction {
  type: typeof NUTRITIONIST_ADDING;
}
export interface NutritionistAddFailedAction {
  type: typeof NUTRITIONIST_ADD_FAILED;
  error: string;
}

export const NUTRITIONIST_DELETE = 'NUTRITIONIST_DELETE';
export const NUTRITIONIST_DELETING = 'NUTRITIONIST_DELETING';
export const NUTRITIONIST_DELETE_FAILED = 'NUTRITIONIST_DELETE_FAILED';

export interface NutritionistDeleteAction {
  type: typeof NUTRITIONIST_DELETE;
}
export interface NutritionistDeletingAction {
  type: typeof NUTRITIONIST_DELETING;
}
export interface NutritionistDeleteFailedAction {
  type: typeof NUTRITIONIST_DELETE_FAILED;
  error: string;
}

export const REGISTER_FROM_NUTRITIONIST_SUCCESS =
  'REGISTER_FROM_NUTRITIONIST_SUCCESS';
export const REGISTERING_FROM_NUTRITIONIST = 'REGISTERING_FROM_NUTRITIONIST';
export const REGISTER_FROM_NUTRITIONIST_FAILED =
  'REGISTER_FROM_NUTRITIONIST_FAILED';

export interface RegisterFromNutritionistAction {
  type: typeof REGISTER_FROM_NUTRITIONIST_SUCCESS;
  account: Account;
}
export interface RegisteringFromNutritionistAction {
  type: typeof REGISTERING_FROM_NUTRITIONIST;
}
export interface RegisterFromNutritionistFailedAction {
  type: typeof REGISTER_FROM_NUTRITIONIST_FAILED;
  error: string;
}

export const COMPLETE_REGISTER_FROM_NUTRITIONIST_SUCCESS =
  'COMPLETE_REGISTER_FROM_NUTRITIONIST_SUCCESS';
export const COMPLETING_REGISTER_FROM_NUTRITIONIST =
  'COMPLETING_REGISTER_FROM_NUTRITIONIST';
export const COMPLETE_REGISTER_FROM_NUTRITIONIST_FAILED =
  'COMPLETE_REGISTER_FROM_NUTRITIONIST_FAILED';

export interface CompleteRegisterFromNutritionistAction {
  type: typeof COMPLETE_REGISTER_FROM_NUTRITIONIST_SUCCESS;
}
export interface CompletingRegisterFromNutritionistAction {
  type: typeof COMPLETING_REGISTER_FROM_NUTRITIONIST;
}
export interface CompleteRegisterFromNutritionistFailedAction {
  type: typeof COMPLETE_REGISTER_FROM_NUTRITIONIST_FAILED;
  error: string;
}

export const REFERER_LOAD = 'REFERER_LOAD';
export const REFERER_LOADING = 'REFERER_LOADING';
export const REFERER_LOAD_FAILED = 'REFERER_LOAD_FAILED';

export interface RefererLoadAction {
  type: typeof REFERER_LOAD;
  referer: User;
}
export interface RefererLoadingAction {
  type: typeof REFERER_LOADING;
}
export interface RefererLoadFailedAction {
  type: typeof REFERER_LOAD_FAILED;
  error: string;
}

export const GET_REFERER_ACCOUNTS = 'GET_REFERER_ACCOUNTS';
export const LOADING_REFERER_ACCOUNTS = 'LOADING_REFERER_ACCOUNTS';
export const FAILED_REFERER_ACCOUNTS = 'FAILED_REFERER_ACCOUNTS';

export interface GetRefererAccountsAction {
  type: typeof GET_REFERER_ACCOUNTS;
  refererAccounts: Account[];
}

export interface LoadingRefererAccountsAction {
  type: typeof LOADING_REFERER_ACCOUNTS;
}

export interface FailedRefererAccountsAction {
  type: typeof FAILED_REFERER_ACCOUNTS;
  error: string;
}

export type NutritionistActionTypes =
  | NutritionistLoadAction
  | NutritionistLoadingAction
  | NutritionistLoadFailedAction
  | NutritionistAddAction
  | NutritionistAddingAction
  | NutritionistAddFailedAction
  | NutritionistDeleteAction
  | NutritionistDeletingAction
  | NutritionistDeleteFailedAction
  | RegisterFromNutritionistAction
  | RegisteringFromNutritionistAction
  | RegisterFromNutritionistFailedAction
  | CompleteRegisterFromNutritionistAction
  | CompletingRegisterFromNutritionistAction
  | CompleteRegisterFromNutritionistFailedAction
  | RefererLoadAction
  | RefererLoadingAction
  | RefererLoadFailedAction
  | GetRefererAccountsAction
  | LoadingRefererAccountsAction
  | FailedRefererAccountsAction
  | GetUserFromCodeSuccessAction
  | GetUserFromCodeAction
  | GetUserFromCodeFailureAction;
