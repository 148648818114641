import { Instrument } from "./types";

export const ADDING_INSTRUMENT = "ADDING_INSTRUMENT";
export const ADD_INSTRUMENT_URL = "ADD_INSTRUMENT_URL";
export const ADD_INSTRUMENT_FAILED = "ADD_INSTRUMENT_FAILED";

export interface AddingInstrumentAction {
  type: typeof ADDING_INSTRUMENT;
}

export interface AddInstrumentUrlAction {
  type: typeof ADD_INSTRUMENT_URL;
  url: string;
}

export interface AddInstrumentFailedAction {
  type: typeof ADD_INSTRUMENT_FAILED;
  error: string;
}

export type AddInstrumentActionTypes = AddingInstrumentAction | AddInstrumentUrlAction | AddInstrumentFailedAction;

export const INSTRUMENTS_LOADING = "INSTRUMENTS_LOADING";
export const ADD_INSTRUMENTS = "ADD_INSTRUMENTS";
export const INSTRUMENTS_FAILED = "INSTRUMENTS_FAILED";

export interface RequestInstrumentsAction {
  type: typeof INSTRUMENTS_LOADING;
}

export interface AddInstrumentsAction {
  type: typeof ADD_INSTRUMENTS;
  instruments: Instrument[];
}

export interface InstrumentsFailedAction {
  type: typeof INSTRUMENTS_FAILED;
  error: string;
}

export type FetchInstrumentsActionTypes = RequestInstrumentsAction | AddInstrumentsAction | InstrumentsFailedAction;

export type InstrumentsActionTypes = AddInstrumentActionTypes | FetchInstrumentsActionTypes;
