import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import location from "../../../../assets/location.png";
import * as styleVars from "../../../../styles/_variables.scss";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { FormTextField } from "../../../common/forms/FormTextField";
import { FormSelect } from "../../../common/forms/FormSelect";
import schemas from "../../../../data/schemas";
import states from "../../../../utils/states";
import { Address } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px 0 0 0",
    },
    image: {
      height: 48,
      width: "auto",
      [theme.breakpoints.down("xs")]: {
        height: 36,
      },
    },
    titleText: {
      fontSize: 24,
      [theme.breakpoints.down("xs")]: {
        fontSize: 16,
      },
    },
    closeIcon: {
      boxShadow: "0 2px 24px 0 rgba(0, 0, 0, 0.15)",
      backgroundColor: styleVars.white,
    },
    content: {
      margin: "0 auto",
      padding: 0,
      width: 400,
      overflow: 'hidden',
      [theme.breakpoints.down("xs")]: {
        padding: 0,
        width: "80%",
        overflow: 'scroll'
      },
    },
    actions: {
      padding: "32px 16px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    button: {
      fontSize: 18,
    },
  })
);

interface AddressDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSubmit: (value: Address) => void;
}


function AddressDialog({ open, setOpen, onSubmit }: AddressDialogProps) {
  const classes = useStyles();

  const submit = (values: Address, actions: FormikHelpers<Address>) => {
    console.log(values);
    console.log(actions);
    onSubmit(values)
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>
        <div className={classes.title}>
          <img src={location} alt="location" className={classes.image} />
          <span className={classes.titleText}>Agregar dirección</span>
          <IconButton
            className={classes.closeIcon}
            onClick={() => setOpen(false)}
          >
            <CloseIcon color="primary" fontSize="default" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Formik
          initialValues={{
            street: "",
            doorNumber: "",
            apartmentNumber: "",
            corner: "",
            state: 0
          }}
          validationSchema={schemas.AddressSchema}
          onSubmit={submit}
        >
          <Form>
            <Field
              name="state"
              component={FormSelect}
              label="Departamento"
              options={states}
            />
            <Field
              name="street"
              component={FormTextField}
              type="text"
              placeholder="Calle"
            />
            <Field
              name="doorNumber"
              component={FormTextField}
              type="text"
              placeholder="Número puerta"
            />
            <Field
              name="apartmentNumber"
              component={FormTextField}
              type="text"
              placeholder="Apartamento"
            />
            <Field
              name="corner"
              component={FormTextField}
              type="text"
              placeholder="Esquina"
            />
            <div className={classes.actions}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disableElevation
                className={classes.button}
              >
                Agregar
              </Button>
            </div>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default AddressDialog;
