import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { Button } from "@material-ui/core";
import { PathologiesFields } from "../../common/PathologiesFields";

interface Values {
  pathologies: string[];
}

interface PathologiesProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  pathologies: Values;
  setPathologies: React.Dispatch<React.SetStateAction<Values>>;
}

function Pathologies({ activeStep, setActiveStep, pathologies, setPathologies }: PathologiesProps) {
  const submit = (values: Values, actions: FormikHelpers<Values>) => {
    setPathologies(values);
    setActiveStep(activeStep + 1);
  };

  return (
    <div className="pathologies">
      <Formik initialValues={pathologies} onSubmit={submit}>
        <Form>
          <PathologiesFields />
          <div className="buttons">
            <Button
              classes={{ root: "outlined-button" }}
              onClick={() => setActiveStep(activeStep - 1)}
              color="primary"
              disableElevation
            >
              Anterior
            </Button>
            <Button type="submit" color="primary" variant="contained" disableElevation>
              Continuar
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default Pathologies;
