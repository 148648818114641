import React from "react";
import { Button, Paper } from "@material-ui/core";
import { UserType } from "../../../../data/enums";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/config/types";
import { authService } from "../../../../services/auth.service";

interface EmailConfirmedProps {
  userType: number;
}

function EmailConfirmed({ userType }: EmailConfirmedProps) {
  const auth = useSelector((state: RootState) => state.auth);

  const mail = auth.emailSent;

  async function resendMail() {
    try {
      await authService.resendEmail(mail);
    } catch (e) {
      console.log("Ha ocurrido un error");
    }
  }

  console.log(userType)
  if (
    userType === UserType.EndUser ||
    userType === UserType.Company ||
    userType === UserType.Nutritionist
  ) {
    return (
      <div
        className="mail-sent"
        style={{ marginTop: userType === UserType.Company ? 64 : 0 }}
      >
        <p className="huge-title">Último paso</p>
        <p className="mail-sent-speech">
          Perfecto, solo queda 1 paso, te vamos a enviar un email a{" "}
          <b>{mail}</b> para activar tu cuenta y así podés ingresar al
          dashboard.
          <br/><br/>
          Por favor revisa tu bandeja de spam si no recibes el email en unos minutos.
        </p>
        
        <div className="buttons">
          <Button
            classes={{ root: "big-button" }}
            onClick={resendMail}
            color="primary"
            variant="contained"
            disableElevation
          >
            Reenviar email
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Paper
      elevation={2}
      classes={{ root: "nutritionist-signup-content nutritionist-mail-sent" }}
    >
      <Paper elevation={0} classes={{ root: "content-heading" }}>
        <p className="speech">Lorem ipsum dolor sit a</p>
      </Paper>
      <p className="title">Lorem ispsdakl</p>
      <p className="mail-sent-speech">
        Perfecto, solo queda 1 paso, te vamos a enviar un email a <b>{mail}</b>{" "}
        para activar tu cuenta y así podés ingresar al dashboard.
      </p>
      <Button
        classes={{ root: "continue-button" }}
        onClick={resendMail}
        type="submit"
        variant="contained"
        color="primary"
        disableElevation
      >
        Reenviar mail
      </Button>
    </Paper>
  );
}

export default EmailConfirmed;
