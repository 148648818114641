import React, { useEffect, useCallback, useState, useRef } from "react";
import logo from "../../../assets/logo.png";
import { LoadingAnimation } from "../../animations/LoadingAnimation";
import { CompanyCard } from "../../common/CompanyCard";
import { EndUserCard } from "../../common/EndUserCard";
import { RootState } from "../../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import { addInstrument } from "../../../store/instruments/actionCreators";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { userAccount } from "../../../store/auth/selector";
import roles from "../../../utils/roles";
import { Header } from "../../common/Header";
import { Menu } from "@material-ui/core";
import { logout } from "../../../store/auth/actionCreators";
import {BASE_URL_FOODS, BASE_URL} from '../../../services/config';
import request from 'request';
import { set } from "date-fns";
import { Formik, Form, Field } from "formik";
import { FormTextField } from "../../common/forms/FormTextField";
import { Button } from "@material-ui/core";
import FormFileField from "../../common/forms/FormFileField/FormFileField";

const mapStateToProps = (state: RootState) => ({
  instruments: state.instruments,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, AppActions>
) => ({
  addInstrument: () => dispatch(addInstrument()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function LoadingPayment({ instruments, addInstrument, history }: PropsType) {
  const actualUser = useSelector((state: RootState) => userAccount(state));
  const isCompany = actualUser?.role === roles?.business;
  const dispatch = useDispatch();
  const costs = useSelector((state: RootState) => state.costs);
  const [coupon, setCoupon] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [finalPrice, setFinalPrice] = useState<number>(0);
  const [isInputDisabled, setInputDisabled] = useState(false);

  const iframeRef = useRef(null);


  /* console.log('Historia: ');
  console.log(history);
  console.log('Instrumentos: ');
  console.log(instruments); */


  const redirectTo = useCallback(
    (path: string): void => {
      //console.log('Redireccion a desde loadingPayment: ', path);
      history.push(path);
    },
    [history]
  );

  useEffect(() => {
    if (
      !instruments.addingInstrument &&
      !instruments.instrumentUrl &&
      instruments.addInstrumentErrorMessage === ""
    ) {
      console.log("Agregando instrumento")
      addInstrument();
    }
  }, [
    instruments.addingInstrument,
    instruments.instrumentUrl,
    instruments.addInstrumentErrorMessage,
    addInstrument,
  ]);

  useEffect(() => {
    const el = document.querySelector(".signup-left-content") as HTMLElement;
    if(el) {
      el.style.backgroundColor = 'white';
    }
    window.addEventListener("message", receiveMessage);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);

  useEffect(() => {
    if(!isCompany) {
      fetch(`${BASE_URL}/v1/cost`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', 
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Error al enviar la solicitud');
          }
          return response.json(); 
        })
        .then((data: any) => {
          //console.log('precios: ');
          //console.log(data);
          let price = data.find(e => e.minAmount === 1 && e.maxAmount === 1).unitCost
          setPrice(price);
          setFinalPrice(price);
        })
        .catch(error => {
          console.error('Error al enviar la solicitud por cupón:', error);
        });

        localStorage.removeItem("coupon");
    }

  },[])

  useEffect(() => {
    localStorage.setItem("finalPrice", finalPrice.toString());
    console.log('Precio final actualizado')
  }, [finalPrice]);
  
  useEffect(() => {
    console.log('Precio actualizado')
    localStorage.setItem("price", price.toString());
  }, [price]);

  function receiveMessage(e: MessageEventInit) {
    console.log('Mensaje recibido: ');
    console.log(e);
    if (e.data === "redirectOccurred") {
      console.log("redirección desde loading payment");
      redirectTo("/dashboard");
    }
  }

  const loadingPlexo = (
    <div className="loading-payment">
      <LoadingAnimation />
      <p>
        En unos segundos serás redireccionado al sitio de Plexo para realizar el
        pago.
      </p>
    </div>
  );

  const plexoIframe = (
    <div className="iframe-container">
      <iframe
        height={700}
        width={700}
        src={instruments.instrumentUrl!}
        title="plexo"
      />
    </div>
  );

  const checkIframeUrl = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      try {
        const iframeUrl = iframe.contentWindow.location.pathname;
        if (iframeUrl === '/checkout') {
          window.top.location.href = iframe.contentWindow.location.href;
        }
      } catch (e) {
        // Puede fallar debido a políticas de CORS, ignora el error
      }
    }
  };

  function goBack() {
    dispatch(logout());
    history.push("/");
  }
  function goToMeals() {
    history.push("/platos-semanales");
  }

  function handleCoupon(e: React.ChangeEvent<HTMLInputElement>) {
    setCoupon(e.target.value);
  }

  function checkCoupon() {
    if(coupon === "") return
    setInputDisabled(true);

    const postData: { [key: string]: any } = {
      code: coupon
    };
    
    fetch(`${BASE_URL_FOODS}/v1/coupon/checkCoupon`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', 
      },
      body: JSON.stringify(postData),
    })
      .then(response => {
        if (!response.ok) {
          setInputDisabled(false);
          throw new Error('Error al enviar la solicitud');
        }
        return response.json(); 
      })
      .then((data: any) => {
        //console.log('Resp cupón:', data);

        if(data[0]) {
          localStorage.setItem("coupon", coupon.toUpperCase());
          const discount = Number(data[1]);
          const newPrice = price - discount;
          setFinalPrice(newPrice);

          if(newPrice === 0) {
            console.log("Test gratuito");
            //redirect test gratis
            redirectTo("/checkout");
          }
          console.log("Cupón aplicado");
          //actualizo precio
          
          
        } else {
          setInputDisabled(false);
          setCoupon("");
        }

      })
      .catch(error => {
        console.error('Error al enviar la solicitud por cupón:', error);
      });



  }

  const verifyCoupon = async (couponCode) => {
    try {
      const response = await fetch(`${BASE_URL_FOODS}/v1/coupon/checkCoupon`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code: couponCode }),
      });
      if (!response.ok) throw new Error('Error al verificar el cupón');
      const data = await response.json();
      return data; // Asegúrate de que esta respuesta se ajuste a tus necesidades
    } catch (error) {
      console.error('Error al verificar el cupón:', error);
      throw error; // Propagar el error para manejarlo en onSubmit
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>, submitForm: any) => {
    event.preventDefault();
    event.stopPropagation();
    submitForm();
  };

  const submitForm = async (values, actions) => {
    const { coupon } = values;
    try {
      const couponData = await verifyCoupon(coupon);
      //console.log('Resp cupón:', couponData);
      if (couponData[0]) {
        localStorage.setItem("coupon", coupon.toUpperCase());
        const discount = Number(couponData[1]);
        const newPrice = price - discount;
        //console.log('Precio final:', newPrice)
        setFinalPrice(newPrice);
        
        if (newPrice === 0) {
          redirectTo("/checkout"); 
        }
      } else {
        // Manejar cupón inválido
      }
    } catch (error) {
      // Manejar errores de la verificación aquí
    } finally {
      actions.setSubmitting(false); 
    }
  };

  return (
    <div className="container">
      <div className="header-payment">
      <div className="gotomeals" onClick={goToMeals}>
        <p>Platos semanales</p>
      </div>  
      <div className="logout" onClick={goBack}>
          <p>Cerrar Sesion</p>
        </div>
      </div>
      <div className="user-signup user-payment-coupon" style={{height:'auto !important'}}>
        <div className="signup-left-content">
          <img src={logo} alt="logo" className="logo" />
          <div style={{width:'100%'}}>
            { !isCompany ? (
              <>
                <div className="finalPrice">
                  <Formik 
                  initialValues={{ coupon: '' }}
                  onSubmit={submitForm}
                    >
                      {({ isSubmitting, isValid, handleSubmit: formikSubmit }) => (
                        <Form onSubmit={(e) => handleSubmit(e, formikSubmit)} className="form">
                          <div className="your-data">
                            <p className="title">Tienes un cupón de descuento?</p>
                            <div className="horizontal-line coupon">
                              <Field
                                className="field"
                                name="coupon"
                                component={FormTextField}
                                type="text"
                                placeholder="INGRESÁ EL CÓDIGO"
                                style={{width:'100% !important'}}
                              />
                            </div>
                          </div>
                          <div className="buttons">
                            <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                              disableElevation
                              disabled={isSubmitting}
                              style={{width:'100%'}}
                              
                            >
                              Aplicar cupón
                            </Button>
                          </div>
              
                        </Form>
                        
                      )}
                  </Formik>
                  <div className="final-price-coupon">
                    Precio final <span>USD {finalPrice} Imp. Incl.</span>
                  </div>
                </div>

              { /*  <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%', paddingTop:'20px'}}>
                  <span>¿Tienes un cupón de descuento?</span>
                  <div style={{display:'flex', width:'100%', padding:'25px'}}>
                    <input style={{width:'65%', height:'30px'}} disabled={isInputDisabled} type='text' placeholder='INGRESÁ EL CÓDIGO' value={coupon} onChange={handleCoupon} />  
                    <button style={{width:'35%'}} onClick={checkCoupon}>Aplicar cupón</button>
                  </div>
                  <div style={{ display:'flex', flexDirection:'column', marginTop:'10px', alignItems:'center'}}>Precio final 
                    <span>{finalPrice}</span>
                  </div>
                  </div> */}
              </>
              ) : ''
            }
          </div>
          {instruments.instrumentUrl ? plexoIframe : loadingPlexo}
        </div>
        <div className="signup-right-content payment-right-content" style={{backgroundSize:'100% !important', width:'100% !important'}}>
          <img src={logo} alt="logo" className="logo" />
          <CompanyCard isCompany={isCompany} />
        </div>
      </div>
    </div>
  );
}

export default connector(withRouter(LoadingPayment));
