import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import CommonContainer from "./common/CommonContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface Week {
  weekId: number;
  weekstart: string;
  weekend: string;
  mealsq: number;
  mealsList: Meal[];
}

interface Meal {
  id: number;
  title: string;
  subtitle: string;
  photo: string;
  category: string;
  calories: number;
  carbos: number;
  fats: number;
  proteins: number;
}

function Meals() {
  const [loading, setLoading] = useState(true);
  /*  const [mealsData, setMealsData] = useState<Week[]>([]);
  const [expandedWeekId, setExpandedWeekId] = useState<number | null>(null);
  

  useEffect(() => {
    fetchEndpointData();
  }, []);

  const fetchEndpointData = async () => {
    try {
      const response = await fetch("https://services.balance.com.uy/v1/meal/MealsByWeek");
      const data = await response.json();
      setMealsData(data);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    }
  };

  const toggleWeek = (weekId: number) => {
    if (expandedWeekId === weekId) {
      setExpandedWeekId(null);
    } else {
      setExpandedWeekId(weekId);
    }
  }; */

  return (
    <CommonContainer>
      <div className="mealsPadding">
        <div style={{display:'flex', justifyContent:'center', width:'100%', alignItems:'center'}}>
          <p>Te presentamos una nueva forma de alimentarte</p>
        </div>
        <Paper className="result-card welcome">
          {/*loading ? (
            <p>Cargando...</p>
          ) : (*/
            <div className="meals">

              {/*mealsData.map((week) => (
                <div key={week.weekId} className={`week ${expandedWeekId === week.weekId ? "expanded" : ""}`}>
                  <div className="week-header" onClick={() => toggleWeek(week.weekId)}>
                    <div>
                      <p>
                        Semana desde el <b>{week.weekstart.split("-")[2]}/{week.weekstart.split("-")[1]}</b> hasta el{" "}
                        <b>{week.weekend.split("-")[2]}/{week.weekend.split("-")[1]}</b>
                      </p>
                      <p>Cantidad de Platos disponibles: <b>{week.mealsq}</b></p>
                    </div>
                    <div><FontAwesomeIcon
                      icon={faChevronDown}
                      className={`toggle-icon ${expandedWeekId === week.weekId ? "expanded" : ""}`}
                    /></div>
                  </div>
                  {expandedWeekId === week.weekId && (
                    <div key={`mealsList-${week.weekId}`} className="mealsList">
                      {week.mealsList.map((meal) => (
                        <div key={meal.id} className="meal card">
                          <img src={meal.photo} alt={meal.title} />
                          <h3>{meal.title} {meal.subtitle}</h3>
                          <p>Categoría: {meal.category}</p>
                          <p>
                            {meal.calories} KCal (C {meal.carbos}g / G {meal.fats}g / P {meal.proteins}g)
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                      ))*/}
            </div>
          /* ) */}
        </Paper>
      </div>
    </CommonContainer>
  );
}

export default Meals;
/*
<CommonContainer>
      <div className="mealsPadding">
        <Paper className="result-card welcome">
          <div className="meals">
            
          </div>
        </Paper>
      </div>
    </CommonContainer>
*/