import { UsersState } from "./types";
import {
  UsersActionTypes,
  USERS_LOAD,
  USERS_LOADING,
  USERS_LOAD_FAILED,
  USERS_ADD,
  USERS_ADDING,
  USERS_ADD_FAILED,
  CHANGE_AVATAR,
} from "./actionTypes";

const initialState: UsersState = {
  users: null,
  usersUpdating: false,
  usersUpdateErrorMessage: "",
  usersAdding: false,
  usersAddSuccess: false,
  usersAddErrorMessage: "",
  companyAvatar: null
};

export function usersReducer(state = initialState, action: UsersActionTypes): UsersState {
  switch (action.type) {
    case CHANGE_AVATAR: 
      return {
        ...state,
        companyAvatar: action.avatar
      }
    case USERS_LOAD:
      return {
        ...state,
        usersUpdating: false,
        usersUpdateErrorMessage: "",
        users: action.users,
      };
    case USERS_LOADING:
      return {
        ...state,
        usersUpdating: true,
      };
    case USERS_LOAD_FAILED:
      return {
        ...state,
        usersUpdating: false,
        usersUpdateErrorMessage: action.error,
      };
    case USERS_ADD:
      const newUsersList = state.users ? state.users : [];
      newUsersList.push(action.user);
      return {
        ...state,
        users: newUsersList,
        usersAdding: false,
        usersAddErrorMessage: "",
        usersAddSuccess: true,
      };
    case USERS_ADDING:
      return {
        ...state,
        usersAdding: true,
      };
    case USERS_ADD_FAILED:
      return {
        ...state,
        usersAdding: false,
        usersAddSuccess: false,
        usersAddErrorMessage: action.error,
      };
    default:
      return state;
  }
}
