import React, { useEffect, useState } from "react";
import { Paper, Button } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import qs from 'query-string';
import { Formik, Form, FormikHelpers, Field } from "formik";
import { useSelector, useDispatch } from 'react-redux';

import { Header } from "../../../common/Header";
import { UserDataFields } from "../../../common/UserDataFields";
import { PathologiesFields } from "../../../common/PathologiesFields";
import { FamilyBackgroundFields } from "../../../common/FamilyBackgroundFields";
import schemas from "../../../../data/schemas";
import { Account as Acc } from '../../../../store/auth/types';
import { RootState } from '../../../../store/config/types';
import { userAccount, userRole } from '../../../../store/auth/selector';
import { authService } from '../../../../services/auth.service';
import { changeEmail, register } from '../../../../store/auth/actionCreators';
import FormTextField from '../../../common/forms/FormTextField/FormTextField';
import roles from "../../../../utils/roles";
import { FullWidthSnackbar } from "../../../common/FullWidthSnackbar";

import logo from "../../../../assets/logo.png";

interface Values {
  name: string;
  surname: string;
  gender: number;
  height: string;
  weight: string;
  phone: string;
  ethnicity: number;
  date: string;
  email: string;
  pathologies: string[];
  familyBackground: string[];
  password: string;
  repeatPassword: string;
}

function UserForm({ history }: RouteComponentProps) {
  const [open, setOpen] = useState(false);
  const role = useSelector((state: RootState) => userRole(state));
  const isNutritionist = role === roles.nutricionist;
  const endUserName = isNutritionist ? "paciente" : "empleado";

  const [isUser, setIsUser] = useState<boolean>(false);
  const actualUser = useSelector((state: RootState) => userAccount(state));
  const dispatch = useDispatch();
  const [business, setBusiness] = useState<any>();
  const [nutritionist, setNutritionist] = useState<any>();

  const [initialValues, setInitialValues] = useState<Values>({
    name: "",
    surname: "",
    gender: 0,
    height: "170",
    weight: "70",
    phone: "",
    ethnicity: 0,
    date: "1990-01-01T00:00:00",
    email: "",
    pathologies: [],
    familyBackground: [],
    password: "",
    repeatPassword: ""
  });

  useEffect(() => {
    const search = window.location.search;
    if (isUser === false && search != "") {
      setIsUser(true);
      const userData = qs.parse(search)['user'].toString();
      authService.getUserFromBusiness(userData).then(
        (response) => {
          console.log(response);
          const profile = response.account.profile;

          let pathologiesIds = [];
          let familyBackIds = [];
          if (profile) {
            for (let i of profile.pathologies) {
              pathologiesIds.push(i.id);
            }

            for (let i of profile.familyHistories) {
              familyBackIds.push(i.id);
            }

            setInitialValues({
              name: profile.firstName,
              surname: profile.lastName,
              gender: profile.gender,
              height: profile.height,
              weight: profile.weight,
              phone: profile.mobile,
              ethnicity: 0,
              date: profile.birthDate,
              email: response.account.email,
              pathologies: pathologiesIds,
              familyBackground: familyBackIds,
              password: "",
              repeatPassword: ""
            })
          }

          setBusiness(response.account.business);
          setNutritionist(response.account.referer);

        },
        (error) => {
          console.log('error', error)
        }
      )
    }
  }, [setInitialValues, initialValues]);

  const submit = async (values: Values, { setSubmitting, validateForm, setFieldError }) => {
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      setSubmitting(false);
      return;
    }
    
    authService.verifyEmail(values.email).then(
      (response) => {
        let pathologiesIds = [];
        for (let i of values.pathologies) {
          pathologiesIds.push({ id: i, name: "" });
        }

        let familyBackIds = [];
        for (let i of values.familyBackground) {
          familyBackIds.push({ id: i, name: "" });
        }
        if (isUser) {
          const account = createRequestRegisterCompany(business, nutritionist, values, pathologiesIds, familyBackIds);
          dispatch(changeEmail(values.email));
          dispatch(register(account, values.password));
          history.push("/signup/?user=1&step=4&fromCompany=1");
        }
        else {
          if (isNutritionist) {
            const account = createRequestRegisterNutricionist(actualUser.nutritionist, values, pathologiesIds, familyBackIds);

            const request = {
              accountId: actualUser.id,
              newAccountInfo: account
            };

            authService.createUserFromNutricionist(request).then(
              (response) => {
                goBack();
              },
              (error) => {
                setOpen(true);
              })


          } else {
            const account = createRequestRegisterCompany(actualUser.business, nutritionist, values, pathologiesIds, familyBackIds);

            const request = {
              accountId: actualUser.id,
              newAccountInfo: account
            };

            authService.createUserFromBusiness(request).then(
              (response) => {
                goBack();
              },
              (error) => {
                console.log("error",error);
              }
            )
          }
        }
      },
      (error) => {
        setSubmitting(false);  
        setFieldError('email', 'El correo electrónico no esta disponible');
      }
    )
  };

  const createRequestRegisterCompany = (business, nutritionist, values, pathologiesIds, familyBackIds): Acc => {
    var referrer = null;
    if (nutritionist) {
      referrer = {
        publicCode: nutritionist.publicCode,
        profile: nutritionist
      }
    }
    const profile = {
      firstName: values.name,
      lastName: values.surname,
      birthDate: values.date,
      height: values.height,
      weight: values.weight,
      gender: values.gender,
      analysis: null,
      mobile: values.phone,
      address: "",
      pathologies: pathologiesIds,
      familyHistories: familyBackIds,
    }
    return {
      role: "Customer",
      profile,
      email: values.email,
      business: business,
      referer: referrer,
      nutritionist: null,
    };
  };

  const createRequestRegisterNutricionist = (nutritionist, values, pathologiesIds, familyBackIds): Acc => {
    const referrer = {
      publicCode: nutritionist.publicCode,
      profile: nutritionist
    }
    const profile = {
      firstName: values.name,
      lastName: values.surname,
      birthDate: values.date,
      height: values.height,
      weight: values.weight,
      gender: values.gender,
      analysis: null,
      mobile: values.phone,
      address: "",
      pathologies: pathologiesIds,
      familyHistories: familyBackIds,
    }
    return {
      role: "Customer",
      profile,
      email: values.email,
      referer: referrer,
      business: null,
      nutritionist: nutritionist,
    };
  };

  const goBack = () => {
    history.push("/dashboard");
  };

  const redirectTo = (path: string): void => {
    history.push(path);
  };

  return (
    <div className="dashboard">
      {isUser ? (
        <div className="header">
          <img src={logo} alt="logo" className="logo" onClick={() => redirectTo("/")} />
          <div className="right">
          </div>
        </div>
      ) :
        <Header />
      }
      <Paper className="user-form">
        <div className="heading-line">
          {isUser ? (<h2>Cargar datos personales</h2>) : (<h2>{`Cargar datos del ${endUserName}`}</h2>)}
          {!isUser &&
            <div className="back" onClick={goBack}>
              <ArrowBackIcon />
              <p>Volver</p>
            </div>
          }
        </div>
        {!isUser && (<p>
          {`Podés cargar la mayor cantidad de información para que el ${endUserName} no lo deba hacer, si te faltan algunos
          campos no te preocupes ya que el ${endUserName} lo puede completar luego.`}
        </p>)}
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={schemas.CreateUserSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={submit}
        >
        { ({ isSubmitting, validateForm }) => (
          <Form className="form" autoComplete="off">
            <div className="top-separator" />
            <UserDataFields speech={!isUser ? `Datos del ${endUserName}` : "Mis datos"} />
            {/* <PathologiesFields />
            <FamilyBackgroundFields /> */}
            {isUser && <div className="your-passwords">
              <p className="title">Contraseña para ingresar a tu perfil</p>
              <div className="horizontal-line">
                <Field
                  className="field"
                  name="password"
                  component={FormTextField}
                  type="password"
                  placeholder="Contraseña"
                />
                <Field
                  className="field"
                  name="repeatPassword"
                  component={FormTextField}
                  type="password"
                  placeholder="Repetir contraseña"
                />
              </div>
            </div>}
            <div className="bottom-separator" />
            <div className="buttons">
              <Button type="submit" color="primary" variant="contained" disableElevation>
                {isUser ? "Confirmar datos" : "Guardar cambios"}
              </Button>
            </div>
          </Form>
        )}
        </Formik>
      </Paper>
      <FullWidthSnackbar
        open={open}
        setOpen={setOpen}
        text="El correo ya está ingresado"
        success={false}
      />
    </div>
  );
}

export default withRouter(UserForm);
