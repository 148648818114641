import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAnalysisResults } from "../../store/analyses/actionCreators";
import { RootState } from "../../store/config/types";
import { LoadingAnimation } from "../animations/LoadingAnimation";
import { FullWidthSnackbar } from "../common/FullWidthSnackbar";
import { mapDiagnostics } from "./Diagnostics";

type DiagnosticsProps = {
  patientId: string;
};

function Metabolisms({ patientId }: DiagnosticsProps) {
  const [mappedAnalysis, setmappedAnalysis] = useState({
    intolerancesMain: [],
    intolerancesOther: [],
  });
  const dispatch = useDispatch();
  const { analysisResult, analysisResultError, analysisResultSuccess } =
    useSelector((x: RootState) => x.analyses);
  useEffect(() => {
    if (!analysisResult.length) {
      dispatch(fetchAnalysisResults(patientId));
    }
  }, []);

  useEffect(() => {
    setmappedAnalysis(mapDiagnostics(analysisResult));
  }, [analysisResult]);

  return (
    <>
      <div className="diagnostic-container metabolism">
        {analysisResultSuccess ? (
          <>
            <h3>Principales</h3>
            <div className="diagnostic-container">
              {mappedAnalysis?.intolerancesMain?.map((a) => {
                return <DiagnosticCard result={a} />;
              })}
            </div>

            <h3>Alcohol, cafeína y comportamiento</h3>
            <div className="diagnostic-container">
              {mappedAnalysis?.intolerancesOther?.map((a) => {
                return <DiagnosticCard result={a} />;
              })}
            </div>
          </>
        ) : (
          <div className="loading">
            <LoadingAnimation />
          </div>
        )}
      </div>

      <FullWidthSnackbar
        open={analysisResultError}
        setOpen={() => false}
        text="Hubo un error al mostrar los diagnosticos"
        success={false}
      />
    </>
  );
}

const DiagnosticScore = {
  0: "Bajo",
  1: "Moderado",
  2: "Alto",
};
function DiagnosticCard({ result }) {
  return (
    <div className="diagnostic">
      <h4>{result.label}</h4>
      <p>{DiagnosticScore[result.value]}</p>
      <div className="circles">
        <div>
          <div className={`low ${result.value === 0 ? "active" : null}`}></div>
        </div>
        <div>
          <div
            className={`medium ${result.value === 1 ? "active" : null}`}
          ></div>
        </div>
        <div>
          <div className={`high ${result.value === 2 ? "active" : null}`}></div>
        </div>
      </div>
      <p className="description">{result.desc[result.value]}</p>
    </div>
  );
}

export default Metabolisms;
