import React, { useEffect, useState } from "react";
import CheckIcon from "@material-ui/icons/Check";
import calendar from "../../../../assets/calendar.png";
import DateDialog from "./DateDialog";
import moment from "moment";
import "moment/locale/es";
import { Button } from "@material-ui/core";
import { googleCalendarEventUrl } from "google-calendar-url";

const timeOptions = ["8am - 2pm", "2pm - 10pm"];

function generateDates(): SelectableDate[] {
  const now = moment();
  now.locale("es");
  const next48hs = now.add(60, "hour");
  const dates = [];
  for (let i = 0; i < 3; i++) {
    if (next48hs.day() === 0) {
      next48hs.add(1, "day");
    }
    if (next48hs.day() === 6) {
      next48hs.add(2, "day");
    }
    if (next48hs.hour() > 14) {
      next48hs.add(8, "hour");
    }
    const nextDateFormatted = next48hs.locale(false).format("dddd D [de] MMMM");
    dates.push({
      label: `${nextDateFormatted} - ${timeOptions[0]}`,
      id: 0,
      date: next48hs.clone(),
    });
    dates.push({
      label: `${nextDateFormatted} - ${timeOptions[1]}`,
      id: 1,
      date: next48hs.clone(),
    });
    next48hs.add(1, "day");
  }
  return dates;
}

export interface SelectableDate {
  id: number;
  label: string;
  date: string;
}

interface DateSelectorProps {
  selected: SelectableDate;
  setSelected: (value: SelectableDate) => void;
  shouldContact: boolean;
  coordinated: boolean;
}

function DateSelector({
  selected,
  setSelected,
  coordinated,
  shouldContact,
}: DateSelectorProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [dates, setDates] = useState([]);
  const [internalSelected, setInternalSelected] = useState(-1);
  const [url, setUrl] = useState("");

  useEffect(() => {
    setDates(generateDates());
  }, []);

  function selectCustom(date, time) {
    setInternalSelected(-1);
    setSelected({ id: time, label: `${date} - ${time}`, date: date });
  }

  function getUrl() {
    if (selected) {
      const url = googleCalendarEventUrl({
        start: moment(selected.date).format('YYYYMMDD'),
        end: moment(selected.date).format('YYYYMMDD'),
        title: "Envio de kit Mr.Gen",
      });
      setUrl(url);
    }

  }

  useEffect(() => {
    getUrl();
  }, [selected]);

  return (
    <div className="date">
      <img src={calendar} alt="date" />
      <div className="right-chunk">
        <p className="title">Seleccionar día y horario</p>

        {!coordinated ? (
          <div className="content">
            <div className="options">
              {shouldContact ? (
                <div className="grey-container">
                  <p>Serás contactado a la brevedad para coordinar el envío</p>
                </div>
              ) : (
                dates.map((d: SelectableDate, ix) => {
                  return (
                    <div key={ix} className="option-container">
                      <div
                        className={`option ${
                          internalSelected === ix ? "selected-option" : ""
                        }`}
                        onClick={() => {
                          setInternalSelected(ix);
                          setSelected(d);
                        }}
                      >
                        <span>{d.label}</span>
                        {internalSelected === ix ? (
                          <CheckIcon className="check-icon" />
                        ) : null}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
            <div className="other-option">
              <span className="speech">No puedes ninguno de estos días?</span>
              <span className="primary-link" onClick={() => setOpen(true)}>
                Seleccionar día y horario específico
              </span>
            </div>
          </div>
        ) : (
          <div className="coordinated-option">
            <span>
              {moment(selected.date).format("LL")} - {timeOptions[selected.id]}
            </span>
            <span className="primary-link" onClick={() => setOpen(true)}>
              Editar
            </span>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => window.open(url)}
            >
              Agregar a mi Calendar
            </Button>
          </div>
        )}
      </div>
      <DateDialog open={open} setOpen={setOpen} onSelectDate={selectCustom} />
    </div>
  );
}

export default DateSelector;
