import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  RouteComponentProps,
  withRouter,
  useLocation
} from "react-router-dom";

import { Login } from "./auth/Login";
import { ChangePassword } from "./auth/ChangePassword/";
import { ResetPassword } from "./auth/ResetPassword";
import { Signup } from "./signup";
import { Landing } from "./landing/Landing";
import { EmailVerified } from "./verify/Verify";
import { Dashboard } from "./dashboard";
import { Profile } from "./profile";
import { DNAForm } from "./profile/DNAForm";
import { NutritionistPayments } from "./profile/NutritionistPayments";
import { PaymentMethod } from "./profile/PaymentMethod";
import { PaymentResult } from "./payment/PaymentResult";
import { Redirect } from "./payment/Redirect";
import { Checkout } from "./payment/Checkout";
import { LoadingPayment } from "./payment/LoadingPayment";
import { UserForm } from "./dashboard/common/UserForm";
import { RootState } from "../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../store/config/ActionTypes";
import { getAccount } from "../store/auth/actionCreators";
import { ConnectedProps, connect } from "react-redux";
import { urlHelper } from "../helpers/urlHelper";
import { LoadingAnimation } from "./animations/LoadingAnimation";
import { TermsAndConditions } from "./common/TermsAndConditions";
import EmailConfirmed from "./signup/common/EmailConfirmed/EmailConfirmed";
import EndUserLanding from "./endUser/Landing";
import Results from "./endUser/Results";
import Foods from "./endUser/Foods";
import Agenda from "./endUser/Agenda";
import Meal from "./endUser/Meals";
import roles from "../utils/roles";
import NutritionistResults from "./dashboard/NutritionistDashboard/NutritionistResults";
//import ReactGA from 'react-ga';
import { MenuSemanal } from "./common/MenuSemanal";
import { ConsultaNutricional } from "./common/ConsultaNutricional";
import { MealsPayment } from "./common/MealsPayment";
import { LoadingPaymentClone } from "./payment/LoadingPaymentClone";

//ReactGA.initialize('UA-210867173-1');

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, AppActions>
) => ({
  getAccount: () => dispatch(getAccount()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Main({ auth, getAccount, history }: PropsType) {
  const [loading, setLoading] = useState(true);
  const hasToken =
    window.localStorage.getItem("token") !== undefined &&
    window.localStorage.getItem("token") !== null;
  
  const loc = useLocation();
  const route = loc.pathname;
  
  useEffect(() => {
    if (!auth.getAccountSuccess && auth.getAccountErrorMessage) {
      setLoading(false);
      history.push("/");
    }
  }, [auth.getAccountSuccess, auth.getAccountErrorMessage, auth.loggedIn]);

  /* useEffect(() => {
    history.listen(location => {
      ReactGA.pageview(location.pathname);
      //console.log('tageado '+location.pathname);
    });
  },[history]); */

  useEffect(() => {
    if (hasToken) {
      if (!auth.loggingIn && !auth.loggedIn && auth.loginErrorMessage === "") {
        getAccount();
      }

      if (auth.loggedIn) {
        setLoading(false);
        console.log(auth.account);
        if (
          history.location.pathname == "/payment" ||
          history.location.pathname == "/paymentMethod" ||
          history.location.pathname == "/checkout" ||
          history.location.pathname == "/payrenewed" 
        ) {
          return;
          
        }
        if (
          auth.account.profile !== null &&
          auth.account.role === roles.user &&
          auth.account.profile.analysis === null
        ) {
          //Aca le pregunto si el Profile->Analysis->Order != null, en caso de ser null lo mando a la ventana de pago de plexo.
          history.push("/loadingPayment");
        } else {
          if(history.location.pathname == "/platos-semanales"){
            return;
          }
          if(history.location.pathname == "/consulta-nutricional"){
            return;
          }
          if(history.location.pathname == "/meals-checkout"){
            return;
          }
          history.push("/dashboard");
          //REDIRECCION PLEXO OBLIGADO CHEQUEOS
          //history.push("/loadingPayment");
        }
      }
    } else {
      if (
        !urlHelper.isOnPublicUrl(history.location.pathname)
      ) {
        history.push("/");
      }
      setLoading(false);
    }
  }, [
    auth.loggingIn,
    auth.loggedIn,
    auth.loginErrorMessage,
    getAccount,
    history,
    hasToken,
  ]);

  if (loading) {
    return (
      <div className="loading-full-screen">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <div className={`main ${auth.loggedIn ? "main-logged" : ""}`}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/resetPassword" component={ResetPassword} />
        <Route path="/changePassword" component={ChangePassword} />
        <Route path="/verified" component={EmailVerified} />
        <Route path="/signup" component={Signup} />
        <Route path="/dashboard/?payment=success" component={Dashboard} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/profile" component={Profile} />
        <Route path="/collections" component={NutritionistPayments} />
        <Route path="/paymentMethod" component={PaymentMethod} />
        <Route path="/dna" component={DNAForm} />
        <Route path="/payment" component={PaymentResult} />
        <Route path="/redirect" component={Redirect} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/loadingPayment" component={LoadingPayment} />
        <Route path="/payrenewed" component={LoadingPaymentClone} />
        <Route path="/createUser" component={UserForm} />
        <Route path="/emailConfirmed" component={EmailConfirmed} />
        <Route path="/home" component={EndUserLanding} />
        <Route path="/results" component={Results} />
        <Route path="/foods" component={Foods} />
        <Route path="/agenda" component={Agenda} />
        <Route path="/platos" component={Meal} />
        <Route path="/patientresult" component={NutritionistResults} />
        <Route path="/terminos-condiciones-uso-uy" component={TermsAndConditions} />
        <Route path="/platos-semanales" component={MenuSemanal} />
        <Route path="/consulta-nutricional" component={ConsultaNutricional} />
        <Route path="/meals-checkout" component={MealsPayment} />
        <Route path="/" component={Landing} />
      </Switch>
    </div>
  );
}

export default connector(withRouter(Main));
