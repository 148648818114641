import React, { useState, useEffect } from "react";
import { Paper, Button, Input } from "@material-ui/core";
import { businessService } from "../../../services/businesses.service";
import { createMemoryHistory } from "history";

interface BusinessKitProps {
  setToPay: React.Dispatch<React.SetStateAction<number>>
}

function BusinessKits({ setToPay }: BusinessKitProps) {
  const [available, setkitsavailables] = useState<number>(0);
  const [value, setValue] = useState<number>(0);
  const history = createMemoryHistory();
useEffect(() => {
  getkits();
}, []);

async function getkits() {
  setkitsavailables(await businessService.kitsavailables());
}

const valueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setValue(parseInt(event.target.value));
};

function gotobuykits(){
  setToPay(value);
}
  return (
    <Paper elevation={1} classes={{ root: "dashboard-content" }}>
        <div className="profile-business-content">
          <div className="profile-business-header">
            <div className="header-content">
              <div className="profile-kit-details">
                <div className="profile-kit-remaining">
                  <p className="subtitle">Cupos restantes: </p>
                  <p>{available != null ? available : 0}</p>
                </div>
              </div>
            </div>
            <div className="img-div" />
          </div>
          <p className="title">¿Necesitas más cupos?</p>
          <span>Ingresa la cantidad de cupos que quieres comprar: </span>
          <Input
      //value={value}
      onChange={valueChange}
    />
          <Button variant="contained" color="primary" onClick={gotobuykits} disableElevation>
          Comprar
        </Button>
        </div>
      
    </Paper>
  );
}


export default BusinessKits;
