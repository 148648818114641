import React from "react";
import { Step } from "./types";
import CheckIcon from "@material-ui/icons/Check";

interface ArrowStepsProps {
  activeStep: number;
  steps: Step[];
}

function ArrowSteps({ activeStep, steps }: ArrowStepsProps) {
  return (
    <div className="arrow-steps">
      <div className="steps">
        {steps.map((step, index) => {
          return (
            <div key={index} className="parent" style={{ zIndex: steps.length - index }}>
              <div
                className={`arrow ${
                  activeStep > index ? "past-arrow" : activeStep === index ? "present-arrow" : "future-arrow"
                }`}
              >
                {activeStep === index ? (
                  <div className="content">
                    <span className="number">{index + 1}</span>
                    <div className="right">
                      <p className="title">{step.presentTitle}</p>
                      <p className="name">{step.name}</p>
                    </div>
                  </div>
                ) : (
                  <div className="arrow-details">
                    <span className="name-desktop">{step.name}</span>
                    <span className="number-mobile">{index + 1}</span>
                    {index < activeStep ? <CheckIcon style={{ fontSize: 12 }} /> : null}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ArrowSteps;
