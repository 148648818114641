import { Commission } from "./types";

export const LOAD_COMMISSIONS = "LOAD_COMMISSIONS";
export const LOADING_COMMISSIONS = "LOADING_COMMISSIONS";
export const LOAD_COMMISSIONS_FAILED = "LOAD_COMMISSIONS_FAILED";

export interface LoadCommissionAction {
  type: typeof LOAD_COMMISSIONS;
  commissions: Commission[];
}

export interface LoadingCommissionsAction {
  type: typeof LOADING_COMMISSIONS;
}

export interface LoadCommissionsFailedAction {
  type: typeof LOAD_COMMISSIONS_FAILED;
  error: string;
}

export type CommissionsActionTypes =
  | LoadCommissionAction
  | LoadingCommissionsAction
  | LoadCommissionsFailedAction;