import { BankAccount } from "./types";

export const BANKACCOUNTS_LOAD = "BANKACCOUNTS_LOAD";
export const BANKACCOUNTS_UPDATING = "BANKACCOUNTS_UPDATING";
export const BANKACCOUNTS_LOAD_FAILED = "BANKACCOUNTS_LOAD_FAILED";

export const BANKACCOUNTS_ADD = "BANKACCOUNTS_ADD";
export const BANKACCOUNTS_ADDING = "BANKACCOUNTS_ADDING";
export const BANKACCOUNTS_ADD_FAILED = "BANKACCOUNTS_ADD_FAILED";

export interface BankAccountsLoadAction {
  type: typeof BANKACCOUNTS_LOAD;
  bankAccounts: BankAccount[];
}
export interface BankAccountsUpdatingAction {
  type: typeof BANKACCOUNTS_UPDATING;
}
export interface BankAccountsLoadFailedAction {
  type: typeof BANKACCOUNTS_LOAD_FAILED;
  error: string;
}

export interface BankAccountsAddAction {
  type: typeof BANKACCOUNTS_ADD;
  bankAccount: BankAccount;
}
export interface BankAccountsAddingAction {
  type: typeof BANKACCOUNTS_ADDING;
}
export interface BankAccountsAddFailedAction {
  type: typeof BANKACCOUNTS_ADD_FAILED;
  error: string;
}

export type BankAccountsActionTypes =
  | BankAccountsLoadAction
  | BankAccountsUpdatingAction
  | BankAccountsLoadFailedAction
  | BankAccountsAddAction
  | BankAccountsAddingAction
  | BankAccountsAddFailedAction;
