import React from "react";
import { Stepper, Step, StepLabel, StepConnector, withStyles, makeStyles } from "@material-ui/core";
import StepIcon from "./StepIcon";
import * as styleVars from "../../../../styles/_variables.scss";

const Connector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: styleVars.darkPrimary,
    },
  },
  completed: {
    "& $line": {
      borderColor: styleVars.darkPrimary,
    },
  },
  line: {
    borderColor: styleVars.darkBackground,
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useStepperStyles = makeStyles({
  root: {
    padding: "24px 0",
  },
  noPadding: {
    padding: 0,
  },
});

const steps = [1, 2, 3, 4, 5];

interface UserStepperProps {
  activeStep: number;
}

function UserStepper({ activeStep }: UserStepperProps) {
  const classes = useStepperStyles();

  return (
    <div className="stepper">
      <Stepper classes={{ root: classes.root }} connector={<Connector />} activeStep={activeStep}>
        {steps.map((s) => {
          return (
            <Step classes={{ root: classes.noPadding }} key={s}>
              <StepLabel classes={{ iconContainer: classes.noPadding }} StepIconComponent={StepIcon} />
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

export default UserStepper;
