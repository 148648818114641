import { Dispatch } from "redux";
import {
  AnalysesActionTypes,
  ANALYSES_LOADING,
  ADD_ANALYSES,
  // ANALYSES_FAILED,
  ADDING_ANALYSIS,
  ADD_ANALYSIS,
  ADD_ANALYSIS_FAILED,
  ANALYSIS_DIRECTIONS_LOADING,
  ADD_ANALYSIS_DIRECTIONS,
  // ANALYSIS_DIRECTIONS_FAILED,
  ADDING_ANALYSIS_DIRECTION,
  ADD_ANALYSIS_DIRECTION,
  FETCHING_ANALYSIS_RESULT,
  FETCH_ANALYSIS_FAILED,
  FETCH_ANALYSIS,
  FETCHING_ANALYSIS_FOODS,
  FETCH_ANALYSIS_FOODS,
  FETCH_ANALYSIS_FOODS_FAILED,
  // ADD_ANALYSIS_DIRECTION_FAILED,
} from "./actionTypes";
import { Analysis, Direction, CreateAnalysisRequest } from "./types";
import { analysesService } from "../../services/analyses.service";
import { ChangePatientIdAction, CHANGE_PATIENTID } from './actionTypes';

//#region Fetch analyses

export const fetchAnalyses = () => {
  return (dispatch: Dispatch<AnalysesActionTypes>) => {
    dispatch(loadingAnalyses());

    let analyses = [
      {
        id: "",
        referenceId: "string",
        created: new Date(),
        lastModified: new Date(),
        order: {
          invoiceId: "",
          transaction: "",
          quantity: 0,
        },
      },
    ];

    //TODO: Call backend
    setTimeout(() => {
      //dispatch(addAnalyses(analyses));
    }, 3000);
  };
};

const loadingAnalyses = (): AnalysesActionTypes => ({
  type: ANALYSES_LOADING,
});

const addAnalyses = (analyses: Analysis[]): AnalysesActionTypes => ({
  type: ADD_ANALYSES,
  analyses,
});

// const addAnalysesFailed = (error: string): AnalysesActionTypes => ({
//   type: ANALYSES_FAILED,
//   error,
// });

//#endregion

//#region Create and pay analysis

export const createAndPayAnalysis = ({
  analysisAmount,
  instrumentToken,
  installments,
  coupon
}: CreateAnalysisRequest) => {
  return (dispatch: Dispatch<AnalysesActionTypes>) => {
    dispatch(creatingAnalysis());

    const createAnalysisRequest: CreateAnalysisRequest = {
      analysisAmount,
      instrumentToken,
      installments,
      coupon
    };

    analysesService.createAnalysis(createAnalysisRequest).then(
      (response) => {
        dispatch(addAnalysis(response!));
      },
      (error) => {
        dispatch(addAnalysisFailed(error.message));
      }
    );
  };
};

const creatingAnalysis = (): AnalysesActionTypes => ({
  type: ADDING_ANALYSIS,
});

const addAnalysis = (analysis: Analysis): AnalysesActionTypes => ({
  type: ADD_ANALYSIS,
  analysis,
});

const addAnalysisFailed = (error: string): AnalysesActionTypes => ({
  type: ADD_ANALYSIS_FAILED,
  error,
});

//#endregion

//#region Fetch analyses directions

export const fetchAnalysisDirections = () => {
  return (dispatch: Dispatch<AnalysesActionTypes>) => {
    dispatch(loadingAnalysisDirections());

    let analysisDirections = [
      {
        id: "1",
      },
    ];

    //TODO: Call backend
    setTimeout(() => {
      dispatch(addAnalysisDirections(analysisDirections));
    }, 3000);
  };
};

const loadingAnalysisDirections = (): AnalysesActionTypes => ({
  type: ANALYSIS_DIRECTIONS_LOADING,
});

const addAnalysisDirections = (
  analysisDirections: Direction[]
): AnalysesActionTypes => ({
  type: ADD_ANALYSIS_DIRECTIONS,
  directions: analysisDirections,
});

// const addAnalysisDirectionsFailed = (error: string): AnalysesActionTypes => ({
//   type: ANALYSIS_DIRECTIONS_FAILED,
//   error,
// });

//#endregion

//#region Create analysis direction

export const chagePatientId = (id: string) => {
  return (dispatch: Dispatch<ChangePatientIdAction>) => {
    dispatch(
      {
        type: CHANGE_PATIENTID,
        id: id
      }
    )
  }
}

export const createAnalysisDirections = (direction: Direction) => {
  return (dispatch: Dispatch<AnalysesActionTypes>) => {
    dispatch(creatingAnalysisDirection());

    //TODO: Call backend
    setTimeout(() => {
      dispatch(addAnalysisDirection(direction));
    }, 3000);
  };
};

const creatingAnalysisDirection = (): AnalysesActionTypes => ({
  type: ADDING_ANALYSIS_DIRECTION,
});

const addAnalysisDirection = (
  analysisDirection: Direction
): AnalysesActionTypes => ({
  type: ADD_ANALYSIS_DIRECTION,
  direction: analysisDirection,
});

// const addAnalysisDirectionFailed = (error: string): AnalysesActionTypes => ({
//   type: ADD_ANALYSIS_DIRECTION_FAILED,
//   error,
// });

//#endregion

//#region Fetch analysis results

export const fetchAnalysisResults = (patientId: string) => {
  return async (dispatch: Dispatch<AnalysesActionTypes>) => {
    dispatch(fetchingAnalysisResult());

    try {
      var result = null; 
      if(patientId && patientId !== "") result = await analysesService.getAnalysesResultsById(patientId);
      else result = await analysesService.getAnalysesResults();
      dispatch(fetchingAnalysisSuccess(result));
    } catch {
      dispatch(
        fetchingAnalysisResultFailed("Failed to fetch analysis results")
      );
    }
  };
};

//#endregion fetch analysis results

const fetchingAnalysisResult = (): AnalysesActionTypes => ({
  type: FETCHING_ANALYSIS_RESULT,
});

const fetchingAnalysisSuccess = (results): AnalysesActionTypes => ({
  type: FETCH_ANALYSIS,
  results,
});

const fetchingAnalysisResultFailed = (error: string): AnalysesActionTypes => ({
  type: FETCH_ANALYSIS_FAILED,
  error,
});

//#region Fetch analysis foods

export const fetchAnalysisFoods = () => {
  return async (dispatch: Dispatch<AnalysesActionTypes>) => {
    dispatch(fetchingAnalysisFood());

    try {
      const food = await analysesService.getAnalysesFoods();

      dispatch(fetchingAnalysisFoodSuccess(food));
    } catch {
      dispatch(
        fetchingAnalysisFoodFailed("Failed to fetch analysis foods")
      );
    }
  };
};

const fetchingAnalysisFood = (): AnalysesActionTypes => ({
  type: FETCHING_ANALYSIS_FOODS,
});

const fetchingAnalysisFoodSuccess = (foods): AnalysesActionTypes => ({
  type: FETCH_ANALYSIS_FOODS,
  foods,
});

const fetchingAnalysisFoodFailed = (error: string): AnalysesActionTypes => ({
  type: FETCH_ANALYSIS_FOODS_FAILED,
  error,
});

//#endregion Fetch analysis foods