import React, { useState, useEffect } from "react";
import { urlHelper } from "../../../helpers/urlHelper";
import { LoadingAnimation } from "../../animations/LoadingAnimation";
import { REDIRECT_URL } from "../../../services/config"

function Redirect() {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let urlVars = urlHelper.parseQueryString();

    if (urlVars.errorcode) {
      setHasError(true);
    } else if (urlVars.sessionId && false) {
      console.log("TODO");
    } else if (false) {
      console.log("TODO");
    }
  }, []);

  const loading = (
    <div className="loading-full-screen">
      <LoadingAnimation />
    </div>
  );

  if (window.location !== window.parent.location) {
    const message = hasError ? "redirectError" : "redirectSuccess";
    window.parent.postMessage(message, `${REDIRECT_URL}redirect`);
    return loading;
  } else {
    if (hasError) {
      //TODO
    }
    return loading;
  }
}

export default Redirect;
