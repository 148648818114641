import React from "react";
import { Button } from "@material-ui/core";
import logo from "../../../../assets/logo.png";
import { Link, useHistory } from "react-router-dom";
import oit from "../../../../assets/oit.png";
import wef from "../../../../assets/wef.png";
import oms from "../../../../assets/oms.png";
import WhiteLogo from "../../../../assets/whiteLogo.png";
import mosaic from "../../../../assets/auth-left-mosaic.png";

interface JoinMrGenProps {
  title: string;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  type: "nutritionist" | "company";
}

function JoinMrGen({ title, activeStep, setActiveStep, type }: JoinMrGenProps) {
  
  const history = useHistory();

  function goToHome() {
    history.push('/');
  }
  
  return (
    <div className="welcome-screen-in-half">
      <div className="welcome-half-content">
        <div className="welcome-left-content">
          <div className="welcome-title">
            <img src={logo} alt="logo" className="logo" onClick={goToHome}/>
            <Link className="back" to="/">
              Volver
            </Link>
          </div>
          <h1 className="huge">{title}</h1>
          {type == "nutritionist" ? (
            <>
              <br/><br/>
              <p className="pdouble">
                Alcanza las metas de salud con tus pacientes, diseñando dietas
                personalizadas basadas en su ADN.
              </p>
              <p className="pdouble">
                El Test de nutrición y genética de Mr. Gen es tu mejor aliado
                para brindar a tus pacientes recomendaciones nutricionales
                asociadas a su genotipo y lograr con esto una dieta efectiva y
                con rápidos resultados.
              </p>
              <p className="pdouble">
                Por cada paciente que recomiendes podrás obtener ganancias.
                Regístrate y te contactaremos para brindarte información sobre
                la propuesta.
                <br/><br/>
              </p>
              {/* <a className="link-mobile" target="_blank" rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/mrgen-dev.appspot.com/o/informes_uy%2FMr.%20GEN-Resultado-usuario-prueba.pdf?alt=media&token=b9765f5b-ab90-4949-ae41-97cc5aed9fa6">
                <p>Descargar informe de ejemplo</p>
              </a> */}
              <div className="button">
            <Button
              onClick={() => setActiveStep(activeStep + 1)}
              variant="contained"
              color="primary"
            >
              Sumate a nuestro equipo
            </Button>
          </div>
              <br/>
            </>
          ) : null}
          {type == "company" ? (
            <>
            <div className="company-landing">
              <div className="section">
                <div className="image-wrapper">
                  <img src={oit} alt="OIT" />
                </div>
                <h3>Aumento de la productividad</h3>
                <p>
                  De acuerdo a la OIT (Organización Internacional del Trabajo)
                  una mala alimentación durante la jornada laboral causa
                  pérdidas de hasta un 17% en la productividad.
                </p>
              </div>
              <div className="section">
                <div className="image-wrapper">
                  <img src={oms} alt="OMS" className="oms" />
                </div>
                <h3>Prevención de enfermedades</h3>
                <p>
                  La OMS afirma que las enfermedades derivadas de una mala
                  alimentación, tales como diabetes, hipertensión, obesidad,
                  entre otros. Causan ausentismo, incapacidad a corto y largo
                  plazo, alto índice de rotación de personal, clima laboral
                  inestable entre otras.
                </p>
              </div>
              <div className="section">
                <div className="image-wrapper">
                  <img src={wef} alt="WEF" className="wef" />
                </div>
                <h3>Retorno de Inversión</h3>
                <p>
                  Según un estudio del WORLD ECONOMIC FORUM, los programas de
                  bienestar corporativo logran retornos de inversión hasta un
                  300%
                </p>
              </div>
            </div>
            <a className="link-mobile" target="_blank" rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/mrgen-dev.appspot.com/o/otros%2FMr.GEN-Comercial-Empresas-2024-c.pdf?alt=media&token=b5081f35-5d7c-4efc-a49c-5e51dafab10b">
              <p>Descargar presentación comercial</p>
            </a>
            <div className="button">
            <Button
              onClick={() => setActiveStep(activeStep + 1)}
              variant="contained"
              color="primary"
            >
              Comenzá a probar Mr.GEN
            </Button>
          </div>
            </>
          ) : null}
          
        </div>
      </div>

      <div className="welcome-half-content welcome-right-content">
        <img src={mosaic} className="" alt=""/>
        <div className="text" style={{marginTop: '-50px'}}>
          <h1>Más conocimiento, mejor bienestar, mejor calidad de vida</h1>
          {type == "company" ? (
            <a className="link" target="_blank" rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/mrgen-dev.appspot.com/o/otros%2FMr.GEN-Comercial-Empresas-2024-c.pdf?alt=media&token=b5081f35-5d7c-4efc-a49c-5e51dafab10b">
              <p>Descargar presentación comercial</p>
            </a>
          ): null}
        </div>
        
      </div>      
    </div>
  );
}

export default JoinMrGen;
