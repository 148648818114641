import {
  NutritionistActionTypes,
  NUTRITIONIST_LOAD,
  NUTRITIONIST_LOADING,
  NUTRITIONIST_LOAD_FAILED,
  NUTRITIONIST_ADD,
  NUTRITIONIST_ADDING,
  NUTRITIONIST_ADD_FAILED,
  NUTRITIONIST_DELETE,
  NUTRITIONIST_DELETING,
  REFERER_LOAD,
  REFERER_LOADING,
  REFERER_LOAD_FAILED,
  NUTRITIONIST_DELETE_FAILED,
  REGISTER_FROM_NUTRITIONIST_SUCCESS,
  REGISTERING_FROM_NUTRITIONIST,
  REGISTER_FROM_NUTRITIONIST_FAILED,
  COMPLETE_REGISTER_FROM_NUTRITIONIST_SUCCESS,
  COMPLETING_REGISTER_FROM_NUTRITIONIST,
  COMPLETE_REGISTER_FROM_NUTRITIONIST_FAILED,
  GET_REFERER_ACCOUNTS,
  LOADING_REFERER_ACCOUNTS,
  FAILED_REFERER_ACCOUNTS,
  GET_USER_FROM_CODE,
  GET_USER_FROM_CODE_FAILURE,
  GET_USER_FROM_CODE_SUCCESS,
} from "./actionTypes";
import { NutritionistState } from "./types";

const initialState: NutritionistState = {
  nutritionist: null,
  nutritionistIsLoading: false,
  nutritionistErrorMessage: "",
  nutritionistAdding: false,
  nutritionistAddSuccessful: false,
  nutritionistAddErrorMessage: "",
  nutritionistDeleting: false,
  nutritionistDeleteSuccessful: false,
  nutritionistDeleteErrorMessage: "",
  registeringFromNutritionist: false,
  registerFromNutritionistSuccessful: false,
  registeredFromNutritionistAccount: null,
  registerFromNutritionistFailed: "",
  completingRegisterFromNutritionist: false,
  completeRegisterFromNutritionistSuccessful: false,
  completeRegisterFromNutritionistFailed: "",
  referer: null,
  refererIsLoading: false,
  refererErrorMessage: "",
  refererAccounts: null,
  refererAccountsErrorMessage: "",
  refererAccountsIsLoading: false,
  userFromCode: null,
  errorGettingUserFromCode: "",
};

export function NutritionistReducer(
  state = initialState,
  action: NutritionistActionTypes
): NutritionistState {
  switch (action.type) {
    case GET_USER_FROM_CODE_SUCCESS:
      return {
        ...state,
        userFromCode: action.userFromCode,
        errorGettingUserFromCode: "",
      };

    case GET_USER_FROM_CODE_FAILURE:
      return {
        ...state,
        errorGettingUserFromCode: action.error,
      };

    case LOADING_REFERER_ACCOUNTS:
      return {
        ...state,
        userFromCode: null,
        errorGettingUserFromCode: "",
      };

    case FAILED_REFERER_ACCOUNTS:
      return {
        ...state,
        userFromCode: null,
        errorGettingUserFromCode: action.error,
      };

    case GET_REFERER_ACCOUNTS:
      return {
        ...state,
        refererAccounts: action.refererAccounts,
        refererAccountsIsLoading: false,
      };

    case LOADING_REFERER_ACCOUNTS:
      return {
        ...state,
        refererAccountsIsLoading: true,
      };

    case FAILED_REFERER_ACCOUNTS:
      return {
        ...state,
        refererAccountsIsLoading: false,
        refererAccountsErrorMessage: action.error,
      };

    case NUTRITIONIST_LOAD:
      return {
        ...state,
        nutritionist: action.nutritionist,
        nutritionistIsLoading: false,
        nutritionistErrorMessage: "",
      };

    case NUTRITIONIST_LOADING:
      return {
        ...state,
        nutritionistIsLoading: true,
      };

    case NUTRITIONIST_LOAD_FAILED:
      return {
        ...state,
        nutritionistIsLoading: false,
        nutritionistErrorMessage: action.error,
      };

    case NUTRITIONIST_ADD:
      return {
        ...state,
        nutritionist: action.nutritionist,
        nutritionistAdding: false,
        nutritionistAddSuccessful: true,
        nutritionistAddErrorMessage: "",
      };

    case NUTRITIONIST_ADDING:
      return {
        ...state,
        nutritionistAdding: true,
      };

    case NUTRITIONIST_ADD_FAILED:
      return {
        ...state,
        nutritionistAdding: false,
        nutritionistAddSuccessful: false,
        nutritionistAddErrorMessage: action.error,
      };

    case NUTRITIONIST_DELETE:
      return {
        ...state,
        nutritionist: null,
        nutritionistDeleting: false,
        nutritionistDeleteSuccessful: true,
        nutritionistDeleteErrorMessage: "",
      };

    case NUTRITIONIST_DELETING:
      return {
        ...state,
        nutritionistDeleting: true,
      };

    case NUTRITIONIST_DELETE_FAILED:
      return {
        ...state,
        nutritionistDeleting: false,
        nutritionistDeleteSuccessful: false,
        nutritionistDeleteErrorMessage: action.error,
      };

    case REGISTER_FROM_NUTRITIONIST_SUCCESS:
      return {
        ...state,
        registeringFromNutritionist: false,
        registerFromNutritionistSuccessful: true,
        registeredFromNutritionistAccount: action.account,
        registerFromNutritionistFailed: "",
      };

    case REGISTERING_FROM_NUTRITIONIST:
      return {
        ...state,
        registeringFromNutritionist: true,
        registerFromNutritionistSuccessful: false,
        registeredFromNutritionistAccount: null,
        registerFromNutritionistFailed: "",
      };

    case REGISTER_FROM_NUTRITIONIST_FAILED:
      return {
        ...state,
        registeringFromNutritionist: false,
        registerFromNutritionistSuccessful: false,
        registeredFromNutritionistAccount: null,
        registerFromNutritionistFailed: action.error,
      };

    case COMPLETE_REGISTER_FROM_NUTRITIONIST_SUCCESS:
      return {
        ...state,
        completingRegisterFromNutritionist: false,
        completeRegisterFromNutritionistSuccessful: true,
        completeRegisterFromNutritionistFailed: "",
      };

    case COMPLETING_REGISTER_FROM_NUTRITIONIST:
      return {
        ...state,
        completingRegisterFromNutritionist: true,
        completeRegisterFromNutritionistSuccessful: false,
        completeRegisterFromNutritionistFailed: "",
      };

    case COMPLETE_REGISTER_FROM_NUTRITIONIST_FAILED:
      return {
        ...state,
        completingRegisterFromNutritionist: false,
        completeRegisterFromNutritionistSuccessful: false,
        completeRegisterFromNutritionistFailed: action.error,
      };

    case REFERER_LOAD:
      return {
        ...state,
        referer: action.referer,
        refererIsLoading: false,
      };

    case REFERER_LOADING:
      return {
        ...state,
        refererIsLoading: true,
      };

    case REFERER_LOAD_FAILED:
      return {
        ...state,
        refererIsLoading: false,
        refererErrorMessage: "",
      };

    default:
      return state;
  }
}
