import React from "react";
import WhiteLogo from "../../../assets/whiteLogo.png";
import mosaic from "../../../assets/auth-left-mosaic.png";
import { useHistory } from "react-router";
function AuthInfoPanel() {
  const history = useHistory()

  function goToHome() {
    history.push('/');
  }
  return (
    <div className="auth-partition-with-img">
      <img src={mosaic} className="auth-mosaic" alt=""/>
      <div className="content">
        <img src={WhiteLogo} className="logo" alt="MrGen logo" onClick={goToHome} />
        <h1 className="auth-info-title">
          Más conocimiento, mejor bienestar, mejor calidad de vida.
        </h1>
        <p>
          Accedé a tus resultados y navegá entre casi 300 alimentos para conocer si debes preferir, moderar o evitar su consumo.
        </p>
        <a onClick={() => history.push('/signup/?user=3')}>
          <p>Consultá aquí planes de empresa</p>
        </a>
      </div>
    </div>
  );
}

export default AuthInfoPanel;
