import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/config/types';
import { getPatients } from '../../../store/nutritionists/actionCreators';
import Diagnostics from '../../endUser/Diagnostics';
import { Header } from "../../common/Header";

const options = ["Mis pacientes", "Mi balance"];

function NutritionistResults({ history }: RouteComponentProps) {

  const dispatch = useDispatch();
  const analyses = useSelector((state: RootState) => state.analyses);
  console.log("analyses");
  console.log(analyses);

  return (
    <div className="dashboard">
      <Header/>
      <Diagnostics patientId={analyses.patientId} isNutritionist />
    </div>
  )
}

export default withRouter(NutritionistResults);
