export const zonasEnvio = {
    Montevideo: {
      localidades: {
        "Aguada": 70,
        "Atahualpa": 90,
        "Bañados de Carrasco": 90,
        "Barrio Sur": 70,
        "Brazo Oriental": 90,
        "Buceo": 70,
        "Capurro, Bella Vista, Arroyo Seco": 90,
        "Carrasco": 70,
        "Carrasco Norte": 70,
        "Castro Castellanos": 90,
        "Centro": 70,
        "Cerrito de la Victoria": 90,
        "Ciudad Vieja": 70,
        "Cordón": 70,
        "Flor de Maroñas": 90,
        "Ituzaingó": 90,
        "Jacinto Vera": 90,
        "La Blanqueada": 70,
        "La Comercial": 70,
        "La Figurita": 90,
        "Larrañaga": 70,
        "Las Canteras": 70,
        "Malvín": 70,
        "Malvín Norte": 70,
        "Maroñas, Parque Guaraní": 90,
        "Mercado Modelo, Bolívar": 90,
        "Palermo": 70,
        "Parque Batlle, Villa Dolores": 70,
        "Parque Rodó": 70,
        "Pocitos": 70,
        "Prado, Nueva Savona": 90,
        "Punta Carretas": 70,
        "Punta Gorda": 70,
        "Reducto": 90,
        "Tres Cruces": 70,
        "Unión": 70,
        "Villa Española": 90,
        "Villa Muñoz, Goes, Retiro": 70,
      },
    },
    Canelones: {
      localidades: {
        "Barra de Carrasco": 70,
        "Colonia Nicolich": 120,
        "Haras del Lago": 120,
        "Lagomar": 120,
        "Parque Carrasco": 120,
        "Paso Carrasco": 70,
        "San José de Carrasco": 120,
        "Shangrilá": 120,
      },
    },
  };