import React from "react";
import "./styles/app.scss";
import { BrowserRouter } from "react-router-dom";
import Main from "./components/Main";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./theme";
import { Provider } from "react-redux";
import { store } from "./store/config/configureStore";
import { WhatsAppIcon } from "./components/common/WhatsAppIcon";


function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Main />
            <WhatsAppIcon />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;

class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  };
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
