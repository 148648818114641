import { AnalysesState, Analysis, Direction } from "./types";
import {
  AnalysesActionTypes,
  ANALYSES_LOADING,
  ADD_ANALYSES,
  ANALYSES_FAILED,
  ADDING_ANALYSIS,
  ADD_ANALYSIS,
  ADD_ANALYSIS_FAILED,
  ANALYSIS_DIRECTIONS_LOADING,
  ADD_ANALYSIS_DIRECTIONS,
  ANALYSIS_DIRECTIONS_FAILED,
  ADD_ANALYSIS_DIRECTION_FAILED,
  ADD_ANALYSIS_DIRECTION,
  ADDING_ANALYSIS_DIRECTION,
  FETCHING_ANALYSIS_RESULT,
  FETCH_ANALYSIS,
  FETCH_ANALYSIS_FAILED,
  FETCHING_ANALYSIS_FOODS,
  FETCH_ANALYSIS_FOODS,
  FETCH_ANALYSIS_FOODS_FAILED,
  CHANGE_PATIENTID,
} from "./actionTypes";

const initialState: AnalysesState = {
  loading: false,
  analyses: null,
  errorMessage: "",
  analysisResult: [],
  analysisResultSuccess: false,
  analysisResultError: false,
  fetchingAnalysisResult: false,
  creatingAnalysis: false,
  createAnalysisSuccess: false,
  createAnalysisErrorMessage: "",
  gettingAnalysisDirections: false,
  analysisDirections: null,
  analysisDirectionsErrorMessage: "",
  creatingAnalysisDirection: false,
  createAnalysisDirectionSuccess: false,
  createAnalysisDirectionErrorMessage: "",
  analysisFoodResult: { foods: [], categories: [] },
  analysisFoodResultSuccess: false,
  analysisFoodResultError: false,
  fetchingAnalysisFoodResult: false,
  patientId: "",
};

export function analysesReducer(
  state = initialState,
  action: AnalysesActionTypes
): AnalysesState {
  switch (action.type) {
    case CHANGE_PATIENTID:
      return {
        ...state,
        patientId: action.id
      };

    case ANALYSES_LOADING:
      return {
        ...state,
        loading: true,
        analyses: null,
        errorMessage: "",
      };

    case ADD_ANALYSES:
      return {
        ...state,
        loading: false,
        analyses: action.analyses,
        errorMessage: "",
      };

    case ANALYSES_FAILED:
      return {
        ...state,
        loading: false,
        analyses: null,
        errorMessage: action.error,
      };

    case ADDING_ANALYSIS:
      return {
        ...state,
        creatingAnalysis: true,
        createAnalysisSuccess: false,
        createAnalysisErrorMessage: "",
      };

    case ADD_ANALYSIS:
      let newResults: Analysis[] | null = state.analyses;
      newResults?.push(action.analysis);

      return {
        ...state,
        creatingAnalysis: false,
        createAnalysisSuccess: true,
        createAnalysisErrorMessage: "",
        analyses: newResults,
      };

    case ADD_ANALYSIS_FAILED:
      return {
        ...state,
        creatingAnalysis: false,
        createAnalysisSuccess: false,
        createAnalysisErrorMessage: action.error,
      };

    case ANALYSIS_DIRECTIONS_LOADING:
      return {
        ...state,
        gettingAnalysisDirections: true,
        analysisDirections: null,
        analysisDirectionsErrorMessage: "",
      };

    case ADD_ANALYSIS_DIRECTIONS:
      return {
        ...state,
        gettingAnalysisDirections: false,
        analysisDirections: action.directions,
        analysisDirectionsErrorMessage: "",
      };

    case ANALYSIS_DIRECTIONS_FAILED:
      return {
        ...state,
        gettingAnalysisDirections: false,
        analysisDirections: null,
        analysisDirectionsErrorMessage: action.error,
      };

    case ADDING_ANALYSIS_DIRECTION:
      return {
        ...state,
        creatingAnalysisDirection: true,
        createAnalysisDirectionSuccess: false,
        createAnalysisDirectionErrorMessage: "",
      };

    case ADD_ANALYSIS_DIRECTION:
      let newDirections: Direction[] | null = state.analysisDirections;
      newDirections?.push(action.direction);

      return {
        ...state,
        creatingAnalysisDirection: false,
        createAnalysisDirectionSuccess: true,
        createAnalysisDirectionErrorMessage: "",
        analysisDirections: newDirections,
      };

    case ADD_ANALYSIS_DIRECTION_FAILED:
      return {
        ...state,
        creatingAnalysisDirection: false,
        createAnalysisDirectionSuccess: false,
        createAnalysisDirectionErrorMessage: action.error,
      };
    case FETCHING_ANALYSIS_RESULT:
      return {
        ...state,
        fetchingAnalysisResult: true,
        loading: true,
      };

    case FETCH_ANALYSIS:
      return {
        ...state,
        fetchingAnalysisResult: false,
        analysisResultError: false,
        analysisResultSuccess: true,
        analysisResult: action.results,
        loading: false,
      };

    case FETCH_ANALYSIS_FAILED:
      return {
        ...state,
        fetchingAnalysisResult: false,
        analysisResultError: true,
        analysisResultSuccess: false,
        errorMessage: action.error,
      };
    case FETCHING_ANALYSIS_FOODS:
      return {
        ...state,
        fetchingAnalysisFoodResult: true,
        loading: true,
      };

    case FETCH_ANALYSIS_FOODS:
      return {
        ...state,
        fetchingAnalysisFoodResult: false,
        analysisFoodResultError: false,
        analysisFoodResultSuccess: true,
        analysisFoodResult: action.foods,
        loading: false,
      };
    case FETCH_ANALYSIS_FOODS_FAILED:
      return {
        ...state,
        fetchingAnalysisFoodResult: false,
        analysisFoodResultError: true,
        analysisFoodResultSuccess: false,
        errorMessage: action.error,
      };

    default:
      return state;
  }
}
