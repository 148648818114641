import React, { useState, useEffect, ChangeEventHandler } from 'react';
import { RootState } from "../../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import { addInstrument } from "../../../store/instruments/actionCreators";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { userAccount } from "../../../store/auth/selector";
import roles from "../../../utils/roles";
import { logout } from "../../../store/auth/actionCreators";
import { LoadingAnimation } from "../../animations/LoadingAnimation";

const mapStateToProps = (state: RootState) => ({
    instruments: state.instruments,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<RootState, any, AppActions>
  ) => ({
    addInstrument: () => dispatch(addInstrument()),
  });

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;
  

function MealsPayment({ instruments, addInstrument, history }: PropsType) {
    const actualUser = useSelector((state: RootState) => userAccount(state));
    const isCompany = actualUser?.role === roles?.business;
    const dispatch = useDispatch();


    useEffect(() => {
        if (
          !instruments.addingInstrument &&
          !instruments.instrumentUrl &&
          instruments.addInstrumentErrorMessage === ""
        ) {
          addInstrument();
        }
      }, [
        instruments.addingInstrument,
        instruments.instrumentUrl,
        instruments.addInstrumentErrorMessage,
        addInstrument,
      ]);

    const loadingPlexo = (
        <div className="loading-payment">
            <LoadingAnimation />
            <p>
            En unos segundos serás redireccionado al sitio de Plexo para realizar el
            pago.
            </p>
        </div>
    );

    const plexoIframe = (
        <div className="iframe-container">
            <iframe
            height={700}
            width={700}
            src={instruments.instrumentUrl!}
            title="plexo"
            />
        </div>
    );

    function goBack() {
        dispatch(logout());
        history.push("/");
      }

    return (
        <>
            {instruments.instrumentUrl ? plexoIframe : loadingPlexo}
        </>
    )
}

export default connector(withRouter(MealsPayment));