import React, { useEffect, useState } from "react";
import { Step } from "../../common/ArrowSteps/types";
import { ArrowSteps } from "../../common/ArrowSteps";
import KitCoordination, { CoordinationType } from "./KitCoordination";
import KitConfirmation from "./KitConfirmation";
import KitPickup from "./KitPickup";
import TestResults from "./TestResults";
import ShippingSelection from "./ShippingSelection";
import {
  Distribution,
  Analysis,
  KitDistribution,
  Address,
} from "../../../store/analyses/types";
import { analysesService } from "../../../services/analyses.service";
import states from "../../../utils/states";
import { Address as SelectableAddress } from "./AddressSelector/types";
import { SelectableDate } from "./DateSelector/DateSelector";
import moment from "moment";
import { LoadingAnimation } from "../../animations/LoadingAnimation";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/config/types";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { userAccount, userRole } from "../../../store/auth/selector";
import roles from "../../../utils/roles";

const steps: Step[] = [
  {
    presentTitle: "Primer paso",
    name: "Forma de envío",
  },
  {
    presentTitle: "Segundo paso",
    name: "Coordinar Kit ",
  },
  {
    presentTitle: "Tercer paso",
    name: "Confirmacion",
  },
  {
    presentTitle: "Cuarto paso",
    name: "Forma de retiro",
  },
  {
    presentTitle: "Quinto paso",
    name: "Coordinar Retiro",
  },
  {
    presentTitle: "Sexto paso",
    name: "Resultados",
  },
];

enum ShippingType {
  DELIVERY,
  PICKUP,
}

// distribution type:
// 0: delivery
// 1: pickup

// 1 distribution con 2 kit distribution: 2 envios a la misma address
// 2 distributions, 1 con kit distribution y una sin: 1 envio montevideo y 1 pickup
// 2 distributions sin kit distributions: 2 pickup

function UserDashboard({ history }: RouteComponentProps) {
  const [activeStep, setActiveStep] = useState(0);
  const [distributionInfo, setDistributionInfo] = useState<Analysis>();
  const [isLoading, setIsLoading] = useState(false);

  const role = useSelector((state: RootState) => userRole(state));
  const isFinalUser = role === roles.user;

  const actualUser = useSelector((state: RootState) => userAccount(state));

  async function getKitDistribution() {
    try {
      setIsLoading(true);
      const res = await analysesService.getKitDistribution();
      setDistributionInfo(res);
      setIsLoading(false);
      return res;
    } catch {
      setDistributionInfo(undefined);
    }
  }
  useEffect(() => {
    getKitDistribution();
  }, []);

  useEffect(() => {
    if (distributionInfo?.status === 8) {
      history.push("/home");
    }
    if(!distributionInfo){
      setActiveStep(0);
      return;
    }
    if (distributionInfo.returnDistribution || distributionInfo.status == 2 ) {
      setActiveStep(5);
      return;
    }
    if (distributionInfo.pickupDistribution) {
      setActiveStep(2);
      return;
    }
  }, [distributionInfo]);

  async function addDistribution(
    distribution: Distribution
  ): Promise<Distribution> {
    setIsLoading(true);
    await analysesService.addDistribution(distribution);
    const savedDistribution = await getKitDistribution();
    const returnDistribution = savedDistribution.distributions.find(
      (x) =>
        x.address?.department == distribution.address?.department &&
        x.address?.city == distribution.address?.city &&
        x.address?.apartmentNumber == distribution.address?.apartmentNumber &&
        x.address?.street == distribution.address?.street &&
        x.address?.doorNumber == distribution.address?.doorNumber
    );
    return returnDistribution;
  }

  async function saveKitAndDistribution(
    distribution,
    date,
    typeDelivery,
    nextStep
  ) {
    setIsLoading(true);
    try {
      let dist =
        distributionInfo?.distributions[
          distributionInfo.distributions.length - 1
        ];
      if (distribution.address?.new || !distribution.address || !dist) {
        dist = await addDistribution(distribution);
      }
      const addKitDistributionData: KitDistribution = {
        distributionId: dist.id,
        typeDelivery: typeDelivery,
        date: date ? moment(date.date).toJSON() : undefined,
        schedule: date ? (date.id as 0 | 1) : undefined,
      };
      await analysesService.addKitDistribution(addKitDistributionData);
      await getKitDistribution();
      setActiveStep(nextStep);
    } catch (e){
      console.log(e);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }

  async function submitKitDistribution(
    date: SelectableDate,
    shippingType: ShippingType,
    address: SelectableAddress & { new: boolean },
    typeDelivery: 1 | 2,
    nextStep: number
  ) {
    const distribution: Distribution = {
      type: shippingType,
    };

    if (shippingType === ShippingType.PICKUP) {
      saveKitAndDistribution(distribution, date, typeDelivery, nextStep);
    } else {
      distribution.address = {
        street: address?.street,
        city: "",
        doorNumber: address?.doorNumber,
        streetCorner: address?.corner,
        apartmentNumber: address?.apartmentNumber,
        department: states[address?.state].value,
        new: address?.new,
      };
      saveKitAndDistribution(distribution, date, typeDelivery, nextStep);
    }
  }
  
    if (isLoading) { 
      return (
        <div className="loading-container" style={{width:'100%', height:'100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
          <LoadingAnimation />
        </div>
      )
    } else {

      return (
        <div className="user-dashboard">
          <ArrowSteps activeStep={activeStep} steps={steps} />
          {isLoading ? (
            <div className="loading-container" style={{width:'100%', height:'100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
              <LoadingAnimation />
            </div>
          ) : (
            <>
              {activeStep < 3 ? (
                <DeliveryFlow
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  distributionInfo={distributionInfo}
                  submitKitDistribution={submitKitDistribution}
                />
              ) : null}
              {activeStep >= 3 ? (
                <PickupFlow
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  distributionInfo={distributionInfo}
                  submitKitDistribution={submitKitDistribution}
                />
              ) : null}
            </>
          )}
        </div>
      )
    }
}

function PickupFlow({
  activeStep,
  setActiveStep,
  distributionInfo,
  submitKitDistribution,
}: {
  distributionInfo: Analysis;
  activeStep: any;
  setActiveStep: any;
  submitKitDistribution: any;
}) {
  const [date, setDate] = useState<SelectableDate>();
  const [address, setAddress] = useState<SelectableAddress>();
  const account = useSelector((s: RootState) => s.auth.account);
  const [isLoading, setIsLoading] = useState(false);
  const [shippingType, setShippingType] = useState();

  console.log("activestep", activeStep);

  useEffect(() => {
    if (distributionInfo?.pickupDistribution?.address) {
      const addr: any = distributionInfo?.pickupDistribution?.address;
      addr.state = states.find((x) => x.value === addr.department)?.id;
      setAddress(addr);
    }
  }, []);

  async function addKitPickupDistribution() {
    const distributionAddress = distributionInfo?.pickupDistribution?.address;
    const addressesAreEqual =
      address &&
      address?.street == distributionAddress?.street &&
      address?.doorNumber == distributionAddress?.doorNumber;

    if (addressesAreEqual) {
      submitKitDistribution(date, shippingType, distributionAddress, 2, 5);
    } else {
      submitKitDistribution(
        date,
        shippingType,
        { ...address, new: true },
        2,
        5
      );
    }
    return;
  }

  return (
    <>
      {isLoading ? (
        <div className="loading-container" style={{width:'100%', height:'100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
          <LoadingAnimation />
        </div>
      ) : (
        <>
          {activeStep === 3 ? (
            <ShippingSelection
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setShippingType={setShippingType}
              name={account.profile.firstName}
              isDelivery={false}
              submitKitDistribution={addKitPickupDistribution}
            />
          ) : null}
          {activeStep === 4 ? (
            <KitCoordination
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              date={date}
              setDate={setDate}
              address={address}
              type={CoordinationType.PICKUP}
              setAddress={setAddress}
              name={account.profile.firstName}
              submitKitDistribution={addKitPickupDistribution}
            />
          ) : null}
          {activeStep === 5 ? (
            <KitConfirmation
              kitDeliveryInfo={distributionInfo}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              date={date}
              name={account.profile.firstName}
            />
          ) : null}
        </>
      )}
    </>
  );
}

function DeliveryFlow({
  activeStep,
  setActiveStep,
  distributionInfo,
  submitKitDistribution,
}) {
  const [shippingType, setShippingType] = useState();
  const [date, setDate] = useState<SelectableDate>();
  const [address, setAddress] = useState<SelectableAddress>();
  const account = useSelector((s: RootState) => s.auth.account);

  return (
    <>
      {activeStep === 0 ? (
        <ShippingSelection
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setShippingType={setShippingType}
          name={account.profile.firstName}
          submitKitDistribution={() =>
            submitKitDistribution(date, shippingType, address, 1, 2)
          }
          isDelivery
        />
      ) : null}
      {activeStep === 1 ? (
        <KitCoordination
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          date={date}
          setDate={setDate}
          address={address}
          type={CoordinationType.DELIVERY}
          setAddress={setAddress}
          name={account.profile.firstName}
          submitKitDistribution={() =>
            submitKitDistribution(date, shippingType, address, 1, 2)
          }
        />
      ) : null}
      {activeStep === 2 ? (
        <KitConfirmation
          kitDeliveryInfo={distributionInfo}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          date={date}
          name={account.profile.firstName}
        />
      ) : null}
    </>
  );
}

export default withRouter(UserDashboard);
