import React from "react";
import { Snackbar, makeStyles, Theme, createStyles } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as styleVars from "../../../styles/_variables.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbar: {
      width: "100%",
      margin: 0,
      top: 0,
      right: 0,
      height: "100px",
      alignItems: "center",
      justifyContent: "center",
      color: styleVars.darkSecondaryText,
      fontWeight: "bold",
      [theme.breakpoints.down('xs')]: {
        left: 0,
      }
    },
    content: {
      display: "flex",
      alignItems: "Center",
      "& p": {
        marginLeft: "15px",
      },
      [theme.breakpoints.down('xs')]: {
        margin: "0 15px",
      }
    },
  })
);

interface FullWidthSnackbarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  text: string;
  success: boolean;
}

function FullWidthSnackbar({ open, setOpen, text, success }: FullWidthSnackbarProps) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      className={classes.snackbar}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      style={{backgroundColor: success ? styleVars.success : styleVars.warning}}
      autoHideDuration={9000}
      resumeHideDuration={9000}
    >
      <div className={classes.content}>
        {success ? <CheckCircleIcon /> : <WarningIcon />}
        <p>{text}</p>
      </div>
    </Snackbar>
  );
}

export default FullWidthSnackbar;
