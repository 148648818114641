import React from "react";
import { FormSelect } from "../forms/FormSelect";
import { FormTextField } from "../forms/FormTextField";
import { Field } from "formik";

interface UserDataFieldsProps {
  speech: string;
}

function UserDataFields({ speech }: UserDataFieldsProps) {
  return (
    <div className="your-data">
      <p className="title">{speech}</p>
      <div>Estás a solo un paso de descubrir cómo tu ADN puede mejorar tu salud y bienestar. Completá la información y empezá tu viaje hacia una vida más saludable.</div>
      <div className="horizontal-line">
        <Field
          className="field"
          name="name"
          component={FormTextField}
          type="text"
          placeholder="Nombre"
          maxLength={50}
          inputProps={{
            autocomplete: "off" 
          }}

        />
        <Field
          className="field"
          name="surname"
          component={FormTextField}
          type="text"
          placeholder="Apellido"
          inputProps={{
            autocomplete: "off"  
          }}
        />
      </div>
      
      <div className="horizontal-line">
        <Field
          className="field"
          name="email"
          component={FormTextField}
          type="email"
          validateOnChange={true}
          placeholder="Email"
        />
        <Field
          className="field"
          name="phone"
          component={FormTextField}
          type="number"
          maxLength={10}
          pattern="\d*"
          validateOnChange={true}
          placeholder="Celular"
        />
      </div>
      {/* <div className="horizontal-line"> */}
        {/* birthdate */}
        {/* <Field
          className="field"
          name="gender"
          component={FormSelect}
          label="Género"
          options={[
            { id: 0, value: "Masculino" },
            { id: 1, value: "Femenino" },
          ]}
        /> */}
      {/* </div> */}
      {/* height and weight */}
    </div>
  );
}

export default UserDataFields;

/* <Field
          className="field"
          name="date"
          component={//FormTextField}
          type="date"
          placeholder="Fecha de nacimiento"
        /> */


/* <div className="horizontal-line">
        <Field
          className="field"
          name="height"
          component={//FormTextField}
          type="number"
          min="30"
          max="230"
          validateOnChange={true}
          placeholder="Estatura (centímetros)"
        />
        <Field
          className="field"
          name="weight"
          component={//FormTextField}
          min="3"
          max="270"
          type="number"
          validateOnChange={true}
          placeholder="Peso (kilogramos)"
        />
      </div> */