import { httpGet } from "./base.service";
import { Cost } from "../store/costs/types";
import { endpoints } from "./endpoints";

export const costsService = {
  fetchCosts,
};

async function fetchCosts(): Promise<Cost[] | undefined> {
  return await httpGet<Cost[]>(`${endpoints["costs"]}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
