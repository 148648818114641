import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Button, Paper } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { PaymentMethodFields } from "../../common/PaymentMethodFields";
import { userAccount, editSuccess } from "../../../store/auth/selector";
import { updateProfile } from "../../../store/auth/actionCreators";
import { RootState } from "../../../store/config/types";
import { FullWidthSnackbar } from "../../common/FullWidthSnackbar";
import { LoadingAnimation } from "../../animations/LoadingAnimation";

interface Values {
  bank: string;
  accountNumber: string;
  branchOffice: string;
  fullName: string;
  collectionNetwork: string[];
  ci: string;
}

function PaymentDetails() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const userData = useSelector((state: RootState) => userAccount(state));
  const editOk = useSelector((state: RootState) => editSuccess(state));
  const isLoading = useSelector(
    (state: RootState) => state.auth.gettingAccount
  );
  const submitPaymentDetails = (values: Values) => {

    if (values.bank && values.bank !== null) {
      let bankAccountNewData = {
        name: values.bank,
        number: values.accountNumber,
        bankBranch: values.branchOffice,
      };
      userData.nutritionist.bankAccount = bankAccountNewData;
    } else {
      userData.nutritionist.cardIdentification = values.ci;
    }

    userData.nutritionist.paymentName = values.fullName;
    userData.nutritionist.paymentType =
      values.collectionNetwork[0] === "Redpagos" ? 3 : 1;

    dispatch(updateProfile(userData));
  };

  useEffect(() => {
    if (editOk) {
      setOpen(true);
    }
  }, [editOk]);

  return (
    userData &&
    userData.nutritionist && (
      <Paper elevation={1} classes={{ root: "dashboard-content" }}>
        <Formik
          initialValues={{
            bank: userData.nutritionist.bankAccount
              ? userData.nutritionist.bankAccount.name
              : "",
            accountNumber: userData.nutritionist.bankAccount
              ? userData.nutritionist.bankAccount.number
              : "",
            branchOffice: userData.nutritionist.bankAccount
              ? userData.nutritionist.bankAccount.bankBranch
              : "",
            fullName: userData.nutritionist.paymentName,
            collectionNetwork:
              userData.nutritionist.paymentType === 3 ? ["Redpagos"] : [],
            ci: userData.nutritionist.cardIdentification,
          }}
          onSubmit={submitPaymentDetails}
        >
          {({ values }) => {
            let collectionNetworkSelected = values.collectionNetwork.length > 0;

            return (
              <Form className="form">
                <div className="profile-form-data">
                  <p className="subtitle">
                    Para poder cobrar debes identificarnos la forma que más te
                    quede cómoda.
                  </p>
                  {isLoading ? (
                    <LoadingAnimation />
                  ) : (
                    <>
                      <PaymentMethodFields
                        collectionNetworkSelected={collectionNetworkSelected}
                      />
                      <div className="buttons">
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          disableElevation
                        >
                          Guardar cambios
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
        {editOk && (
          <FullWidthSnackbar
            open={open}
            setOpen={setOpen}
            success
            text={"Cuenta editada correctamente"}
          />
        )}
      </Paper>
    )
  );
}

export default PaymentDetails;
