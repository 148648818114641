import { Analysis, AnalysisResult, Direction, FoodsResponse, FoodsResult } from "./types";

export const ANALYSES_LOADING = "ANALYSES_LOADING";
export const ADD_ANALYSES = "ADD_ANALYSES";
export const ANALYSES_FAILED = "ANALYSES_FAILED";

export interface RequestAnalysesAction {
  type: typeof ANALYSES_LOADING;
}

export interface AddAnalysesAction {
  type: typeof ADD_ANALYSES;
  analyses: Analysis[];
}

export interface AnalysesFailedAction {
  type: typeof ANALYSES_FAILED;
  error: string;
}

export const FETCHING_ANALYSIS_RESULT = "FETCHING_ANALYSIS_RESULT";
export const FETCH_ANALYSIS = "FETCH_ANALYSIS";
export const FETCH_ANALYSIS_FAILED = "FETCH_ANALYSIS_FAILED";

export interface FetchAnalysisResult {
  type: typeof FETCHING_ANALYSIS_RESULT;
}

export interface FetchAnalysis {
  type: typeof FETCH_ANALYSIS;
  results: AnalysisResult[];
}

export interface FetchAnalysisFailed {
  type: typeof FETCH_ANALYSIS_FAILED;
  error: string;
}

export const FETCHING_ANALYSIS_FOODS = "FETCHING_ANALYSIS_FOODS";
export const FETCH_ANALYSIS_FOODS = "FETCH_ANALYSIS_FOODS";
export const FETCH_ANALYSIS_FOODS_FAILED = "FETCH_ANALYSIS_FOODS_FAILED";

export interface FetchAnalysisFoods {
  type: typeof FETCHING_ANALYSIS_FOODS;
}

export interface FetchAnalysisFoodsResponse {
  type: typeof FETCH_ANALYSIS_FOODS;
  foods: FoodsResponse;
}

export interface FetchAnalysisFoodsFailed {
  type: typeof FETCH_ANALYSIS_FOODS_FAILED;
  error: string;
}

export const ADDING_ANALYSIS = "ADDING_ANALYSIS";
export const ADD_ANALYSIS = "ADD_ANALYSIS";
export const ADD_ANALYSIS_FAILED = "ADD_ANALYSIS_FAILED";

export interface RequestAddAnalysisAction {
  type: typeof ADDING_ANALYSIS;
}

export interface AddAnalysisAction {
  type: typeof ADD_ANALYSIS;
  analysis: Analysis;
}

export interface AddAnalysisFailedAction {
  type: typeof ADD_ANALYSIS_FAILED;
  error: string;
}

export const ANALYSIS_DIRECTIONS_LOADING = "ANALYSIS_DIRECTIONS_LOADING";
export const ADD_ANALYSIS_DIRECTIONS = "ADD_ANALYSIS_DIRECTIONS";
export const ANALYSIS_DIRECTIONS_FAILED = "ANALYSIS_DIRECTIONS_FAILED";

export interface RequestAnalysisDirectionsAction {
  type: typeof ANALYSIS_DIRECTIONS_LOADING;
}

export interface AddAnalysisDirectionsAction {
  type: typeof ADD_ANALYSIS_DIRECTIONS;
  directions: Direction[];
}

export interface AnalysisDirectionsFailedAction {
  type: typeof ANALYSIS_DIRECTIONS_FAILED;
  error: string;
}

export const ADDING_ANALYSIS_DIRECTION = "ADDING_ANALYSIS_DIRECTION";
export const ADD_ANALYSIS_DIRECTION = "ADD_ANALYSIS_DIRECTION";
export const ADD_ANALYSIS_DIRECTION_FAILED = "ADD_ANALYSIS_DIRECTION_FAILED";

export interface RequestAddAnalysisDirectionAction {
  type: typeof ADDING_ANALYSIS_DIRECTION;
}

export interface AddAnalysisDirectionAction {
  type: typeof ADD_ANALYSIS_DIRECTION;
  direction: Direction;
}

export interface AddAnalysisDirectionFailedAction {
  type: typeof ADD_ANALYSIS_DIRECTION_FAILED;
  error: string;
}

export const CHANGE_PATIENTID = "CHANGE_PATIENTID";

export interface ChangePatientIdAction {
  type: typeof CHANGE_PATIENTID;
  id: string;
}

export type AnalysesActionTypes =
  | RequestAnalysesAction
  | AddAnalysesAction
  | AnalysesFailedAction
  | RequestAddAnalysisAction
  | AddAnalysisAction
  | AddAnalysisFailedAction
  | RequestAnalysisDirectionsAction
  | AddAnalysisDirectionsAction
  | AnalysisDirectionsFailedAction
  | RequestAddAnalysisDirectionAction
  | AddAnalysisDirectionAction
  | AddAnalysisDirectionFailedAction
  | FetchAnalysisResult
  | FetchAnalysis
  | FetchAnalysisFailed
  | FetchAnalysisFoods
  | FetchAnalysisFoodsResponse
  | FetchAnalysisFoodsFailed
  | ChangePatientIdAction
  ;
