import { BusinessActionTypes, FAILED_EMPLOYES_ACCOUNTS, GET_EMPLOYES_ACCOUNTS, LOADING_BUSINESS, LOADING_EMPLOYES_ACCOUNTS, LOAD_BUSINESS, LOAD_BUSINESS_FAILED } from "./actionTypes";
import { Dispatch } from "redux";
import { Business } from "./types";
import { businessService } from '../../services/businesses.service';

//#region Fetch business

export const fetchBusiness = () => {
  return (dispatch: Dispatch<BusinessActionTypes>) => {
    dispatch(loadingBusiness());

    //TODO: Call backend
    setTimeout(() => {
      dispatch(loadBuisnessSuccess({ name: "Mr. Gen", rut: "123456789012", avatar: "https://res.cloudinary.com/dj29j7ziz/image/upload/v1555347817/iconmonstr-cat-3-120.png" }));
    }, 3000);
  };
};

const loadingBusiness = (): BusinessActionTypes => ({
  type: LOADING_BUSINESS,
});

const loadBuisnessSuccess = (business: Business): BusinessActionTypes => ({
  type: LOAD_BUSINESS,
  business,
});

const loadBusinessFailed = (error: string): BusinessActionTypes => ({
  type: LOAD_BUSINESS_FAILED,
  error,
});


export const getEmployes = () => {
  return (dispatch: Dispatch<BusinessActionTypes>) => {
    dispatch({
      type: LOADING_EMPLOYES_ACCOUNTS,
    });

    businessService.getEmployes().then(
      (response) => {
        dispatch({
          type: GET_EMPLOYES_ACCOUNTS,
          employesAccounts: response!,
        });
      },
      (error) => {
        dispatch({
          type: FAILED_EMPLOYES_ACCOUNTS,
          error: error,
        });
      }
    );
  };
};


