import React from "react";
import { makeStyles, StepIconProps } from "@material-ui/core";
import * as styleVars from "../../../../styles/_variables.scss";

const useStepIconStyles = makeStyles({
  root: {
    color: styleVars.darkBackground,
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: styleVars.darkPrimary,
  },
  circle: {
    width: 16,
    height: 16,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  activeCircle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `3px solid ${styleVars.darkPrimary}`,
    backgroundClip: "content-box",
    backgroundColor: styleVars.darkPrimary,
    borderRadius: "50%",
    padding: 3,
    color: styleVars.white,
    zIndex: 1,
    height: 24,
    width: 24,
    fontSize: 14,
  },
  completed: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: styleVars.darkPrimary,
    borderRadius: "50%",
    padding: 5,
    color: styleVars.darkBackground,
    zIndex: 1,
    height: 16,
    width: 16,
    fontSize: 14,
  },
});

function StepIcon(props: StepIconProps) {
  const classes = useStepIconStyles();
  const { active, completed } = props;

  return (
    <div className={`${classes.root} ${active ? classes.active : ""}`}>
      {completed ? (
        <div className={classes.completed}>{props.icon}</div>
      ) : active ? (
        <div className={classes.activeCircle}>{props.icon}</div>
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

export default StepIcon;
