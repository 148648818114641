import React from 'react'

import { Paper } from "@material-ui/core";

function ResultCard({ title, content }) {
  return (
    <Paper className="result-card">
      <h1>{title}</h1>
      {content}
    </Paper>
  );
}

export default ResultCard;
