import { User } from "./types";

export const USERS_LOAD = "USERS_LOAD";
export const USERS_LOADING = "USERS_LOADING";
export const USERS_LOAD_FAILED = "USERS_LOAD_FAILED";

export const USERS_ADD = "USERS_ADD";
export const USERS_ADDING = "USERS_ADDING";
export const USERS_ADD_FAILED = "USERS_ADD_FAILED";

export const CHANGE_AVATAR = "CHANGE_AVATAR";

export interface ChangeAvatarAction { 
  type: typeof CHANGE_AVATAR;
  avatar: any;
}
export interface UsersLoadAction {
  type: typeof USERS_LOAD;
  users: User[];
}
export interface UsersLoadingAction {
  type: typeof USERS_LOADING;
}
export interface UsersLoadFailedAction {
  type: typeof USERS_LOAD_FAILED;
  error: string;
}

export interface UsersAddAction {
  type: typeof USERS_ADD;
  user: User;
}
export interface UsersAddingAction {
  type: typeof USERS_ADDING;
}
export interface UsersAddFailedAction {
  type: typeof USERS_ADD_FAILED;
  error: string;
}

export type UsersActionTypes =
  | UsersLoadAction
  | UsersLoadingAction
  | UsersLoadFailedAction
  | UsersAddAction
  | UsersAddingAction
  | UsersAddFailedAction
  | ChangeAvatarAction;
