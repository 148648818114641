import { Dispatch } from "redux";
import {
  ADD_INSTRUMENTS,
  INSTRUMENTS_LOADING,
  INSTRUMENTS_FAILED,
  FetchInstrumentsActionTypes,
  AddInstrumentActionTypes,
  ADDING_INSTRUMENT,
  ADD_INSTRUMENT_URL,
  ADD_INSTRUMENT_FAILED,
} from "./actionTypes";
import { Instrument } from "./types";
import { instrumentsService } from "../../services/instruments.service";

//#region Add instrument

export const addInstrument = () => {
  return (dispatch: Dispatch<AddInstrumentActionTypes>) => {
    dispatch(addingInstrument());

    return instrumentsService.addInstrument().then(
      (response) => {
        dispatch(addInstrumentUrl(response!));
      },
      (error) => {
        dispatch(addInstrumentFailed(error));
      }
    );
  };
};

const addingInstrument = (): AddInstrumentActionTypes => ({
  type: ADDING_INSTRUMENT,
});

const addInstrumentUrl = (url: string): AddInstrumentActionTypes => ({
  type: ADD_INSTRUMENT_URL,
  url,
});

const addInstrumentFailed = (error: string): AddInstrumentActionTypes => ({
  type: ADD_INSTRUMENT_FAILED,
  error,
});

//#endregion

//#region Fetch instruments

export const fetchInstruments = () => {
  return (dispatch: Dispatch<FetchInstrumentsActionTypes>) => {
    dispatch(loadingInstruments());

    return instrumentsService.fetchInstruments().then(
      (response) => {
        dispatch(addInstruments(response!));
      },
      (error) => {
        dispatch(addInstrumentsFailed(error));
      }
    );
  };
};

const loadingInstruments = (): FetchInstrumentsActionTypes => ({
  type: INSTRUMENTS_LOADING,
});

const addInstruments = (instruments: Instrument[]): FetchInstrumentsActionTypes => ({
  type: ADD_INSTRUMENTS,
  instruments,
});

const addInstrumentsFailed = (error: string): FetchInstrumentsActionTypes => ({
  type: INSTRUMENTS_FAILED,
  error,
});

//#endregion
