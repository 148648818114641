import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, CircularProgress, useMediaQuery, useTheme } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";

interface PopupProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    textAlign: 'center',
    fontSize: '2.2em',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4em",
      marginTop: '20px'
    },
  },
  completedText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    listStyle: "none",
  },
  tick: {
    color: "green",
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
    },
  },
  spinner: {
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
    },
  },
}));

const Popup: React.FC<PopupProps> = ({ open, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const texts = [
    "Registrando usuario...",
    "Iniciando sesión...",
    "Cargando medios de pago...",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (open) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          if (prevIndex + 1 < texts.length) {
            return prevIndex + 1;
          } else {
            clearInterval(interval);
            return prevIndex;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [open]);

  return (
    <Dialog 
      open={open} 
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}
      fullScreen={fullScreen}
      PaperProps={{
        style: {
          width: fullScreen ? '100%' : '800px',
          height: fullScreen ? '100%' : '450px',
          margin: 'auto',
          position: 'relative',
          paddingTop: '20px',
          paddingBottom: '20px',
          borderRadius: '20px'
        }
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle></DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ul>
          {texts.map((text, index) => (
            <li style={{listStyle:'none'}} key={index} className={index <= currentIndex ? classes.completedText : ""}>
              {text}    
              {index <= currentIndex && <CheckCircleIcon className={classes.tick} />}
            </li>
          ))}
        </ul>
        <CircularProgress className={classes.spinner} />
      </DialogContent>
    </Dialog>
  );
}

export default Popup;
