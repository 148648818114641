import React from "react";
import { FormCheckbox } from "../forms/FormCheckbox";
import { Field } from "formik";

function FamilyBackgroundFields() {
  return (
    <div className="checkboxes">
      <p className="title">Antecedentes familiares</p>
      <Field name="familyBackground" value="70d167b9-f679-4ab8-9216-849cc3e9e5f6" component={FormCheckbox} label="Hipertensión" />
      <Field name="familyBackground" value="9ee8ed18-5b92-4f9b-8423-61fc07766567" component={FormCheckbox} label="Diabetes" />
      <Field name="familyBackground" value="87615a03-61ca-4bed-a337-c9f7473f0922" component={FormCheckbox} label="Colesterol alto" />
      <Field name="familyBackground" value="8231558a-4b68-4646-b45f-0ddb37f73add" component={FormCheckbox} label="Nivel triglicéridos" />
      <Field name="familyBackground" value="56bc920b-17c3-4807-a6f4-d300caef384c" component={FormCheckbox} label="Sobrepeso" />
      <Field name="familyBackground" value="d33a4480-8569-454b-9a25-092071870e00" component={FormCheckbox} label="Osteoporosis" />
      <Field name="familyBackground" value="d08e677a-5421-49c5-97eb-0815f1d1c038" component={FormCheckbox} label="Alergias" />
      <Field name="familyBackground" value="1fb4a49d-9dc2-4343-ba48-a0df7836d5d1" component={FormCheckbox} label="Intolerancia al gluten" />
      <Field name="familyBackground" value="ec5178d3-b0ba-4e4d-9d7e-d1f87fc6634d" component={FormCheckbox} label="Intolerancia a lácteos" />
    </div>
  );
}

/*
Id                                  |Name                  |
------------------------------------|----------------------|
70d167b9-f679-4ab8-9216-849cc3e9e5f6|Hipertensión          |*
9ee8ed18-5b92-4f9b-8423-61fc07766567|Diabetes              |*
87615a03-61ca-4bed-a337-c9f7473f0922|Colesterol            |*
8231558a-4b68-4646-b45f-0ddb37f73add|Triglicéridos         |*
1fb4a49d-9dc2-4343-ba48-a0df7836d5d1|Intolerancia Gluten   |
ec5178d3-b0ba-4e4d-9d7e-d1f87fc6634d|Intolerancia a Lácteos|
56bc920b-17c3-4807-a6f4-d300caef384c|Sobrepeso             |*
d08e677a-5421-49c5-97eb-0815f1d1c038|Alergias              |*
d33a4480-8569-454b-9a25-092071870e00|Osteoporosis          |*
*/

export default FamilyBackgroundFields;
