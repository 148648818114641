import {
  NutritionistActionTypes,
  NUTRITIONIST_LOAD,
  NUTRITIONIST_LOADING,
  NUTRITIONIST_LOAD_FAILED,
  NUTRITIONIST_ADD,
  NUTRITIONIST_ADDING,
  NUTRITIONIST_ADD_FAILED,
  NUTRITIONIST_DELETE,
  NUTRITIONIST_DELETING,
  NUTRITIONIST_DELETE_FAILED,
  REFERER_LOAD,
  REFERER_LOADING,
  REFERER_LOAD_FAILED,
  REGISTERING_FROM_NUTRITIONIST,
  REGISTER_FROM_NUTRITIONIST_SUCCESS,
  REGISTER_FROM_NUTRITIONIST_FAILED,
  COMPLETE_REGISTER_FROM_NUTRITIONIST_SUCCESS,
  COMPLETING_REGISTER_FROM_NUTRITIONIST,
  COMPLETE_REGISTER_FROM_NUTRITIONIST_FAILED,
  GET_REFERER_ACCOUNTS,
  LOADING_REFERER_ACCOUNTS,
  FAILED_REFERER_ACCOUNTS,
  GET_USER_FROM_CODE_SUCCESS,
  GET_USER_FROM_CODE_FAILURE,
  GET_USER_FROM_CODE,
} from './actionTypes';
import {
  Nutritionist,
  AssociateNutritionistRequest,
  NewAccountInfo,
  RegisterFromNutritionistRequest,
} from './types';
import {User} from '../users/types';
import {Dispatch} from 'redux';
import {nutritionistsService} from '../../services/nutritionists.service';
import {Account} from '../auth/types';

export const getUserFromCode = (code: string) => {
  return (dispatch: Dispatch<NutritionistActionTypes>) => {
    dispatch({
      type: GET_USER_FROM_CODE,
    });

    nutritionistsService.getUserFromCodeService(code).then(
      (response) => {
        dispatch({
          type: GET_USER_FROM_CODE_SUCCESS,
          userFromCode: response!,
        });
      },
      (error) => {
        dispatch({
          type: GET_USER_FROM_CODE_FAILURE,
          error: error,
        });
      }
    );
  };
};

//#region Associate nutritionist

export const associateNutritionist = ({code}: AssociateNutritionistRequest) => {
  return (dispatch: Dispatch<NutritionistActionTypes>) => {
    dispatch(addingNutritionist());

    const associateNutritionistRequest: AssociateNutritionistRequest = {
      code,
    };

    nutritionistsService
      .associateNutritionist(associateNutritionistRequest)
      .then(
        (response) => {
          dispatch(addNutritionist(response!));
        },
        (error) => {
          addNutritionistFailed(error);
        }
      );
  };
};

const addNutritionist = (
  nutritionist: Nutritionist
): NutritionistActionTypes => ({
  type: NUTRITIONIST_ADD,
  nutritionist,
});

const addingNutritionist = (): NutritionistActionTypes => ({
  type: NUTRITIONIST_ADDING,
});

const addNutritionistFailed = (error: string): NutritionistActionTypes => ({
  type: NUTRITIONIST_ADD_FAILED,
  error,
});

//#endregion

export const getPatients = () => {
  return (dispatch: Dispatch<NutritionistActionTypes>) => {
    dispatch({
      type: LOADING_REFERER_ACCOUNTS,
    });

    nutritionistsService.getPatients().then(
      (response) => {
        dispatch({
          type: GET_REFERER_ACCOUNTS,
          refererAccounts: response!,
        });
      },
      (error) => {
        dispatch({
          type: FAILED_REFERER_ACCOUNTS,
          error: error,
        });
      }
    );
  };
};

//#region Get associated nutritionist

export const getAssociatedNutritionist = () => {
  return (dispatch: Dispatch<NutritionistActionTypes>) => {
    dispatch(loadingNutritionist());

    nutritionistsService.getAssociatedNutritionist().then(
      (response) => {
        dispatch(loadNutritionist(response!));
      },
      (error) => {
        dispatch(loadNutritionistFailed(error));
      }
    );
  };
};

const loadNutritionist = (
  nutritionist: Nutritionist
): NutritionistActionTypes => ({
  type: NUTRITIONIST_LOAD,
  nutritionist,
});

const loadingNutritionist = (): NutritionistActionTypes => ({
  type: NUTRITIONIST_LOADING,
});

const loadNutritionistFailed = (error: string): NutritionistActionTypes => ({
  type: NUTRITIONIST_LOAD_FAILED,
  error,
});

//#endregion

//#region Remove associated nutritionist

export const removeAssociatedNutritionist = () => {
  return (dispatch: Dispatch<NutritionistActionTypes>) => {
    dispatch(deletingNutritionist());

    nutritionistsService.removeAssociatedNutritionist().then(
      (response) => {
        dispatch(deleteNutritionist());
      },
      (error) => {
        dispatch(deleteNutritionistFailed(error));
      }
    );
  };
};

const deleteNutritionist = (): NutritionistActionTypes => ({
  type: NUTRITIONIST_DELETE,
});

const deletingNutritionist = (): NutritionistActionTypes => ({
  type: NUTRITIONIST_DELETING,
});

const deleteNutritionistFailed = (error: string): NutritionistActionTypes => ({
  type: NUTRITIONIST_DELETE_FAILED,
  error,
});

//#endregion

//#region Register from nutritionist

export const registerFromNutritionist = (newAccountInfo: NewAccountInfo) => {
  return (dispatch: Dispatch<NutritionistActionTypes>) => {
    dispatch(registeringFromNutritionist());

    const registerFromNutritionistRequest: RegisterFromNutritionistRequest = {
      newAccountInfo,
    };

    nutritionistsService
      .registerFromNutritionist(registerFromNutritionistRequest)
      .then(
        (response) => {
          dispatch(registerFromNutritionistSuccess(response!));
        },
        (error) => {
          dispatch(registerFromNutritionistFailed(error));
        }
      );
  };
};

const registerFromNutritionistSuccess = (
  account: Account
): NutritionistActionTypes => ({
  type: REGISTER_FROM_NUTRITIONIST_SUCCESS,
  account,
});

const registeringFromNutritionist = (): NutritionistActionTypes => ({
  type: REGISTERING_FROM_NUTRITIONIST,
});

const registerFromNutritionistFailed = (
  error: string
): NutritionistActionTypes => ({
  type: REGISTER_FROM_NUTRITIONIST_FAILED,
  error,
});

//#endregion

//#region Complete register from nutritionist

export const completeRegisterFromNutritionist = (token: string) => {
  return (dispatch: Dispatch<NutritionistActionTypes>) => {
    dispatch(completingRegisterFromNutritionist());

    nutritionistsService.completeRegisterFromNutritionist(token).then(
      (response) => {
        dispatch(completeRegisterFromNutritionistSuccess());
      },
      (error) => {
        dispatch(completeRegisterFromNutritionistFailed(error));
      }
    );
  };
};

const completeRegisterFromNutritionistSuccess = (): NutritionistActionTypes => ({
  type: COMPLETE_REGISTER_FROM_NUTRITIONIST_SUCCESS,
});

const completingRegisterFromNutritionist = (): NutritionistActionTypes => ({
  type: COMPLETING_REGISTER_FROM_NUTRITIONIST,
});

const completeRegisterFromNutritionistFailed = (
  error: string
): NutritionistActionTypes => ({
  type: COMPLETE_REGISTER_FROM_NUTRITIONIST_FAILED,
  error,
});

//#endregion

//#region Get associated referer

export const getAssociatedReferer = () => {
  return (dispatch: Dispatch<NutritionistActionTypes>) => {
    dispatch(loadingReferer());

    nutritionistsService.getAssociatedReferer().then(
      (response) => {
        loadReferer(response!);
      },
      (error) => {
        loadRefererFailed(error);
      }
    );
  };
};

const loadReferer = (referer: User): NutritionistActionTypes => ({
  type: REFERER_LOAD,
  referer,
});

const loadingReferer = (): NutritionistActionTypes => ({
  type: REFERER_LOADING,
});

const loadRefererFailed = (error: string): NutritionistActionTypes => ({
  type: REFERER_LOAD_FAILED,
  error,
});

//#endregion
