import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Button, Paper } from "@material-ui/core";

import { UserDataFields } from "../../common/UserDataFields";
import { PathologiesFields } from "../../common/PathologiesFields";
import { FamilyBackgroundFields } from "../../common/FamilyBackgroundFields";
import { userAccount, editSuccess } from "../../../store/auth/selector";
import { updateProfile } from "../../../store/auth/actionCreators";
import { RootState } from "../../../store/config/types";
import { FullWidthSnackbar } from "../../common/FullWidthSnackbar";
import { LoadingAnimation } from "../../animations/LoadingAnimation";

interface Values {
  name: string;
  surname: string;
  gender: number;
  height: string;
  weight: string;
  phone: string;
  ethnicity: number;
  date: string;
  pathologies: string[];
  familyBackground: string[];
  email: string;
}

function UserDNAForm() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const userData = useSelector((state: RootState) => userAccount(state));
  const editOk = useSelector((state: RootState) => editSuccess(state));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editOk) {
      setOpen(true);
    }
    setIsLoading(false);
  }, [editOk]);

  let pathologiesIds = [];
  for (let i of userData.profile.pathologies) {
    pathologiesIds.push(i.id);
  }

  let familyBackIds = [];
  for (let i of userData.profile.familyHistories) {
    familyBackIds.push(i.id);
  }

  const submit = (values: Values) => {
    setIsLoading(true);
    let pathologiesNames = [];
    for (let i of values.pathologies) {
      pathologiesNames.push({ id: i, name: "" });
    }

    let familyHistoriesNames = [];
    for (let i of values.familyBackground) {
      familyHistoriesNames.push({ id: i, name: "" });
    }

    userData.profile.firstName = values.name;
    userData.profile.lastName = values.surname;
    userData.profile.gender = values.gender;
    userData.profile.height = values.height;
    userData.profile.weight = values.weight;
    userData.profile.mobile = values.phone;
    userData.profile.birthDate = values.date;
    userData.profile.pathologies = pathologiesNames;
    userData.profile.familyHistories = familyHistoriesNames;
    userData.email = values.email;

    dispatch(updateProfile(userData));
  };

  return (
    userData &&
    userData.profile && (
      <Paper elevation={1} classes={{ root: "dashboard-content" }}>
        <Formik
          initialValues={{
            name: userData.profile.firstName,
            surname: userData.profile.lastName,
            gender: userData.profile.gender,
            height: userData.profile.height,
            weight: userData.profile.weight,
            phone: userData.profile.mobile,
            ethnicity: 0,
            date: userData.profile.birthDate.substring(0, 10),
            pathologies: pathologiesIds,
            familyBackground: familyBackIds,
            email: userData.email,
          }}
          onSubmit={submit}
        >
          {isLoading ? (
            <LoadingAnimation />
          ) : (
            <Form className="form">
              <div className="profile-form-data">
                <div className="top-separator" />
                <UserDataFields speech="Datos del paciente" />
                <PathologiesFields />
                <FamilyBackgroundFields />
                <div className="bottom-separator" />
                <div className="buttons">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disableElevation
                  >
                    Guardar cambios
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {editOk && (
          <FullWidthSnackbar
            open={open}
            setOpen={setOpen}
            success
            text={"Cuenta editada correctamente"}
          />
        )}
      </Paper>
    )
  );
}

export default UserDNAForm;
