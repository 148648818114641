export default [
    { id: 0, value: "Montevideo" },
    { id: 1, value: "Artigas" },
    { id: 2, value: "Canelones" },
    { id: 3, value: "Cerro Largo" },
    { id: 4, value: "Colonia" },
    { id: 5, value: "Durazno" },
    { id: 6, value: "Flores" },
    { id: 7, value: "Florida" },
    { id: 8, value: "Lavalleja" },
    { id: 9, value: "Maldonado" },
    { id: 10, value: "Paysandú" },
    { id: 11, value: "Río Negro" },
    { id: 12, value: "Rivera" },
    { id: 13, value: "Rocha" },
    { id: 14, value: "Salto" },
    { id: 15, value: "San José" },
    { id: 16, value: "Soriano" },
    { id: 17, value: "Tacuarembó" },
    { id: 18, value: "Treinta y Tres" },
  ];
  