import React, {useEffect} from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import logo from '../../../assets/logo.png';
import {Button} from '@material-ui/core';
import {RootState} from '../../../store/config/types';
import {ThunkDispatch} from 'redux-thunk';
import {AppActions} from '../../../store/config/ActionTypes';
import {confirmRegister} from '../../../store/auth/actionCreators';
import {connect, ConnectedProps} from 'react-redux';
import {LoadingAnimation} from '../../animations/LoadingAnimation';
import {ErrorAnimation} from '../../animations/ErrorAnimation';
import qs from 'query-string';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, AppActions>
) => ({
  confirmRegister: (token: string, id: string) =>
    dispatch(confirmRegister(token, id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

export function EmailVerified({auth, confirmRegister, history}: PropsType) {
  const redirectTo = (path: string): void => {
    history.push(path);
  };

  useEffect(() => {
    if (
      !auth.confirmingRegister &&
      !auth.confirmRegisterSuccess &&
      auth.confirmRegisterErrorMessage === ''
    ) {
      const search = window.location.search;
      const queryParams = qs.parse(search, {decode: false});

      const accountId = queryParams.id.toString();
      let token = queryParams.token.toString();

      confirmRegister(token, accountId);
    }
  }, [
    auth.confirmingRegister,
    auth.confirmRegisterSuccess,
    auth.confirmRegisterErrorMessage,
    confirmRegister,
  ]);

  const verifyingUser = (
    <div className='loading-verification'>
      <LoadingAnimation />
      <p>Estamos verificando tu correo electrónico.</p>
    </div>
  );

  const userVerified = (
    <div className='email-verified'>
      <p>Felicitaciones, quedaste registrado en Mr. Gen</p>
      <Button
        color='primary'
        variant='contained'
        onClick={() => redirectTo('/login')}
        disableElevation
      >
        Ir a login
      </Button>
    </div>
  );

  const verificationFailed = (
    <div className='verification-failed'>
      <ErrorAnimation message='No se pudo verificar el mail' />
    </div>
  );

  console.log(auth);

  return (
    <div className='user-signup'>
      <div className='signup-left-content'>
        <img src={logo} alt='logo' className='logo' />
        {auth.confirmRegisterSuccess
          ? userVerified
          : auth.confirmRegisterErrorMessage !== ''
          ? verificationFailed
          : verifyingUser}
      </div>
      <div className='signup-right-content'>
        <img src={logo} alt='logo' className='logo' />
      </div>
    </div>
  );
}

export default connector(withRouter(EmailVerified));
