import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import location from "../../../../assets/location.png";
import AddressDialog from "./AddressDialog";
import states from "../../../../utils/states";
import { Address } from "./types";

interface AddressSelectorProps {
  address?: Address;
  setAddress: (value: Address) => void;
  coordinated: boolean;
}

function AddressSelector({
  address,
  setAddress,
  coordinated,
}: AddressSelectorProps) {
  const [open, setOpen] = useState<boolean>(false);

  function createAddress(addressValues) {
    setAddress({...addressValues, new: true});
  }

  return (
    <div className="address">
      <img src={location} alt="location" />
      <div className="right-chunk">
        <p className="title">Dirección</p>
        {!coordinated ? (
          <div className="content">
            <div className="options">
              <p>
                {address
                  ? `${states[address.state].value} - ${address.street} ${
                      address.doorNumber
                    }`
                  : null}
                {/* {addresses.map(
                (a: { state: string; street: string; doorNumber: number }) => {
                  return (
                    <div key={a.street} className="option-container">
                      <div
                        className={`option ${true ? "selected-option" : ""}`}
                        onClick={() => setSelected(1)}
                      >
                        {true ? <CheckIcon className="check-icon" /> : null}
                      </div>
                    </div>
                  );
                }
              )} */}
              </p>
              <div className="option-container">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => setOpen(true)}
                >
                  <AddIcon color="primary" className="add-icon" />
                  {address ? "Editar" : "Agregar"} Dirección
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="coordinated-option">
            {states[address.state].value} - {address.street} &nbsp;
            {address.doorNumber}
            <span className="primary-link" onClick={() => setOpen(true)}>
              Editar
            </span>
          </div>
        )}
      </div>
      <AddressDialog open={open} setOpen={setOpen} onSubmit={createAddress} />
    </div>
  );
}

export default AddressSelector;
