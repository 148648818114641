import {BASE_URL} from './config';

export const endpoints = {
  authLogin: `${BASE_URL}/v1/account/authenticate`,
  authAccount: `${BASE_URL}/v1/account`,
  authNutritionist: `${BASE_URL}/v1/account/nutritionist`,
  authRegisterFromNutritionist: `${BASE_URL}/v1/account/fromNutritionist`,
  authRegisterFromBusiness: `${BASE_URL}/v1/Account/fromBusiness`,
  authReferer: `${BASE_URL}/v1/account/referer`,
  authResendEmail: `${BASE_URL}/v1/Account/resendemailregister`,
  authVerifyEmail: `${BASE_URL}/v1/account/verification`,
  authConfirmation: `${BASE_URL}/v1/account/confirmation`,
  authChangePassword: `${BASE_URL}/v1/account/password`,
  authResetPassword: `${BASE_URL}/v1/account/password/reset`,
  instruments: `${BASE_URL}/v1/instruments`,
  analyses: `${BASE_URL}/v1/analyses`,
  analysesAddDistribution: `${BASE_URL}/v1/analyses/adddistribution`,
  analysesAddKitDistribution: `${BASE_URL}/v1/analyses/addkitdistribution`,
  analysesGetDistribution: `${BASE_URL}/v1/analyses/getDistribution`,
  analysesGet: `${BASE_URL}/v1/Analyses/getAnalysis`,
  analysesGetResults: `${BASE_URL}/v1/Analyses/results`,
  analysesGetResultsById: `${BASE_URL}/v1/Analyses/patients/result`,
  analysesGetFoodsById: `${BASE_URL}/v1/Analyses/patients/foods`,
  analysesGetFoods: `${BASE_URL}/v1/Analyses/foods`,
  bankAccounts: `${BASE_URL}/v1/bankAccounts`,
  commissions: `${BASE_URL}/v1/commission`,
  costs: `${BASE_URL}/v1/cost`,
  patients: `${BASE_URL}/v1/account/patients`,
  getUserFromCode: `${BASE_URL}/v1/account/nutritionist`,
  employes: `${BASE_URL}/v1/account/employees`,
  kitsavailable: `${BASE_URL}/v1/account/kitsavailable`
};
