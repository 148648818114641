import React, { useState, useEffect } from "react";
import MyData from "../common/MyData";
import BusinessKits from "./BusinessKits";
import { useDispatch } from 'react-redux';
import { selectPlan } from '../../../store/costs/actionCreators';

const options = ["Mis datos", "Cupos"];

interface BusinessProfileProps {
  history: any;
}

function BusinessProfile({ history }: BusinessProfileProps) {
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [toPay, setToPay] = useState<number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (toPay > 0) {
      dispatch(selectPlan(toPay));
      localStorage.amount = toPay;
      history.push("/loadingpayment");
    }
  }, [toPay]);

  return (
    <div className="profile-dashboard">
      <div className="profile-navigation">
        <div className="options">
          {options.map((opt, index) => {
            return (
              <span
                onClick={() => setSelectedOption(index)}
                className={selectedOption === index ? "selected" : ""}
                key={index}
              >
                {opt}
              </span>
            );
          })}
        </div>
      </div>
      {selectedOption === 0 ? <MyData /> : <BusinessKits setToPay={setToPay} />}
    </div>
  );
}

export default BusinessProfile;
