import React, { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { FormControlLabel, Checkbox, Button } from "@material-ui/core";
import { FamilyBackgroundFields } from "../../common/FamilyBackgroundFields";
import { LoadingAnimation } from "../../animations/LoadingAnimation";
import { Link } from 'react-router-dom';

interface Values {
  familyBackground: string[];
}

interface FamilyBackgroundProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  familyBackground: Values;
  setFamilyBackground: React.Dispatch<React.SetStateAction<Values>>;
  setToPay: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
}

function FamilyBackground({
  activeStep,
  setActiveStep,
  familyBackground,
  setFamilyBackground,
  setToPay,
  isLoading,
}: FamilyBackgroundProps) {
  const [checked, setChecked] = useState<boolean>(false);

  const submit = (values: Values, actions: FormikHelpers<Values>) => {
    //setActiveStep(activeStep + 1);
    setFamilyBackground(values);
    setToPay(true);
  };

  return (
    <div className="family-background">
      <Formik initialValues={familyBackground} onSubmit={submit}>
        <Form>
          <FamilyBackgroundFields />
          <div className="separator" />
          <div className="share-info">
            <FormControlLabel
              label={
                <span>
                  Estoy de acuerdo con los siguientes{' '}
                  <Link to="/terminos-condiciones-uso-uy" target="_blank" rel="noopener noreferrer">
                    Términos y Condiciones
                  </Link>
                  .
                </span>
              }
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />
              }
            />
            {/* 
              
            <div className="tyc-container">
              <p>
                Soy consciente de los siguientes términos y condiciones y
                también de la política de privacidad: 1. El objetivo de la
                prueba es analizar una muestra de su saliva para saber como
                usted asimila diferentes nutrientes, según su ADN. 2. Esta es
                una prueba informativa y no implica diagnóstico de enfermedad
                alguna. 3. Si usted acepta ocurrirá lo siguiente: - Deberá
                realizar el pago de su prueba nutrigenética con tarjetas de
                débito o crédito hasta en 6 pagos, a través del procesador de
                pagos Plexo y bajo sus términos y condiciones. - Posteriormente
                deberá coordinar la entrega y posterior retiro en su domicilio
                de la muestra nutrigenética de forma automática a través de la
                aplicación si se encuentra en Montevideo o de forma telefónica
                lo contactaremos si se encuentra en el interior del País. -
                Pasado los pasos anteriores, deberá esperar un máximo de 45 días
                para recibir sus resultados completos en su email, los
                resultados resumidos en la plataforma de Mr. Gen y las
                recomendaciones de alimentación personalizadas basado en su
                perfil genético. 4. Las recomendaciones de alimentación están
                basadas en una lista con alrededor 300 alimentos que serán
                actualizados cada cierto tiempo y que está relacionada con
                alimentos que pueden encontrarse en el mercado uruguayo. 5. Los
                análisis pueden implicar eventualmente solicitudes de nuevas
                tomas de muestras, lo que puede dar lugar a cambios en los
                plazos inicialmente estipulados para la entrega de los
                resultados. 6. Mr. Gen no ofrece apoyo de asesoramiento genético
                para los resultados. 7. Los resultados de la prueba
                nutrigenética puede no incluir todas las variantes previstas
                inicialmente. 8. Cualquier conducta o acción que pueda tener un
                impacto en la salud de un individuo debe ser evaluada junto con
                un profesional capacitado. 9. Los conocimientos científicos
                relacionados con los análisis realizados están sujetos a una
                actualización constante. 10. La muestra será enviada para su
                estudio a Nanopharmacia Diagnósitica, compania de análisis
                genéticos y genómicos de alta especialidad que cuenta con un
                laboratorio de última generación ubicado en Calle Tuxpan #2-704
                en la Colonia Roma, Ciudad de México, México. C.P. 06760. 11.
                Mr. Gen únicamente compartirá su número de usuario en la
                plataforma como identificador de su muestra con el laboratorio
                Nanopharmacia Diagnóstica de México. 12. Las características
                proporcionadas en los resultados seguirán a las ofrecidas al
                momento de la compra. Por lo tanto, las nuevas funcionalidades
                que se incorporen, solo estarán disponibles para los clientes
                cuyas compras se hayan realizado a partir de la fecha de dicha
                actualización. 13. Los resultados completos los recibirá
                únicamente usted al email informado en el registro de usuario. A
                su vez recibirá un resultado resumido bajo su usuario y
                contraseña y también las recomendaciones de alimentación
                personalizadas según su perfil genético. 14. Sus resultados
                serán compartidos con el/la profesional en nutrición únicamente
                cuando su cuenta esté relacionada con el identificador de
                nutricionista proporcionado al registrar su usuario. 15. El
                tiempo estipulado de entrega, 45 días hábiles, inicia desde que la
                muestra es retirada del domicilio del usuario y entregada en
                oficinas de Mr. Gen pudiéndose notar un cambio de estado en el
                pedido a “Esperando Resultado”. 16. La decisión de realizar este
                análisis es absolutamente voluntaria. Usted está en plena
                libertad de recibir la asesoría necesaria por el profesional de
                la salud que usted decida. 17. Si tiene alguna pregunta,
                comentario o preocupación relacionada con esta prueba,
                comuníquese al número de teléfono +598 99 436 487 en el horario
                de 10:00 a 17:00 de Lunes a Viernes. Si lo prefiere, envíe un
                mensaje a la siguiente dirección de correo electrónico:
                mrgen.uy@gmail.com Política de Privacidad para la Protección de
                Datos Personales 1.   GUVA SRL a través de su nombre comercial
                Mr. Gen, en adelante “La empresa”, es propietaria de este Sitio
                web y  sólo recogerá información que contenga datos personales
                de usuarios que brinden su consentimiento para ello. Es decir,
                recoge, almacena y lleva a cabo el tratamiento de los datos que
                el usuario pone a su disposición cuando accede a la Plataforma o
                Aplicación móvil, o la utiliza. Cuando el usuario vincula la
                cuenta que tiene en una página web de terceros (por ejemplo,
                Facebook), con su cuenta de Mr. Gen, este obtendrá los Datos
                Personales que el usuario haya proporcionado a la página web de
                terceros, en la medida en que lo permita la configuración que el
                mismo haya elegido en la página web de terceros. Datos móviles.
                Cuando el usuario utilice la aplicación móvil, Mr. Gen podrá
                recibir, almacenar y tratar diferentes categorías de datos
                acerca de su ubicación, incluyendo datos generales (por ejemplo,
                dirección IP, código postal) y datos más específicos (por
                ejemplo, la funcionalidad GPS utilizada para acceder a la
                Plataforma o a características específicas de la misma desde
                dispositivos móviles). Si usted accede a la Plataforma a través
                de un dispositivo móvil y no desea que su dispositivo nos
                proporcione datos que permitan hacer un seguimiento de su
                ubicación, podrá deshabilitar la función GPS u otras funciones
                de localización geográfica en su dispositivo, siempre y cuando
                éste se lo permita. Consulte las instrucciones del fabricante de
                su dispositivo para obtener información adicional. 2.   La
                empresa tratará los datos personales proporcionados por los
                usuarios de este sitio, únicamente con la finalidad y en la
                modalidad detallada en esta Política de Privacidad y en un todo
                de acuerdo a la normativa de la República Oriental del Uruguay
                en materia de Protección de Datos Personales, en particular a la
                Ley No. 18.331 y los decretos No. 664/008 de 22-12-008 y 414/009
                de 31-8-009 y concordantes. 3.   La empresa se compromete a usar
                los datos personales que reciba, exclusivamente para los fines
                específicos para los cuales el usuario los proporciona, no
                cediéndolos a otros terceros que no sean -los proveedores de
                servicios genéticos y servicios logísticos para la entrega y
                retiro del producto-. Mr. Gen declara que tiene derecho a
                divulgar y poner a disposición información de inicio de sesión
                del usuario a esos terceros proveedores del bien o servicio
                objeto del contrato, para que este último ofrezca y haga
                efectivo la entrega del servicio. Sin perjuicio de ello, se
                reserva la posibilidad de realizar el tratamiento de datos
                personales de los usuarios para utilizarlos en los otros
                servicios de la empresa o de sus filiales, sucursales o socios
                de negocios o para enviar mailings de la empresa con las
                novedades a lo cual el usuario consiente. 4.   Los datos
                personales podrán alojarse en los servidores que posee la
                empresa en Uruguay o de futuro en otros países con un nivel de
                protección adecuado. 5.   Mediante el vínculo “CONTACTO”, el
                usuario podrá requerir cualquier tipo de información a la
                empresa y en ese caso, los datos personales que proporcione se
                archivarán en una base de datos de “Registro de Consultas” y
                sólo se utilizarán para evacuar las consultas, extraer
                información estadística o comunicarse con el solicitante. 6.  
                La empresa no se hace responsable por el tratamiento y libre
                acceso a datos personales que el usuario incluya en su
                comunicación enviada sobre terceras personas, entendiendo que el
                usuario deberá contar con el consentimiento documentado de los
                mismos a efectos de incluir sus datos personales en la
                comunicación o consulta. Asimismo la empresa no se hace
                responsable de la información personal incluida en las
                comunicaciones directas con los nutricionistas y proveedores de
                alimentación vinculados a Mr. Gen. 7.   La Política de
                Privacidad se limita a la utilización que el usuario haga
                directamente ingresando a la página web www.mister-gen.com, y no
                se garantiza el mismo resultado en caso de que se acceda
                mediante el redireccionamiento de otros Sitios. 8.   En esta
                Política de Privacidad se detallan las prácticas vigentes de la
                empresa en relación a los servicios de promoción que presta. Por
                ello, la misma puede ser alterada si la empresa, por su sola
                voluntad, decide hacerlo comunicando a los usuarios del cambio
                mediante la actualización de la misma. 9.   La empresa ha
                implementado todas las medidas de seguridad necesarias para
                evitar que terceros no autorizados accedan a los datos. Ello
                incluye las medidas de seguridad físicas y el acceso potencial
                sólo de empleados o subcontratistas que necesiten acceder a los
                datos por razones laborales, los cuales estarán sujetos a
                cláusulas de confidencialidad y obligaciones de secreto
                profesional, contemplado en el art. 302 del Código Penal. 10. La
                empresa, hará los máximos esfuerzos para proteger los datos
                personales que le proporcionen los usuarios por esta web. Sin
                embargo, si en cumplimiento de obligaciones contractuales que
                asuma con terceros, o en el caso de que exista una orden
                judicial al respecto, podrá revelar algunos de los datos que
                tenga en su poder. 11. El Responsable de las bases de datos de
                “Consultas” es la empresa, pudiéndose ejercer los derechos de
                acceso, modificación o supresión dirigiéndose personalmente y
                con copia de documento de identidad o poder acreditativo de
                representación en caso de tratarse de persona jurídica por email
                a mrgen.uy@gmail.com. También podrá presentar el usuario, en la
                misma forma, las quejas o sugerencias que entienda oportunas.
                12. Esta página web utiliza Google Analytics y Google AdWords 
                13. Este sitio web utiliza el servicio de remarketing de AdWords
                de Google, para hacer publicidad en Sitios web de terceros
                (incluyendo Google), para los visitantes que ya visitaron
                nuestro sitio. Eso podría significar que nos comuniquemos con
                usuarios visitantes que no han completado una tarea en nuestro
                Sitio, por ejemplo, usando el formulario de contacto para
                realizar una consulta.  14. Esto podría ser en forma de un
                anuncio en la página de resultados de búsqueda de Google, o en
                un sitio en la Red de Display de Google.  15. Proveedores de
                terceros sitios, incluido Google, utilizan cookies para publicar
                anuncios basados en visitas anteriores de alguien a la página
                web de Mr. Gen. Por supuesto, los datos recogidos serán
                utilizados de acuerdo con nuestra propia Política de Privacidad
                y la Política de Privacidad de Google.  16. Puedes configurar
                las preferencias de cómo Google te muestra los anuncios,
                utilizando la página de preferencias de anuncios de Google, y si
                así lo prefieres, puedes optar por salir de la publicidad basada
                en intereses en su totalidad mediante la configuración de
                cookies o mediante el uso de un plugin para el navegador. 17.
                Para inhabilitar el uso de cookies de Google puedes hacerlo a
                través de la Configuración de anuncios de Google. Si lo deseas
                puedes inhabilitar el uso de cookies de un tercer proveedor a
                través de la página de inhabilitación de Network Advertising
                Initiative. 18.   Mr. Gen contendrá enlaces a otras páginas web
                que no son de su propiedad ni están controladas por la misma.
                Estas páginas web podrán instalar en su dispositivo sus propias
                cookies, web beacons u otros tipos de archivos, o recoger o
                solicitarle que proporcione Datos Personales acerca de usted.
                Estas páginas web de terceros disponen de sus propias
                condiciones en materia de recogida, utilización y divulgación de
                Datos Personales. Le recomendamos que lea las condiciones de uso
                y las políticas de privacidad de las páginas web de terceros que
                usted visite. 19. Podremos cambiar nuestros procedimientos de
                recogida y utilización de Datos Personales en cualquier momento,
                sin previo aviso y a nuestra exclusiva discreción. En caso de
                que introduzcamos cambios significativos a la Política de
                Privacidad, se lo notificaremos publicando la Política de
                Privacidad modificada en la Plataforma o enviándole un mensaje
                de correo electrónico. 20. Si tiene consultas o sugerencias
                sobre esta Política de privacidad de este sitio web, diríjase al
                e-mail mrgen.uy@gmail.com. POLITICA DE DEVOLUCIÓN CÓMO DEVOLVER
                UN PRODUCTO: Cualquiera que sea la razón, rogamos que nos
                notifiques tu deseo de devolver un producto a través de un
                correo electrónico a mrgen.uy@gmail.com. Si deseas el reembolso
                de tu pedido, éste se hará a través del mismo medio utilizado
                para el pago del pedido inicial. Para que el servicio sea más
                rápido, los artículos tienen que ser devueltos en su embalaje
                original, así como, si es posible, en la misma caja en la que
                fueron enviados. Si no fuera posible, te agradeceríamos que
                embalases los productos con el mejor acondicionamiento posible
                con el objetivo de evitar cualquier problema durante la
                devolución. Un e-mail de confirmación te informará de la
                recepción de tu paquete y del procesamiento de tu pedido.
                CONDICIONES PARA CAMBIOS Y DEVOLUCIONES: El plazo máximo para la
                solicitud de devoluciones será de quince (10) días corridos a
                contar desde el día de recepción del pedido. Pasado este
                plazo, tellmeGen no aceptará las devoluciones. Por motivos de
                higiene y salud, Mr.Gen no admitirá la devolución de productos
                usados, abiertos o desprecintados. Tampoco se admitirán
                productos que hayan sido golpeados o deteriorados, sin
                embalaje/caja/estuche original o que presenten alguna falta de
                algún elemento o accesorio. Para que sea efectiva una
                devolución, el kit tiene que ser devuelto a nuestras
                instalaciones. Una vez el kit sea recibido y comprobado por
                nuestro equipo, se procederá a devolver el importe de la compra
                aplicando una penalización de 50USD (o su equivalente en la
                moneda de pago) por gastos de gestión, manipulación y envío. Si
                la muestra ya ha sido enviada a nuestro laboratorio y ya está
                siendo procesada, la devolución del importe no será posible. Por
                motivos de higiene y salud, Mr.Gen no acepta cambios de los kits
                adquiridos. PASOS A SEGUIR EN LAS DEVOLUCIONES: Si deseas hacer
                la devolución de algún producto, por favor sigue estas
                instrucciones: Contacta con el servicio de Atención al Cliente
                mediante el mail mrgen.uy@gmail.com indicando el número de
                pedido y fecha del mismo. Empaqueta el/los producto/s bajo las
                mismas condiciones que lo recibiste. Mr.Gen enviará la etiqueta
                para hacer el envío de vuelta a nuestras instalaciones. Una vez
                recibidos los productos, el personal de Mr.Gen lo revisará y, si
                está todo correcto, se procederá a la devolución del importe
                aplicando la penalización anteriormente descrita. El reintegro
                del importe de las devoluciones se realizará a través del mismo
                canal que el cliente seleccionó como forma de pago en el momento
                de formalizar su pedido. Te informamos que deberás conservar la
                factura del pedido para gestionar cualquier devolución o
                sustitución. POLÍTICA DE SUSTITUCIÓN DE PRODUCTOS DEFECTUOSOS O
                EN MAL ESTADO Por causas ajenas a nuestra voluntad, cabe la
                posibilidad de recibir un producto con un defecto de fabricación
                o en mal estado. Te rogamos que, tan pronto como adviertas que
                el producto presenta alguna anomalía, te pongas en contacto con
                nuestro servicio de Atención al Cliente comunicándonos la
                incidencia. Una vez te pongas en contacto con nosotros te
                indicaremos el procedimiento para la sustitución del producto
                defectuoso. En caso de que el motivo de la devolución sea debido
                a que el producto presente algún tipo de deterioro o defecto de
                fabricación, Mr.Gen se hará cargo de los gastos de envío
                derivados de dicha devolución. No se aceptarán devoluciones de
                productos que hayan sido abiertos o utilizados, a menos que sea
                porque estén estropeados o defectuosos. Te informamos que
                deberás conservar la factura del pedido para gestionar cualquier
                devolución o sustitución. DEVOLUCIONES POR DAÑOS DERIVADOS DEL
                TRANSPORTE: Cabe la posibilidad de que el producto sufra algún
                tipo de daño derivado de su manipulación o transporte. Con el
                fin de identificar posibles daños causados por el transporte,
                Mr.Gen recomienda a sus clientes que revisen el paquete en el
                momento de su recepción con el fin de identificar posibles
                golpes o daños en el exterior del mismo. En caso de que la
                mercancía presente signos evidentes de haber sufrido algún
                deterioro derivado del transporte, el cliente tiene derecho a
                rechazar la mercancía en el momento de su recepción, haciéndole
                saber al mensajero el motivo de la no aceptación. Los reembolsos
                que se realicen en virtud de esta política se emitirán a la
                misma cuenta bancaria utilizada para la compra y se procesarán
                en un plazo de 45 días hábiles a partir de la confirmación por
                parte de nuestro equipo de Atención al Cliente de que se han
                cumplido las condiciones del reembolso. En el momento en que se
                solicite el reembolso y confirmemos que la muestra no ha
                comenzado a ser procesada por nuestro laboratorio, invalidaremos
                el kit de recogida de saliva y cualquier muestra enviada a
                nuestro laboratorio con ese código no será procesada. Si la
                compra de dos o más kits es lo que da derecho a un descuento en
                su pedido y solicita el reembolso de una parte del mismo o si
                una muestra no supera el análisis dos veces, se reembolsará
                primero el precio del kit con descuento. No emitiremos un
                reembolso si: Solicitas un reembolso más de quince (10) días
                después de su pedido. Nuestro laboratorio había comenzado a
                procesar la muestra de saliva antes de la solicitud de
                reembolso. No recibimos el kit de recolección de saliva dentro
                de los quince (10) días de la solicitud de reembolso. POLÍTICA
                DE EXTRAVÍO Si el kit no llega al destino en el plazo de diez
                (10) días corridos desde la compra, rogamos te pongas en
                contacto con nuestro servicio de Atención al Cliente
                (mrgen.uy@gmail.com) comunicándonos la incidencia para que
                podamos verificar con la empresa de transporte qué ha podido
                pasar. Una vez tengamos confirmación por parte de la empresa de
                transporte de que el kit no ha llegado a su destinatario,
                procederemos a hacer el envío de un nuevo kit sin coste para el
                cliente.
              </p>
            </div>
          */}
          </div>
          <div className="buttons">
            <Button
              classes={{ root: "outlined-button" }}
              onClick={() => setActiveStep(activeStep - 1)}
              color="primary"
              disableElevation
            >
              Anterior
            </Button>
            {isLoading ? (
              <LoadingAnimation />
            ) : (
              <Button
                disabled={!checked}
                type="submit"
                color="primary"
                variant="contained"
                disableElevation
              >
                Registrarme
              </Button>
            )}
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default FamilyBackground;
