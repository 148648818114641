import { BankAccount } from "../store/bankAccounts/types";
import { httpGet, httpPost } from "./base.service";
import { endpoints } from "./endpoints";

export const bankAccountsService = {
  fetchBankAccounts,
  createBankAccount,
};

async function fetchBankAccounts(): Promise<BankAccount[] | undefined> {
  return await httpGet<BankAccount[]>(`${endpoints["bankAccounts"]}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function createBankAccount(bankAccount: BankAccount): Promise<BankAccount | undefined> {
  return await httpPost<BankAccount>(`${endpoints["bankAccounts"]}`, bankAccount)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
